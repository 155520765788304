import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import * as actions from '../actions';



const mapStateToProps = state => {
    return { 
     
       // saveTeamInfo: state.saveTeamInfo, 
       // userInfo: state.userInfo,
       // userLogin:state.userInfo
    }  
}
/*
const userInfo = `
query userInfo ($username:String!)  {
        userInfo (username: $username) {
            items {
                username
                nameofuser
                email
                phoneNumber
                bio
                location
                birthday
                profilepixuuid
            }
        }

}`;

async function UserInfo({username},updateUserInfo) { await API.graphql(graphqlOperation(userInfo , {username})).then(response => { 
    // console.log(response.data.userInfo.items[0]) ; 
     //props.updateUserInfo(response.data.userInfo.items[0])
     //updateUserInfo(response.data.userInfo.items[0]);
     

 //}).catch(e => {console.log(e);updateUserInfo([])});
 }).catch(e => {console.log(e)})

}
*/
// Query using a parameter




export const UserInfo = ({username},props, updateUserInfo) => {
    console.log(username)
    const userInfo = `
    query userInfo ($username:String!)  {
            userInfo (username: $username) {
                items {
                    username
                    nameofuser
                    email
                    phoneNumber
                    bio
                    location
                    birthday
                    profilepixuuid
                }
            }
    
    }`;
    API.graphql(graphqlOperation(userInfo , {username})).then(response => { 
        props.updateUserInfo(response.data.userInfo.items[0])
        //updateUserInfo(response.data.userInfo.items[0]);
        props.updateUserLogin(true)
        updateUserInfo(true)
    }).catch(e => {console.log(e)});
}


//export default connect(mapStateToProps, actions)(withRouter(props => <App{...props}/>));

//export default UserInfo; 

export const OtherUserInfo = ({username},updateUserInfo) => {
    
    //export const  UserInfo = ({username},props) => {
     console.log(username)
     
     const userInfo = `
     query userInfo ($username:String!)  {
             userInfo (username: $username) {
                 items {
                     username
                     nameofuser
                     email
                     phoneNumber
                     bio
                     location
                     birthday
                     profilepixuuid
                 }
             }
     
     }`;
     API.graphql(graphqlOperation(userInfo , {username})).then(response => { 
        console.log(response.data.userInfo.items[0])
        updateUserInfo(response.data.userInfo.items[0])
   
     }).catch(e => {console.log(e);updateUserInfo('error')});
 }


export const BatchGetUserInfo = ({usernames,saveData}) => {
    console.log(usernames)
    const userInfo = `
    query batchGetUserInfo ($usernames: [String!]) {
        batchGetUserInfo(usernames: $usernames) {
          bio
          nameofuser
          username
          profilepixuuid
        }
    }`

    API.graphql(graphqlOperation(userInfo , {usernames})).then(response => { 
         response.data.batchGetUserInfo.filter(Boolean) ; 
         saveData(response.data.batchGetUserInfo)
         console.log(response.data.batchGetUserInfo)
         //props.updateUserInfo(response.data.userInfo.items[0])
      
         
   
     //}).catch(e => {console.log(e);updateUserInfo([])});
     }).catch(e => {console.log(e)});
}

//export default connect(mapStateToProps, actions)(UserInfo)


/*
export const NameofUser = ({username},updateUserInfo) => {
    const searchNameInfo = `
    query searchNameInfo ($nameofuser:String!)  {
        searchNameInfo (nameofuser: $nameofuser) {
                items {
                    username
                    nameofuser
                    profilepixuuid
                    bio
                
                }
            }

    }`;
    API.graphql(graphqlOperation(searchNameInfo , {nameofuser: username})).then(response => {             
        updateUserInfo(response.data.searchNameInfo.items[0])
    }).catch(e => {console.log(e);;updateUserInfo([])}); 

}

*/

