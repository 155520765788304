import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';

export const LoadGameAppuuidArray = ({input, uploadData}) => {
    const gamaArray = []
    const newInput = []
    for (var key in input) {
        newInput.push(input[key].appuuidDate.split('-').pop().trim())
    }
    console.log(newInput)
 
        const gameData= `
        query batchGetGroupAppuuid($appuuids:[String!])  {
            batchGetGroupAppuuid (appuuids: $appuuids) {
                
                        appuuid
                        date
                        gender
                        group
                        members
                        nameofuser
                        requesters
                        sport
                        trend
                        username
                        likeNo
                        dislikeNo
                        commentNo
                        homeTeamName
                        awayTeamName
                        groupName
                   
                }
            }`; 
        API.graphql(graphqlOperation(gameData, {appuuids: newInput})).then(response => { 
           // gamaArray.push(response.data.batchGetGroupAppuuid)
               
             //  gamaArray.sort((a, b) => new Date(b.date) - new Date(a.date))
               console.log(response.data.batchGetGroupAppuuid)
              // const NewgamaArray = response.data.batchGetGroupAppuuid
                uploadData(response.data.batchGetGroupAppuuid) 
          
                                
        }).catch(e => {console.log(e);uploadData('error')});
    }
    


export const LoadGameAppuuid = ({input, uploadData}) => {
    
    const gameData= `
        query GameGroupAppuuid ($appuuid:String!)  {
            GameGroupAppuuid (appuuid: $appuuid) {
                    items {
                        appuuid
                        date
                        gender
                        group
                        members
                        nameofuser
                        requesters
                        sport
                        trend
                        username
                        likeNo
                        dislikeNo
                        commentNo
                        homeTeamName
                        awayTeamName
                        groupName
                    }
                }
            }`; 
        API.graphql(graphqlOperation(gameData, {appuuid: input.trim()})).then(response => { 
            uploadData(response.data.GameGroupAppuuid.items[0]) ;
            console.log(response.data.GameGroupAppuuid.items[0])
                                
        }).catch(e => {console.log(e);uploadData('error')});
}
export const LoadGameUsername = ({username},updateSaveGame) => {
  
    const gameData= `
        query GameGroupInfo ($username:String!)  {
            GameGroupInfo (username: $username) {
                    items {
                        appuuid
                        date
                        gender
                        group
                        members
                        nameofuser
                        requesters
                        sport
                        trend
                        username
                        likeNo
                        dislikeNo
                        commentNo
                        homeTeamName
                        awayTeamName
                        groupName
                    }
                }
            }`; 
            API.graphql(graphqlOperation(gameData, {username})).then(response => { 
                updateSaveGame(response.data.GameGroupInfo.items) ;
                console.log(response.data.GameGroupInfo.items)
                                    
            }).catch(e => {console.log(e);updateSaveGame('error')});
}

export const TrendingGame = (updateTrendingGame) => {
    const gameData= `
    query GameGroupTrend ($trend:Int!)  {
        GameGroupTrend (trend: $trend) {
                items {
                    appuuid
                    date
                    gender
                    group
                    members
                    nameofuser
                    requesters
                    sport
                    trend
                    username
                    likeNo
                    dislikeNo
                    commentNo
                    homeTeamName
                    awayTeamName
                    groupName
                }
            }
        }`; 
        API.graphql(graphqlOperation(gameData, {trend:1})).then(response => { 
            updateTrendingGame(response.data.GameGroupTrend.items) ;
            console.log(response.data.GameGroupTrend.items)
                                
        }).catch(e => {console.log(e);updateTrendingGame('error')});
      
}

export const SearchGame = ({input,gameType,ageGroup,gender, startDate, stopDate},updateSearchGame) => {
    console.log(input + gameType + ageGroup + gender + startDate + stopDate )
    const SearchInput = `
            query LambdaGameInfo ($input:String, $gameType:String, $ageGroup:String, $gender:String, $startDate:String, $stopDate:String )  {
                LambdaGameInfo (input: $input, gameType:$gameType, ageGroup:$ageGroup, gender:$gender, startDate:$startDate, stopDate:$stopDate) {                     
                    appuuid
                    date
                    gender
                    group
                    members
                    nameofuser
                    requesters
                    sport
                    trend
                    username
                    likeNo
                    dislikeNo
                    commentNo
                    homeTeamName
                    awayTeamName    
                    bio
                    profilepixuuid
                    groupName
                    }            
            }`; 

        API.graphql(graphqlOperation(SearchInput, { input, gameType,ageGroup,gender, startDate, stopDate})).then(response => { 
            console.log(response.data)
            updateSearchGame (response.data.LambdaGameInfo)     
        }).catch(e => {
            console.log(e);
            updateSearchGame('error')});
      
}

