export default (state = 0, action) => {
    
    switch (action.type) {
        
        case 'guide_note':  
        console.log(action.payload)
           return action.payload;
        default:
            return state;
    }
};