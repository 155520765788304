import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import {UpdateReply} from './UpdateLikeMessage';
import {AddLikeGroup,AddUnlikeGroup, AddCommentNo,RemoveLikeGroup, RemoveUnlikeGroup, RemoveCommentNo, RemoveLikeAddUnlikeGroup, RemoveUnlikeAddLikeGroup}  from '../GameLike/GameInfoData' 


export const SendMessage = ({gameID, id, author, body, username, SendMessageData, reply, messages, index, MessageData, pregameID,loadMessage}) => {
    console.log(body)
    const SendMessage= `
    mutation loadMessage ($gameID:String!, $id:ID!, $author: String!, $replyId: String, $body: String!, $username: String!,$likeButton:Int!, $unlikeButton:Int!,  $noReply:Int!,  $likeUsername:[String!]!, $unlikeUsername:[String!]!) {
        loadMessage (gameID: $gameID, author: $author, body: $body,replyId: $replyId, username: $username, id:$id, likeButton: $likeButton, unlikeButton: $unlikeButton, noReply: $noReply, likeUsername: $likeUsername, unlikeUsername: $unlikeUsername) {
            author
            body
            createdAt
            gameID
            id
            likeButton
            unlikeButton
            updatedAt
            username
            noReply
        }
      }`
      API.graphql(graphqlOperation(SendMessage,  {gameID, author,replyId: pregameID, body, username,id, likeButton: 0, unlikeButton: 0, noReply:0, likeUsername:[], unlikeUsername:[]})).then(response => { 
        console.log(messages)
        console.log(index)
        const newMessage  = [...messages]
        if (reply) {
            //console.log(messages)
            console.log(newMessage[index-1].reply.findIndex(e => e.id == response.data.loadMessage.id ) == -1 )
            if (newMessage[index-1].reply.findIndex(e => e.id == response.data.loadMessage.id ) == -1 ) {
                if (messages[index-1].hasOwnProperty("reply")) {                    
                    newMessage[index-1].reply.push(response.data.loadMessage)
                }
                else {
                    newMessage[index-1].reply = [response.data.loadMessage]
                }
                console.log(newMessage)
                MessageData(newMessage,true );
                //UpdateReply({gameID:pregameID, id:gameID, }) 

            }
            UpdateReply({gameID:pregameID, id:gameID, }) 
            AddCommentNo({appuuid:pregameID})
        }
        else {
            if (newMessage.findIndex(e => e.id == response.data.loadMessage.id ) == -1 ) {
                newMessage.push(response.data.loadMessage)
                MessageData(newMessage,true);
            }

            AddCommentNo({appuuid:gameID})
        }
        SendMessageData(index)
        console.log(response.data.loadMessage)
       
        // const messagesList = [...messages];
       // messagesList[index] = response.data.updateLikeMessage;
       // MessageData(messagesList);                      
    }).catch(e => {console.log(e)});
}