import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';



export const UpdateReply = ({gameID, id, likeUsername, MessageData, index, messages, SetButton, reply }) => {
  
  const updateReplynumber = `
    mutation updateReply ($gameID:String!, $id:ID!) {
      updateReply (gameID: $gameID, id: $id, ) {
          author
          body
          createdAt
          gameID
          id
          likeButton
          unlikeButton
          updatedAt
          username
          likeUsername
          unlikeUsername
          noReply
        }
      }`
      API.graphql(graphqlOperation(updateReplynumber,  {gameID, id, })).then(response => { 
       console.log(response)
        /*
        const messagesList = [...messages];
        if (reply) {         
          const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.updateLikeMessage.gameID )
          if (messageReplyIndex != -1) {
              const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == response.data.updateLikeMessage.id )
              if (ReplyIndex!=-1) {
                messagesList[messageReplyIndex].reply[ReplyIndex] = response.data.updateLikeMessage;
              }
            }
       
        }
        else {
          const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.updateLikeMessage.id )
            messagesList[messageReplyIndex] = response.data.updateLikeMessage;             
        }
        console.log('before message')
        MessageData(messagesList);     
        //SetButton(false)   
        */
                 
    }).catch(e => {console.log(e);
      //MessageData('error')
    });
}

export const decreaseReplyNo = ({gameID, id, likeUsername, MessageData, index, messages, SetButton, reply }) => {
  console.log(gameID, id)
  /*
  const updateReplynumber = `
    mutation decreaseReplyNo ($gameID:String!, $id:ID!) {
      decreaseReplyNo (gameID: $gameID, id: $id, ) {
          author
          body
          createdAt
          gameID
          id
          likeButton
          unlikeButton
          updatedAt
          username
          likeUsername
          unlikeUsername
          noReply
        }
      }`
  */
  
  const updateReplynumber = `
    mutation deReplyNo ($gameID:String!, $id:ID!) {
      deReplyNo (gameID: $gameID, id: $id, ) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
      }`
      API.graphql(graphqlOperation(updateReplynumber,  {gameID, id, })).then(response => { 
       
        /*
        const messagesList = [...messages];
        if (reply) {         
          const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.updateLikeMessage.gameID )
          if (messageReplyIndex != -1) {
              const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == response.data.updateLikeMessage.id )
              if (ReplyIndex!=-1) {
                messagesList[messageReplyIndex].reply[ReplyIndex] = response.data.updateLikeMessage;
              }
            }
       
        }
        else {
          const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.updateLikeMessage.id )
            messagesList[messageReplyIndex] = response.data.updateLikeMessage;             
        }
        console.log('before message')
        MessageData(messagesList);     
        //SetButton(false)   
        */
                 
    }).catch(e => {console.log(e);
      //MessageData('error')
    });
}


export const UpdateLikeMessage = ({gameID, id, likeUsername, MessageData, index, messages, SetButton, reply }) => {
  
  const updateLikeMessage= `
    mutation updateLikeMessage ($gameID:String!, $id:ID!, $likeUsername:[String!]) {
        updateLikeMessage (gameID: $gameID, id: $id, likeUsername: $likeUsername) {
          author
          body
          createdAt
          gameID
          id
          likeButton
          unlikeButton
          updatedAt
          username
          likeUsername
          unlikeUsername
        }
      }`
      API.graphql(graphqlOperation(updateLikeMessage,  {gameID, id, })).then(response => { 
        console.log("add like done")
        /*
        const messagesList = [...messages];
        if (reply) {         
          const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.updateLikeMessage.gameID )
          if (messageReplyIndex != -1) {
              const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == response.data.updateLikeMessage.id )
              if (ReplyIndex!=-1) {
                messagesList[messageReplyIndex].reply[ReplyIndex] = response.data.updateLikeMessage;
              }
            }
       
        }
        else {
          const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.updateLikeMessage.id )
            messagesList[messageReplyIndex] = response.data.updateLikeMessage;             
        }
        console.log('before message')
        MessageData(messagesList);     
        //SetButton(false)   
        */
                 
    }).catch(e => {console.log(e);
      //MessageData('error')
    });
}

export const RemoveLikeMessage = ({gameID, id, likeUsername, MessageData, index, messages,SetButton,reply}) => {
  
  const removeLikeMessage= `
  mutation removeLikeMessage ($gameID:String!, $id:ID!, $likeUsername:[String!]) {
    removeLikeMessage (gameID: $gameID, id: $id, likeUsername: $likeUsername) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
      }
    }`
    API.graphql(graphqlOperation(removeLikeMessage,  {gameID, id, })).then(response => { 
      console.log("Remove like")
      /*
      const messagesList = [...messages];
      
      if (reply) {         
        const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.removeLikeMessage.gameID )
        if (messageReplyIndex != -1) {
            const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == response.data.removeLikeMessage.id )
            if (ReplyIndex!=-1) {
              messagesList[messageReplyIndex].reply[ReplyIndex] = response.data.removeLikeMessage;
            }
          }
     
      }
      else {
        const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.removeLikeMessage.id )
        messagesList[messageReplyIndex ] = response.data.removeLikeMessage;
           
      }    
      MessageData(messagesList);         
       */     
                   
  }).catch(e => {console.log(e);
   // MessageData('error')
  });
}

export const UpdateDislikeMessage = ({gameID, id, unlikeUsername, MessageData, index, messages,SetButton,reply}) => {
  
    const updateDislikeMessage= `
    mutation updateDislikeMessage ($gameID:String!, $id:ID!, $unlikeUsername:[String!]) {
      updateDislikeMessage (gameID: $gameID, id: $id, unlikeUsername: $unlikeUsername) {
          author
          body
          createdAt
          gameID
          id
          likeButton
          unlikeButton
          updatedAt
          username
          likeUsername
          unlikeUsername
        }
      }`
      API.graphql(graphqlOperation(updateDislikeMessage,  {gameID, id, unlikeUsername})).then(response => { 
        /*
        const messagesList = [...messages];
        if (reply) {         
          const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.updateDislikeMessage.gameID )
          if (messageReplyIndex != -1) {
              const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == response.data.updateDislikeMessage.id )
              if (ReplyIndex!=-1) {
                messagesList[messageReplyIndex].reply[ReplyIndex] = response.data.updateDislikeMessage;
              }
            }
       
        }
        else {
          const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.updateDislikeMessage.id)
          messagesList[messageReplyIndex ] = response.data.updateDislikeMessage;
             
        }      
        
        MessageData(messagesList);  
        */   
       // SetButton(false)    
                    
    }).catch(e => {console.log(e);
      //MessageData('error')
    });
}
export const RemoveUnlikeMessage = ({gameID, id, unlikeUsername, MessageData, index, messages,SetButton, reply}) => {
  console.log(unlikeUsername)
  const updateDislikeMessage= `
  mutation removeUnlikeMessage ($gameID:String!, $id:ID!, $unlikeUsername:[String!]) {
    removeUnlikeMessage (gameID: $gameID, id: $id, unlikeUsername: $unlikeUsername) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
      }
    }`
    API.graphql(graphqlOperation(updateDislikeMessage,  {gameID, id, unlikeUsername})).then(response => { 
      /*
      const messagesList = [...messages];
      if (reply) {         
        const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.removeUnlikeMessage.gameID )
        if (messageReplyIndex != -1) {
            const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == response.data.removeUnlikeMessage.id )
            if (ReplyIndex!=-1) {
              messagesList[messageReplyIndex].reply[ReplyIndex] = response.data.removeUnlikeMessage;
            }
          }
     
      }
      else {
        const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.removeUnlikeMessage.id )
        messagesList[messageReplyIndex] = response.data.removeUnlikeMessage;
           
      }
      MessageData(messagesList);    
      */     
     // SetButton(false)   
                       
  }).catch(e => {console.log(e);
    //MessageData('error')
  });
}

export const RemoveLikeAddUnlike = ({gameID, id, unlikeUsername, likeUsername, MessageData, index, messages, SetButton, reply}) => {
  const updateDislikeAndLike= `
  mutation removeLikeAddUnlike ($gameID:String!, $id:ID!, $unlikeUsername:[String!], $likeUsername:[String!]) {
    removeLikeAddUnlike (gameID: $gameID, id: $id, unlikeUsername: $unlikeUsername, likeUsername: $likeUsername) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
      }
    }`
    API.graphql(graphqlOperation(updateDislikeAndLike,  {gameID, id, likeUsername, unlikeUsername, })).then(response => { 
      /*
      const messagesList = [...messages];
      if (reply) {         
        const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.removeLikeAddUnlike.gameID )
        if (messageReplyIndex != -1) {
            const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == response.data.removeLikeAddUnlike.id )
            if (ReplyIndex!=-1) {
              messagesList[messageReplyIndex].reply[ReplyIndex] = response.data.removeLikeAddUnlike;
            }
          }
     
      }
      else {
        const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.removeLikeAddUnlike.id )
        messagesList[messageReplyIndex] = response.data.removeLikeAddUnlike;
           
      }
      MessageData(messagesList); 
      */          
    //  SetButton(false)  
                      
  }).catch(e => {console.log(e);
    //MessageData('error')
  });
}

export const RemoveUnlikeAddLike = ({gameID, id, unlikeUsername, likeUsername, MessageData, index, messages,SetButton, reply}) => {
  const updateDislikeAndLike= `
  mutation removeUnlikeAddLike ($gameID:String!, $id:ID!, $unlikeUsername:[String!], $likeUsername:[String!]) {
    removeUnlikeAddLike (gameID: $gameID, id: $id, unlikeUsername: $unlikeUsername, likeUsername: $likeUsername) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
      }
    }`
    API.graphql(graphqlOperation(updateDislikeAndLike,  {gameID, id, likeUsername, unlikeUsername, })).then(response => { 
      /*
      const messagesList = [...messages];
      if (reply) {         
        const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.removeUnlikeAddLike.gameID )
        if (messageReplyIndex != -1) {
            const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == response.data.removeUnlikeAddLike.id )
            if (ReplyIndex!=-1) {
              messagesList[messageReplyIndex].reply[ReplyIndex] = response.data.removeUnlikeAddLike
            }
          }
     
      }
      else {
        const messageReplyIndex  =   messages.findIndex(e => e.id == response.data.removeUnlikeAddLike.id )
        messagesList[messageReplyIndex] = response.data.removeUnlikeAddLike;
           
      }
      MessageData(messagesList); 
      */       
      //SetButton(false)   
                      
  }).catch(e => {console.log(e);
    //MessageData('error')
  });
}