export default (state = 0, action) => {
    
    switch (action.type) {
       
        case 'game_quarter':           
            return action.payload;      
        default:
            return state;
    }

};