import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
//import {LoadGameUsername, LoadUserGame} from './LoadGame';
import {RemoveUserInfoGame} from '../AjaxCall/SaveGame';
import {LoadUserGame} from './LoadUserGame';
//import {LoadGameUsername} from './LoadGame';

export const DeleteGame = ({appuuid, username}, updateSaveGame) => {
    function wait(timeout) {
        return new Promise(resolve => {
          setTimeout(resolve, timeout);
        });
    }

    const deletePost = `
        mutation  deletePost (
                        $appuuid:String!
                        $username:String!)  {
                    deletePost (
                        appuuid: $appuuid
                        username:$username)
                         {appuuid username, members}
            }`;


        API.graphql(graphqlOperation(deletePost,{appuuid, username}
        )).then(response => {  console.log(response);
            wait(2000).then(() => {
                RemoveUserInfoGame(username, appuuid)
                LoadUserGame({ username},updateSaveGame)
               // LoadUserGame({ username}, updateSaveGame) 
                //LoadGameUsername({ username}, updateSaveGame) 
                const members = response.data.deletePost.members.filter(Boolean)
                for ( const name of members) {
                    RemoveUserInfoGame(name, appuuid)
                }
            })
             
   }).catch(e => {console.log(e);});

}