import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import {LoadGameUsername,LoadGameAppuuid, LoadGameAppuuidArray} from './LoadGame';

export const LoadUserGame = ({username, },updateSaveGame) => {
    console.log(username)
    const gameData= `
        query UserInfoGame ($username:String!)  {
            UserInfoGame (username: $username) {
                    items {
                        action
                        appuuidDate
                        username
                    }
                }
            }`; 
        API.graphql(graphqlOperation(gameData, {username})).then(response => { 
            /*
            const gamaArray = []
            for (key in response.data.UserInfoGame.items) {
                LoadGameAppuuid({ input: key.appuuidDate.split('-')[0],uploadData:updateSaveGame}) 
            }
            */
           LoadGameAppuuidArray({ input: response.data.UserInfoGame.items,uploadData:updateSaveGame}) 
       
            //uploadData(response.data.UserInfoGame.items[0]) ;
                                
        }).catch(e => {console.log(e);updateSaveGame('error')});
}