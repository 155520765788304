
import * as React from 'react';

import { Platform, TextInput, Text, View, Image, FlatList, ScrollView, BackHandler, TouchableOpacity, ImageBackground, Linking, YellowBox, Dimensions} from 'react-native';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import EditProfile from '../EditProfile';
import { MdLooks5,MdEdit,MdDelete, MdKeyboardArrowRight, MdKeyboardArrowDown, MdArrowDropUp, MdArrowDropDown} from 'react-icons/md';
import {ConvoCard} from '../Components/CustomButton'
import {LoadUserGame} from '../AjaxCall/LoadUserGame';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import {UserInfo} from '../AjaxCall/UserInfo';
import {GoComment, GoPerson} from 'react-icons/go';
//import {DeleteGame} from '../AjaxCall/DeleteGame';
const ProfilePage  = (props,) => {
    //window.location.reload();
    console.log(props)
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState({});
    const [game, setGame] = React.useState([]);
    const [loadingIndex, setLoadingIndex] = React.useState(false);
    const [profileColor, setprofileColor] = React.useState('');
    const [boxColor, setboxColor] = React.useState('')
   
   
    const updateSaveGame = (data,loadingIndex, ) => {
        if (data==='error') {}
        else {console.log('succeffuly load game'); setGame(data.filter(Boolean)); setLoadingIndex(loadingIndex);console.log(data) }
    }
   
    const  stringToHslColor = (str, s, l) => {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        var h = hash % 360;
        setprofileColor ('hsl('+h+', '+s+'%, '+l+'%)')
        setboxColor ('hsl('+h+', '+s+'%, '+93+'%)')
       // return 'hsl('+h+', '+s+'%, '+l+'%)';
      }

    
 const updateUserInfo = (data) => {
    if (data==='error') {}
    else {console.log('succeffuly load user'); 
    setUser(data);  console.log(data); 
    }
    // this.props.updateUserInfo(data)
    }

    /*
    React.useEffect(() => {
    Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {console.log(user); console.log(props)
            UserInfo({username: Auth.user.username}, updateUserInfo);
            LoadUserGame({ username: Auth.user.username}, updateSaveGame)
        })
        .catch(err => console.log(err));
    },[])
    */
    //UserInfo({username:this.props.userInfo.username },this.updateUserInfo ) 
   // React.useEffect(() => {props.handler('profile')}, []);
   const aaa = ()=>{

   }
    React.useEffect(() => {LoadUserGame({ username: props.userInfo.username}, updateSaveGame); props.handler('profile'); stringToHslColor (props.userInfo.username,50,55)},[]);
    
        
 

   
    const reportGame = () => { 
        return (
            
              
                            <View style={{backgroundColor:'white',borderRadius:10,}}>
                                <View style={{flexDirection:'row', marginTop:10}}>
                                   
                                    <View style={{flexDirection:'row',paddingBottom:10, flex:1}}>
                                    <ImageBackground  style={{alignSelf:'center', marginBottom:20,width:70, height:70,borderRadius:35,backgroundColor:'#AD3E3A', marginLeft:30, justifyContent:'center', backgroundColor:profileColor, }}
                                        imageStyle={{ borderRadius: 35 }}
                                        source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  props.userInfo.username +'/'+ props.userInfo.profilepixuuid}} > 
                                        {
                                         props.userInfo.profilepixuuid == null ? 
                                        <GoPerson  color={boxColor} size={35} style={{justifyContent:'center', alignSelf:'center',flex:1,}}/>
                                        : null
                                        }
                                    </ImageBackground >
                                       
                                        
                                                                    
                                        <View style ={{marginLeft:10, flex:1,}}>
                                            
                                            <View style={{flexDirection:'row'}}>   
                                                <Text style={{marginTop:10,  fontWeight:500, fontSize:17,flex:1 }}>{props.userInfo.nameofuser===''? 'Name' : props.userInfo.nameofuser}</Text>
                                            </View>
                                            <Text style={{marginTop:10,  fontWeight:500, fontSize:14, marginBottom:0}}>
                                            {user.bio===''? 'Bio': props.userInfo.bio}
                                            </Text>
                                        </View>
                                    </View>
                                    <Link to={`${props.match.url}/EditProfile`} style={{ textDecoration: 'none' }} style={{ marginRight:10, }}> 
                                        <TouchableOpacity >
                                            <MdEdit size={22} color='black' />
                                        </TouchableOpacity>
                                    </Link> 
                                </View>
                                <View style={{borderBottomColor: '#DADADA', borderBottomWidth: 1,}}/>

                                {game.map((item, index)=>  
                                <ConvoCard 
                                item = {item} handler = {props.handler}
                                updateSaveGame = {updateSaveGame} 
                                loading={loadingIndex>=0 && loadingIndex===index ?true:false}
                                data={game}
                                loadingIndex= {index}
                                profile={props.userInfo}
                                edit={true}
                                />)}
                            </View> 
                           
       )
    
}


    
        return  (
            <View style={{backgroundColor:'white', }}> 
               
                <Route  path={`${props.match.path}/EditProfile`}
            
                    render={(props) => <EditProfile routeProps={props} />}
                    /> 
                    {/*
                    <Route  path={`game/:id`}
                    render={(props) => <GamePreview routeProps={props} handler={this.props.handler}/>}
                    />  
                */ }
                <Route
                    exact
                    path={props.match.path}
                    render={() => <View >
                    {reportGame() }
                    </View>}
                /> 
                    
            </View> 
               
         
        )
    
}
{/*
const mapStateToProps = state => {
    return { 
        userInfo: state.userInfo,
        userLogin: state.userLogin,

    }  
}

//export default connect(mapStateToProps, actions)(withRouter(props => <App{...props}/>));

*/}

export default (ProfilePage)

