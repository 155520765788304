import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import {decreaseReplyNo} from './UpdateLikeMessage';
import {AddLikeGroup,AddUnlikeGroup, AddCommentNo,RemoveLikeGroup, RemoveUnlikeGroup, RemoveCommentNo, RemoveLikeAddUnlikeGroup, RemoveUnlikeAddLikeGroup}  from '../GameLike/GameInfoData' 

export const DeleteMessage = ({gameID, id, MessageData, index, messages, reply, pregameID,loadMessage}) => {
    console.log(pregameID)
    const deleteMessage= `
    mutation deleteMessage ($gameID:String!, $id:ID!,) {
        deleteMessage(gameID: $gameID, id:$id,) {
        gameID
        id
        }
    }`
 
    API.graphql(graphqlOperation(deleteMessage,  {gameID, id})).then(response => { 
        if (reply) {
            const messageIndex = messages.findIndex(e => e.id==gameID)
            if (messageIndex != -1) {                
                const replyIndex  =  messages[messageIndex].reply.findIndex(e => e.id == response.data.deleteMessage.id )
                if (replyIndex != -1) {
                    const messagesList = [...messages];
                    messagesList[messageIndex].reply.splice(replyIndex, 1)
                    MessageData(messagesList,loadMessage); 
                    decreaseReplyNo({gameID:pregameID, id:gameID, }) 
                }
            }
            RemoveCommentNo({appuuid:pregameID})
            
        }
        else {
            const messagesList = [...messages];
            const index = messagesList.findIndex(e => e.id == response.data.deleteMessage.id )
            messagesList.splice(index, 1)
            MessageData(messagesList,loadMessage);  
            RemoveCommentNo({appuuid:gameID})
        }
                 
    }).catch(e => {console.log(e)});
}