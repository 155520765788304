import { Alert, StyleSheet, Text, FlatList, TextInput, View, Dimensions, Button, TouchableOpacity, PanResponder, ImageBackground, ScrollView, TouchableWithoutFeedback, TouchableHighlight, Pressable, Modal  } from 'react-native';
import {SendMessage} from './AjaxCall/Message/SendMessage';
import React, { useEffect, useState } from 'react';
import soundfile from './message-pop-sound.wav';
import {MdSend} from 'react-icons/md';
import { connect } from 'react-redux';
import * as actions from './actions';
import {AddRequester, RemoveRequester, AddMember } from './AjaxCall/SaveGame';

function TextInputPage(props) {
    console.log(props)
    const {userInfo} = props.props.props
   // const {id, index, prevgameID, messages, MessageData, textInput, setTextInput} = props
   const {id, index, prevgameID, messages, MessageData, } = props
   // const [messages, setMessages] = useState([]); 
    const [textBorderColor, settextBorderColor] = useState('white');
    const [modalVisible, setModalVisible] = useState(false);
    const [button, setButton] = useState(false)
    //const [textInput, setTextInput] = useState([]);
    //const [textInputA, setTextInputA] = useState('');
   

    //useEffect(() => { props.updateTextInput(new Array(messages.length)) }, []);


    const sendnothing = () => {}
    const saveTeamData = (data) => {
        //const newtrendData = [...this.state.trendData]
        console.log(data)
        
    }

    /*
    const updatTextArray = (text, index) => { 
        const newTextArray = [...textInput]
        newTextArray[index+1] = text
        setTextInput(newTextArray)  
           
    }
    */
   const updatTextArray = (text, index) => { 
    console.log(text)
       console.log(props.TextInput)
        const newTextArray = [...props.TextInput]
        if (index == -1 ) {
            newTextArray[index+1] = text
        }
        else{
            newTextArray[messages.length-index+1] = text
        }       
          props.updateTextInput(newTextArray)
  
    }
    const SendMessageData = (index) => {
        console.log(index)
        
        /*
        if (index == 0 ) {
            newTextArray[index] = ''
        }
        else{
            newTextArray[messages.length-index+1] = ''
        }
        */
       /*
       const newTextArray = [...props.TextInput]
        newTextArray[index] = ''
        props.updateTextInput(newTextArray)
        */
    
        
       // setTextInput(newTextArray)  
    }

    const onSubmitMessage = () => {
        if (userInfo.username == '') {
            setModalVisible(true) 
        }
        else {
 
                if ( props.props.members.findIndex(x => x.username === userInfo.username)==-1 &&  props.props.owner[0].username!=userInfo.username){
                    AddMember (id, userInfo.username, props.props.membersupdate, true)
                    
                }
            
             
              const textid =  '_' + (Number(String(Math.random()).slice(2)) + Date.now() + Math.round(performance.now())).toString(36);
            
            if (index == -1 ) {
                console.log(props.TextInput[index+1])
                const newMessage  = [...messages]
                newMessage.push({
                    gameID:id, 
                    author:userInfo.nameofuser,
                    pregameID:index==-1 ? '' : prevgameID,
                    body :index == -1 ? props.TextInput[index+1] :  props.TextInput[messages.length+1-index] ,
                    username:userInfo.username,
                    id:textid,
                    createdAt: new Date(),
                    likeButton: 0, 
                    unlikeButton: 0, 
                    noReply:0, 
                    likeUsername:[], 
                    unlikeUsername:[]
                })
                MessageData(newMessage,);

                const newTextArray = [...props.TextInput]
                newTextArray[index+1] = ''
                props.updateTextInput(newTextArray)
                SendMessage({
                    gameID: id, 
                    author: userInfo.nameofuser, 
                    body: index == -1 ? props.TextInput[index+1] :  props.TextInput[messages.length+1-index] , 
                    username: userInfo.username,  
                    id:textid,
                    SendMessageData,
                    reply: index==-1 ? false : true,
                    messages, 
                    index:index+1,
                    MessageData,
                    pregameID: index==-1 ? '' : prevgameID,
                    loadMessage:true
                    }) 
                
            }
            else {
                const newMessage  = [...messages]
                console.log(newMessage)
                //console.log(props.TextInput[messages.length+1-index])
                if (messages[messages.length-index-1].hasOwnProperty("reply")  ) {    
                    newMessage[messages.length-index-1].reply.push({
                        gameID:id, 
                        author:userInfo.nameofuser,
                        pregameID:index==-1 ? '' : prevgameID,
                        body :index == -1 ? props.TextInput[index+1] :  props.TextInput[messages.length+1-index] ,
                        username:userInfo.username,
                        id:textid,
                        createdAt: new Date(),
                        likeButton: 0, 
                        unlikeButton: 0, 
                        noReply:0, 
                        likeUsername:[], 
                        unlikeUsername:[]
                    })     
                }
                
                MessageData(newMessage,);
                const newTextArray = [...props.TextInput]
                newTextArray[messages.length+1-index] = ''
                props.updateTextInput(newTextArray)
                SendMessage({
                    gameID: id, 
                    author: userInfo.nameofuser, 
                    body: index == -1 ? props.TextInput[index+1] :  props.TextInput[messages.length+1-index] , 
                    username: userInfo.username,  
                    id:textid,
                    SendMessageData,
                    reply: index==-1 ? false : true,
                    messages, 
                    index: index == -1 ? index+1 : messages.length-index,
                    MessageData,
                    pregameID: index==-1 ? '' : prevgameID,
                    loadMessage:true
                    }) 
            }
        }
    }
       
        
    return (
        
        <View style={{flex:1}}> 
        <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible(!modalVisible);
            }}
        >
            <View style={styles.centeredView}>
            <View style={styles.modalView}>
                <Text style={{fontSize:22,marginBottom: 15,textAlign: "center"}}>Signin to join the conversation</Text>
                <View style={{flexDirection:'row', width:'100%'}}>
                    <View style={{flex:1,}}>
                        <Text onPress={() => props.props.props.history.push('/profile')} style={{marginLeft:45, flex:1,fontSize:16, textAlign:'center', backgroundColor:'green', paddingTop:7, paddingBottom:7, borderRadius:7, color:'white'}}>Signin</Text>
                    </View>
                    <View style={{flex:1,}}>
                        <Text onPress={() => setModalVisible(!modalVisible)}  style={{marginLeft:60, fontSize:16, textAlign:'center', justifyContent:'center',paddingTop:7, paddingBottom:7,}}>Cancel</Text>
                    </View>
                </View>
            </View>
            </View>
        </Modal>
        {index == -1 ?
            <View style={{flexDirection:'row', height:80, flex:1, shadowRadius: 1, borderRadius:5, marginLeft:10,}}>
                <TextInput                               
                        style={{ fontWeight:400, flex:1, fontSize:15,  textAlignVertical: "top", color:'#1B1B1B',  borderWidth:0, padding: 10}}                      
                        placeholder="Add Comment..."
                        placeholderTextColor = "#A8A7A7"
                        onFocus={ () => {settextBorderColor('white')}}
                        multiline={true}
                        numberOfLines={4}
                        onChangeText={(text) =>  updatTextArray(text, index) }                 
                        value={ props.TextInput[index+1] }  
                        maxLength = {400}
                        blurOnSubmit={true}
                        onSubmitEditing={(e)=> { props.TextInput[index+1]== null || !props.TextInput[index+1].trim().length ? sendnothing() : onSubmitMessage()}}  
                    >
                </TextInput>
                <TouchableOpacity  style={{backgroundColor:'#F1F1F1',borderBottomRightRadius:5, borderTopRightRadius:5}} onPress = {() => 
                    { props.TextInput[index+1]== null || !props.TextInput[index+1].trim().length ? sendnothing() : onSubmitMessage()} }>
                    <MdSend style={{marginLeft:5, marginRight:5}} size={20} color='black' />
                </TouchableOpacity>  
            </View>
            :
            <View style={{flexDirection:'row', height:80, flex:1, shadowRadius: 1, borderRadius:5, marginLeft:10,}}>
                <TextInput                               
                style={{ fontWeight:400, flex:1, fontSize:15,  textAlignVertical: "top", color:'#1B1B1B',  borderWidth:0, padding: 10}}                      
                placeholder="Add Comment..."
                placeholderTextColor = "#A8A7A7"
                onFocus={ () => {settextBorderColor('white')}}
                multiline={true}
                numberOfLines={4}
                onChangeText={(text) =>  updatTextArray(text, index) }                 
                value={  props.TextInput[messages.length+1-index]}  
                maxLength = {400}
                blurOnSubmit={true}
                onSubmitEditing={(e)=> { props.TextInput[messages.length+1-index] == null || !props.TextInput[messages.length+1-index].trim().length? sendnothing() : onSubmitMessage()}}  
            >
                </TextInput>
                <TouchableOpacity  style={{backgroundColor:'#F1F1F1',borderBottomRightRadius:5, borderTopRightRadius:5}} onPress = {() => 
                {props.TextInput[messages.length+1-index] == null || !props.TextInput[messages.length+1-index].trim().length? sendnothing() : onSubmitMessage()} }>
                <MdSend style={{marginLeft:5, marginRight:5}} size={20} color='black' />
                </TouchableOpacity> 
            </View>
            }
           
        </View>
        
                
                    
      
    )
}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2
    },
    buttonOpen: {
      backgroundColor: "#F194FF",
    },
    buttonClose: {
      backgroundColor: "#2196F3",
    },
    textStyle: {
      color: "white",
      fontWeight: "bold",
      textAlign: "center"
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center"
    }
  });

const mapStateToProps = state => {
    return { 
        userInfo: state.userInfo,      
        teamInfo: state.teamInfo,  
        TextInput: state.TextInput
    }  
}

export default connect(mapStateToProps, actions)(TextInputPage);
