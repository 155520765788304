import React, { Component, Fragment } from 'react';
import { Platform, TextInput, Text, View, Image, FlatList, ScrollView, BackHandler, TouchableOpacity, ImageBackground, Linking, YellowBox, Dimensions} from 'react-native';
import { connect } from 'react-redux';
import * as actions from './actions';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
//import './style.css';
import TextareaAutosize from 'react-textarea-autosize';
import { FaCamera} from 'react-icons/fa';
import { MdLooks5,MdEdit,MdDelete } from 'react-icons/md';
import Amplify, { API, graphqlOperation, Auth, Storage } from 'aws-amplify';
import Loader from 'react-loader-spinner';
import {SaveUserInfo} from './AjaxCall/SaveUserInfo'

class EditProfile extends Component {
    constructor(props) {
        super(props);        
        this.state = {            
            Imageratio:1,
            TrendingMainHeadingSizeWidth:Dimensions.get('window').width ,
            viewindex: '',
            uploadedComplete:false,
            loading:false,
            mserror: false   
        }  
         this.updateSaveInfoFeedback = this.updateSaveInfoFeedback.bind(this)
    }

    componentDidMount(){       
       // this.handleResize()  
    }

    updateSaveInfoFeedback = (data) => {
        if (data==='success'){
            this.props.routeProps.history.goBack(); 
        }
        else{
            this.setState({mserror:true})
        }
        
    }
    
   


    saveUserDataOnline = () => {
        var editUserInfo = this.props.userInfo  
        SaveUserInfo({UserInfoData:this.removeEmptyData(editUserInfo )}, this.updateSaveInfoFeedback)

    }

    UUID = () => {
        return ('_' + (Number(String(Math.random()).slice(2)) + Date.now() + Math.round(performance.now())).toString(36));
    }

    removeEmptyData = (obj) => {
        for (var propName in obj) { 
            if (obj[propName] === '' || obj[propName] === undefined) {
            delete obj[propName];
            }
        }
    return obj
    }

    onChange(e) {
        this.setState({loading:true})
        const file = e.target.files[0];
        var pixuuid = this.UUID()
         Storage.put('username/' + this.props.userInfo.username+'/'+ pixuuid, file, {contentType: 'image/png'})
        .then (result =>  {this.props.userInfo.profilepixuuid = pixuuid ; this.setState({loading:false, uploadedComplete:true}) ; console.log(result)})
       .catch(err => {console.log(err); this.setState({loading:false, uploadedComplete:true,mserror:true})});
    }

    
    render() {
    
        return(
            <View style={{ backgroundColor:'white',flex:1,paddingBottom:10,  marginTop:20, marginBottom:70,width:  '80%' ,alignSelf:'center'}}> 
                 { 
                 this.state.loading ?  

                    <View style = {{}}>   
                        <Loader
                            style={{alignSelf:'center'}}
                            type="Puff"
                            color="#DE4D4D"
                            height={100}
                            width={100}
                            // timeout={3000} //3 secs
                        />
                    </View>
                    :
                    this.state.uploadedComplete ?  
                    <View style = {{}}>   
                        <ImageBackground 
                            style={{ alignSelf:'center', marginBottom:20,width:120, height:120,borderRadius:5,backgroundColor:'#AD3E3A', marginLeft:30, justifyContent:'center'}}
                            source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+ this.props.userInfo.username  +'/'+ this.props.userInfo.profilepixuuid}} >  
                        </ImageBackground>                                       
                    </View>
                    :
                    <View >                    
                        
                        <ImageBackground
                            style={{ alignSelf:'center', marginBottom:20,width:120, height:120,borderRadius:5,backgroundColor:'#AD3E3A', marginLeft:30, justifyContent:'center'}}
                            source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+ this.props.userInfo.username  +'/'+ this.props.userInfo.profilepixuuid}} >  
                            
                                <label for="file-upload" style={{alignSelf:'center',cursor:'pointer'}}>
                                    <TouchableOpacity>
                                    <FaCamera size={35} color='white'/>
                                    </TouchableOpacity>
                                </label>
                             
                            <   input id="file-upload"  type="file" name="uploadfile" style={{display:'none'}} onChange={(e) => this.onChange(e)}/> 
                        </ImageBackground> 
                    </View> 
              
                 }
                
                <View style ={{marginLeft:10, flex:1, }}>
               
                    <View style={{margin:10, flex:1}}>   
                        <Text style={{fontWeight:490, fontSize:17, color: '#8D8C8C' }}>Name</Text>
                        
                        <TextareaAutosize  
                                minRows={2}
                                maxRows={2}
                                style={{ backgroundColor:'#E2E1E1',alignSelf:'center',  fontSize:17, borderWidth:0, width:'100%',  fontFamily:'sans-serif',marginTop:7,}}
                                onChange={e => {this.props.userInfo.nameofuser = e.target.value; this.forceUpdate();}}                  
                                value={this.props.userInfo.nameofuser}
                                ref={this.textarea} >
                            </TextareaAutosize>
                        

                        <Text style={{  fontWeight:490, fontSize:17, marginTop:30,color: '#8D8C8C' }}>Bio</Text>                   
                        <TextareaAutosize  
                          minRows={3}
                          maxRows={3}
                                style={{backgroundColor:'#E2E1E1',alignSelf:'center',  fontSize:17, borderWidth:0, width:'100%',fontFamily:'sans-serif',marginTop:7,}}
                                onChange={e => {this.props.userInfo.bio = e.target.value; this.forceUpdate();}}                  
                                value={this.props.userInfo.bio}
                                ref={this.textarea} >
                            </TextareaAutosize>

                        <Text style={{  fontWeight:490, fontSize:17, marginTop:30,color: '#8D8C8C' }}>Location</Text>                   
                             <TextareaAutosize  
                             minRows={2}
                             maxRows={2}
                                style={{backgroundColor:'#E2E1E1',alignSelf:'center',  fontSize:17, borderWidth:0, width:'100%',fontFamily:'sans-serif',marginTop:7,}}
                                onChange={e => {this.props.userInfo.location = e.target.value; this.forceUpdate();}}                  
                                value={this.props.userInfo.location}
                                ref={this.textarea} >
                            </TextareaAutosize>
                        

                        <Text style={{  fontWeight:490, fontSize:17, marginTop:30,color: '#8D8C8C' }}>Birth date</Text>                   
                        <TextareaAutosize 
                                minRows={2}
                                maxRows={2} 
                                style={{backgroundColor:'#E2E1E1',alignSelf:'center',  fontSize:17, borderWidth:0, width:'100%',fontFamily:'sans-serif',marginTop:7,}}
                                onChange={e => {this.props.userInfo.birthday = e.target.value; this.forceUpdate();}}                  
                                value={this.props.userInfo.birthday }
                                ref={this.textarea} >
                            </TextareaAutosize>
                            {
                                this.state.mserror ?
                                    <Text style={{alignSelf:'center', marginBottom:-15, color:'#AD3E3A'}}>Sign In to Save </Text>
                                :
                                    null
                            }
                            <TouchableOpacity onPress = {() => {  this.saveUserDataOnline()} }
                                    style={{width:90,height:40,borderRadius:10, alignSelf:'center', marginTop: 20, backgroundColor:'#AD3E3A', justifyContent:'center'}}>
                                <Text style={{fontSize:18,alignSelf:'center', color:'white'}}>Save</Text>
                            </TouchableOpacity>
                          
                    </View>
                </View>
            </View>
        
        )
    }
}

const mapStateToProps = state => {
    return { 
     
        userInfo: state.userInfo, 
       
    }  
}

export default connect(mapStateToProps, actions)(EditProfile);
