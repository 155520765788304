import * as React from 'react';
import { View, Image, Text, ImageBackground,TouchableOpacity, Dimensions, FlatList, Linking, TextInput} from 'react-native';
import {LoadGameAppuuid} from './AjaxCall/LoadGame';
import {FaDotCircle, FaFlag, FaUserSlash, FaCommentAlt,FaRegFutbol, FaBars, FaBasketballBall} from 'react-icons/fa';
import {AiFillLike, AiFillDislike,} from 'react-icons/ai';
import CommentsPage from './CommentsPage';
import {AddRequester, RemoveRequester} from './AjaxCall/SaveGame';
import {BatchGetUserInfo} from './AjaxCall/UserInfo';
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton,FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon,} from 'react-share';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { BrowserRouter as Router, Route, Link, HashRouter, withRouter , Switch, useHistory} from "react-router-dom";
import { Auth } from 'aws-amplify';
import {UserInfo} from './AjaxCall/UserInfo';
import { connect } from 'react-redux';
import * as actions from './actions';

class CheckMember extends React.Component {
    constructor(props) {
        super(props); 
        this.state = { 
            viewingPage:'home',           
            trendData:[],
            members:[],
            requesters:[],
            owner:[],
           // pageURL:'See https://www.iclockin.com/game/'+this.props.props.gameData.appuuid + " for game detail information",
           pageURL:null, 
           //topic:this.props.props.gameData.homeTeamName + ' ' + ' Vs ' + this.props.props.gameData.awayTeamName + ' ' + 
           topic:null,
        } 
    }

    uploadData = (data) => {
        this.setState({trendData:data, pageURL: 'Join the conversation at https://www.iclockin.com/game/'+ data.appuuid,
        topic: data.homeTeamName + ' Vs ' + data.awayTeamName })
        console.log(data.members)
        this.editMember(data)
    }

    

    editMember = (data) => {
        console.log(data)
       const members = data.members.filter(Boolean)
        const requesters = data.requesters.filter(Boolean)
        if  (members.length==0) {
            this.setState({ members:[]})
        }
        else {
            BatchGetUserInfo({usernames:members,saveData:this.saveMemberData})
        }

        if  (requesters.length==0) {
            this.setState({ requesters:[]})
        }
        else {
            BatchGetUserInfo({usernames:requesters,saveData:this.saveRequesterData})
        }
        BatchGetUserInfo({usernames:data.username,saveData:this.saveOwnerData})
    }

    saveOwnerData = (data) => {
        this.setState({owner:data})
        console.log(data)
    }

    saveMemberData = (data) => {
        this.setState({members:data})
        console.log(data)
    }

    saveRequesterData = (data) => {
        this.setState({requesters:data})
        console.log(data)
    }

    saveTeamData = (data) => {
        //const newtrendData = [...this.state.trendData]
        console.log(data)
        this.state.trendData.requesters = data
        console.log('hhhh')
        this.setState({trendData:this.state.trendData})
        console.log(this.state.trendData)
    }

    componentDidMount() { 
        LoadGameAppuuid({input:this.props.match.params.id, uploadData:this.uploadData}) 
     
    }

    componentWillUnmount() {
       // this.props.updateTextInput([])
    }
   

    //history = useHistory();
    //handleOnClick = useCallback(() => history.push('/sample'), [history]);

    RequestView = () =>(
        <View style={{backgroundColor:"white", height:400,  padding:10}}>
             
                
                <Text style={{ color:'#575757',fontSize:13,marginTop:20}}>{this.state.trendData.group} Conversation</Text>
                
                <View style={{marginTop:5, flexDirection:'row',justifyContent:'space-between', }}>
                    <View style={{flexDirection:'row', flex:1,alignItems:'center'}}>
                        <FaCommentAlt  size={12} color= '#6B6B6B'   /> 
                        <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{this.state.trendData.commentNo}</Text>
                    </View>
                    <View style={{flexDirection:'row',flex:1,alignItems:'center'}}>
                        <AiFillLike  size={16} color= '#6B6B6B'   /> 
                        <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{this.state.trendData.likeNo}</Text>
                    </View>
                    <View style={{flexDirection:'row',flex:1,alignItems:'center'}}>
                        <AiFillDislike  size={16} color= '#6B6B6B'   /> 
                        <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{this.state.trendData.dislikeNo}</Text>
                    </View>
                </View>
                { this.state.requesters.findIndex(x => x.username === this.props.userInfo.username)!=-1 ?
                
                    <View>
                        <Text style={{fontWeight:500, fontSize:20,  marginTop:15, alignSelf:'center',}}>Your Request is Pending</Text>
                        <Text style={{fontWeight:400, fontSize:14,   alignSelf:'center', color:'#6B6B6B' }}>You'll get a notification if your request is approved</Text>
                        <TouchableOpacity onPress = {() => RemoveRequester (this.state.trendData.appuuid, this.props.userInfo.username,this.editMember,false )} style={{marginTop:20, backgroundColor:'#E5E5E5', width:'50%', paddingTop:7, paddingBottom:7, color:'white', borderRadius:5, alignSelf:'center', alignItems:'center', fontSize:18}}>
                            <Text style={{fontWeight:600, fontSize:18}}>Cancel Request</Text>
                        </TouchableOpacity>
                    </View>
                    :
                    <TouchableOpacity onPress = {() => this.props.userInfo.username === '' ? this.props.history.push(`${'/profile'}`) :

                    AddRequester (this.state.trendData.appuuid, this.props.userInfo.username,this.editMember )
                    } style={{marginTop:15, backgroundColor:'#C20606', width:'50%', paddingTop:7, paddingBottom:7, color:'white', borderRadius:5, alignSelf:'center', alignItems:'center', fontSize:18}}>
                        <Text style={{fontWeight:700, color:'white',fontSize:18}}>Join</Text>
                    </TouchableOpacity>
                }
            </View>
    )
    /*
     console.log(this.state.trendData.members)
                console.log(this.state.trendData.members.includes(this.props.userInfo.username))
                console.log(this.state.trendData.username == this.props.userInfo.username)
                console.log(this.state.trendData.group == 'Public')
                //557 width:isMobile?'100%':'100%',
     */
   
    render() {
        console.log(this.state.trendData)
        return (
            <View style={{flex:1}}>
                <View style={{marginTop:140}}>
                 { this.state.trendData.length != 0 ?
                    this.state.members.findIndex(x => x.username === this.props.userInfo.username)!=-1 || this.state.trendData.username == this.props.userInfo.username || this.state.trendData.group == 'Public' ?
                    <CommentsPage trendData = {this.state.trendData} props={this.props} members={this.state.members}  membersAndowner = {this.state.members.concat(this.state.owner)}  requesters={this.state.requesters} owner={this.state.owner} membersupdate ={this.editMember}>
                        
                    </CommentsPage>
                    
                    :
                    
                    this.RequestView()
                    :

                    null
                }
                </View>
                <View style={{position: 'fixed', width:isMobile?'100%':557, backgroundColor:'white',}}>
                <Text style={{textAlign:'center', color:'#636363', fontWeight:500,}}>Invite like-minded fans to join the conversation</Text>
                <View style = {{padding:10, flex:1, flexDirection:'row', justifyContent:'space-evenly', backgroundColor:'white', }}>
                                    <FacebookShareButton
                                    url={'https://allamericanhighschoolsports.com'}
                                    quote={this.state.topic}>
                                        <FacebookIcon
                                            size={40}
                                            round />
                                    </FacebookShareButton>

                                    <TwitterShareButton 
                                        url={this.state.pageURL}
                                        title={this.state.topic}>
                                        <TwitterIcon
                                            size={40}
                                            round />
                                    </TwitterShareButton>

                                    <WhatsappShareButton 
                                        url={this.state.pageURL}
                                        title={this.state.topic}>
                                        <WhatsappIcon
                                            size={40}
                                            round />
                                    </WhatsappShareButton>

                                    <EmailShareButton
                                        url={this.state.pageURL}
                                        subject={this.state.topic}>
                                        <EmailIcon
                                            size={40}
                                            round />
                                    </EmailShareButton>


                </View>
                <View style={{flexDirection:'row', justifyContent:'space-evenly', flex:1, marginBottom:10}}>
                   
                   <Text style={{fontSize:19,flex:1, textAlign: 'center', flex:1,fontWeight:500, }}>
                   {this.state.trendData.homeTeamName}
                   </Text>
                  
              
               
                    <Text style={{fontSize:15,alignSelf:'center',flex:1,  textAlign: 'center',fontWeight:500,}}>
                        {new Date(this.state.trendData.date).toLocaleDateString()}
                    </Text>
                      
            
                   <Text style={{fontSize:19,textAlign: 'center', flex:1,fontWeight:500,}}>
                   {this.state.trendData.awayTeamName}
                   </Text>
                   
           
                </View>

                </View>
               
             </View>
        )
        
    }
}

{/*

const mapStateToProps = state => {
    return { 
        userInfo: state.userInfo,
        userLogin: state.userLogin,
        TextInput: state.TextInput
    }  
}



export default connect(mapStateToProps, actions)(CheckMember); 
*/}

export default CheckMember