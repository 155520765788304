import { combineReducers } from 'redux';
import currentTeamSelectedReducer from './currentTeamSelectedReducer';
import newPageReducer from './newPageReducer';
import newPageIndex from './showNewPageReducer';
import playerPageindex from './playerIndexReducer';
import fixtureData from './fixtureData';
import rosterData from './rosterData';
import nextGameElement  from './nextGameElement';
import propsElement  from './propsElement';
import playerDetailInfo from './playerDetailInfo';
import standingData from './standingData';
import teamNewData from './teamNewData';
import viewingPage from './viewingPage';
import codeInProgressReducer from './codeInProgressReducer';
import userLogin from './userLogin';
import addedFixtures from './addedFixtures';
import userEmail from './userEmail';
import teamInfo from './teamInfo';
import saveTeamInfo from './saveTeamInfo';
import userInfo from './userInfo';
import gameQuarter from './gameQuarter';
import guideNote from './guideNote';
import tutoComplete from './tutoComplete';
import TextInput from './TextInput'

export default combineReducers({
    currentTeamSel: currentTeamSelectedReducer,  
    newPage: newPageReducer,
    newPageIndex : newPageIndex,
    playerPageindex: playerPageindex,
    fixtureData: fixtureData,
    nextGameElement : nextGameElement,
    propsElement :  propsElement,
    rosterData: rosterData,    
    standingData: standingData,
    playerDetailInfo: playerDetailInfo,
    teamNewData: teamNewData,  
    viewingPage: viewingPage,
    codeInProgressReducer: codeInProgressReducer,  
    userLogin: userLogin,
    addedFixtures: addedFixtures,
    userEmail: userEmail,
    teamInfo: teamInfo,
    saveTeamInfo: saveTeamInfo,  
    userInfo: userInfo,
    gameQuarter: gameQuarter,
    guideNote: guideNote,
    tutoComplete: tutoComplete,
    TextInput: TextInput,
    
})