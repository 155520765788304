export default (state = -1, action) => {
   
    switch (action.type) {
        
        case 'props_element':
       
            return action.payload;        
        default:
            return state;
    }

};