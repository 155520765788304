import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import {UpdateLikeMessage, UpdateDislikeMessage, RemoveLikeMessage,RemoveUnlikeMessage, RemoveLikeAddUnlike, RemoveUnlikeAddLike } from '../Message/UpdateLikeMessage';
import {AddLikeGroup,AddUnlikeGroup, AddCommentNo,RemoveLikeGroup, RemoveUnlikeGroup, RemoveCommentNo, RemoveLikeAddUnlikeGroup, RemoveUnlikeAddLikeGroup}  from '../GameLike/GameInfoData'  

export const UpdateLike = ({userLike, UserlikeData, SetButton, dir, message, index, pagegameID}) => {
   console.log(pagegameID)
    const Like = `
    mutation likeData($gameID:String!, $username:String!, $like:[String], $unlike:[String]) {
        likeData(gameID: $gameID, username:$username, like: $like, unlike: $unlike) {
          gameID
          like
          unlike
          username
        }
      }`
      API.graphql(graphqlOperation(Like,  {gameID:pagegameID, username:userLike.username , like:userLike.like, unlike:userLike.unlike})).then(response => { 
        console.log(dir)
        SetButton(false)
        if (dir =='RemoveLike'){
          RemoveLikeMessage({gameID:message.gameID, id:message.id, }) 
          RemoveLikeGroup({appuuid:pagegameID})

        }
        else if (dir =='AddLike'){
          UpdateLikeMessage({gameID:message.gameID, id:message.id, }) 
          AddLikeGroup({appuuid:pagegameID})
        }
        else if (dir =='AddUnlike'){
          UpdateDislikeMessage ({gameID:message.gameID, id:message.id, }) 
          AddUnlikeGroup({appuuid:pagegameID})
        }
        if (dir =='RemoveUnlike'){
          RemoveUnlikeMessage({gameID:message.gameID, id:message.id, }) 
          RemoveUnlikeGroup({appuuid:pagegameID})
        }
        else if (dir =='RemoveLikeAddUnlike'){
          RemoveLikeAddUnlike({gameID:message.gameID, id:message.id, }) 
          RemoveLikeAddUnlikeGroup({appuuid:pagegameID})
        }
        else if (dir =='RemoveUnlikeAddLike'){
          RemoveUnlikeAddLike({gameID:message.gameID, id:message.id, })
          RemoveUnlikeAddLikeGroup({appuuid:pagegameID})
        }
        /*
        const items = response.data.LikeInfo.items[0];
        if (items) {
            console.log(response.data.LikeInfo.items[0])
            UserlikeData(items);
          };
        */                    
    }).catch(e => {console.log(e);UserlikeData('error')});
}