import * as React from 'react';
import { View, Text, ImageBackground,TouchableOpacity, Dimensions, FlatList, Linking, TextInput, ScrollView} from 'react-native';
//import TutoVideo from './TutoVideo';
import { BrowserRouter as Router, Route, Link, HashRouter, withRouter , Switch} from "react-router-dom";
import { FaHome, FaUsers, FaPlus} from 'react-icons/fa';
import * as actions from '../actions';
import { connect } from 'react-redux';
import { FaLock, FaEdit,} from 'react-icons/fa';
import { IoMdText, IoIosExit, IoMdPaper,IoMdPerson, IoIosArrowBack} from 'react-icons/io';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { MdLooks5,MdEdit,MdDelete, MdKeyboardArrowRight, MdKeyboardArrowDown, MdArrowDropUp, MdArrowDropDown} from 'react-icons/md';
import {UserInfo} from '../AjaxCall/UserInfo';
import { withAuthenticator, PhotoPicker,  SignIn, SignUp, Authenticator,ConfirmSignIn, ConfirmSignUp, Loading, TOTPSetup ,ForgotPassword,  AmplifySignOut } from '@aws-amplify/ui-react';
import DatePicker from "react-datepicker";
import Geocode from "react-geocode";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from 'semantic-ui-react';
import {SaveGroupInfo} from '../AjaxCall/SaveGame'

Geocode.setApiKey("AIzaSyA-z56tRb6auu8CamY5PFhyXlalqcuPOgw");
Geocode.setLanguage("en");

class CreateGame extends React.Component {
    constructor(props) {
        super(props);        
        this.state = {            
            
            homeTeamName:'',
            awayTeamName:'',
            sport:'',
            visible:false,
           startDate: new Date(),
           sport: false, 
           loading:false,
           date:new Date(),
           group:'',
           gender:'',
           groupName:'',
           
         
          
        } 
        this.saveTeamData = this.saveTeamData.bind(this)  
        this.getTeamData = this.getTeamData.bind(this)
        this.updateUserInfo = this.updateUserInfo.bind(this)
    }

  updateUserInfo = (data) => {this.props.updateUserInfo(data)}

    saveTeamData  = (data) => { 
        console.log(this.props)
        if (data==='error') {console.log('Did not save');this.setState({loading:true}) }
        else {this.props.history.push(`${this.props.match.url.split("/")[0]}/profile`);this.setState({loading:true}) }
    }

    getTeamData = (data) => {this.setState({playerData: data}) }

    sendGroupInfo = () => {
        const item = {
            username: this.props.userInfo.username,
            trend:0,
            nameofuser:this.props.userInfo.nameofuser,
            appuuid: ('_' + (Number(String(Math.random()).slice(2)) + Date.now() + Math.round(performance.now())).toString(36)),
            gender: this.state.gender,
            sport: this.state.sport,
            date: this.state.date,
            group:this.state.group,
            groupName:this.state.groupName,
            members:"",
            requesters:"",
            likeNo: 0,
            dislikeNo: 0,
            commentNo: 0,
            homeTeamName: this.state.homeTeamName,
            awayTeamName: this.state.awayTeamName,
            
        }
        SaveGroupInfo(item,this.saveTeamData)


    }

   

       
    componentDidMount() { 
       // window.scrollTo(0, 0) 
        //UserInfo({username:this.props.userInfo.username },this.updateUserInfo ) 
        console.log(Auth)     
        console.log(this.props)
        this.props.handler('none')
      //  this.props.updateUserEmail(Auth.user.attributes.email)
       // this.props.updateUserLogin(true)
      
    }

    UUID = () => {
        return ('_' + (Number(String(Math.random()).slice(2)) + Date.now() + Math.round(performance.now())).toString(36));
    }

    handleChangeSport = (e, { key, value }) => {this.setState({ sport:value })}
    handleChangeGender = (e, { key, value }) => {this.setState({ gender:value })}


    render() {
        console.log(this.props)
        const sports = [
            { key: 'Basketball', value: 'Basketball', text: 'Basketball' },
            { key: 'Soccer', value: 'Soccer', text: 'Soccer' },
            { key: 'Football', value: 'Football', text: 'Football' },
            { key: 'Baseball', value: 'Baseball', text: 'Baseball' },
            { key: 'Other', value: 'Other', text: 'Other' },
          ]

        const gender = [
            { key: 'Male', value: 'Male', text: 'Male' },
            { key: 'Female', value: 'Female', text: 'Female' },
            { key: 'Coed', value: 'Coed', text: 'Coed' },
        ]

        const ExampleCustomInput = ({ value, onClick }) => {
            console.log(value)
            return (
            <View style={{flexDirection:'row', borderWidth:1,color:'black',backgroundColor:'#F1F1F1',padding:7,fontWeight:450, height:40, width:'100%', alignSelf:'left',borderColor:'#D3D5D6',borderRadius:3}} onClick={onClick}>
                <Text style={{flex:1, fontSize:14, fontWeight:350,alignSelf:'center'}}>{value}</Text> 
             
                        <MdArrowDropDown style={{alignSelf:'center', }} size={21} color='black'/> 
               
                   
            </View>
            )
        
        };
    
        const handleChange = date => {
            this.setState({date,visible:true})
            
        };
        const inputArray = ['Private','Public']
        return (
            
            <View style={{alignSelf:'center', width:300, paddingBottom:20,}}>
                 <Text  style={{fontSize:16, fontWeight:350,  marginTop:20  }}>
                    Group Name
                </Text>
                  <TextInput                                
                      style={{borderColor:'#DBDBDB', marginBottom:3, color: 'black', height: 40, backgroundColor:'white', paddingLeft:5, fontSize:14, borderWidth:1, marginTop:10}}
                      placeholder='Group Name'
                      placeholderTextColor = "#A8A7A7"
                      onChangeText={(state) => {this.setState({groupName:state})}}
                      value={this.state.groupName}
                  />
                
                <Text  style={{fontSize:16, fontWeight:350, marginBottom:10, marginTop:20 }}>
                      Sport
                </Text>
                <Dropdown
                    onChange={this.handleChangeSport}
                    fluid
                    options={sports}
                    placeholder='Sport'
                    selection
                />

                <View>
                <Text  style={{fontSize:16, fontWeight:350,  marginTop:20  }}>
                    Group
                </Text>
                    { inputArray.map((item, index)=>
                        <View style={{flexDirection:'row',marginTop:10}}>
                            <TouchableOpacity onPress= {()=>{this.setState({group:item})}} style={{height:25, width:25, borderRadius:15, borderWidth:1,  justifyContent:'center',backgroundColor:this.state.group===item?'#AD3E3A':'white'}}>
                                <View style={{height:15, width:15, borderRadius:7.5, borderWidth:this.state.group===item?1:0, alignSelf:'center', backgroundColor:'white'}}>
                                
                                </View>
                            </TouchableOpacity>
                            <Text style={{paddingLeft:10, alignSelf:'center', fontSize:16, fontFamily:'Helvetica', color:this.state.group===item?'black':"#A8A7A7"}}>{item}</Text>
                        </View>
                        )
                    }
                </View>
                <Text  style={{fontSize:16, fontWeight:350,  marginTop:20  }}>
                    Home Team Name
                </Text>
                  <TextInput                                
                      style={{borderColor:'#DBDBDB', marginBottom:3, color: 'black', height: 40, backgroundColor:'white', paddingLeft:5, fontSize:14, borderWidth:1, marginTop:10}}
                      placeholder='Home Team Name'
                      placeholderTextColor = "#A8A7A7"
                      onChangeText={(state) => {this.setState({homeTeamName:state})}}
                      value={this.state.homeTeamName}
                  />
                  <Text  style={{fontSize:16, fontWeight:350,  marginTop:20  }}>
                    Away Team Name
                </Text>
                  <TextInput                                
                      style={{borderColor:'#DBDBDB', marginBottom:3, color: 'black', height: 40, backgroundColor:'white', paddingLeft:5, fontSize:14, borderWidth:1, marginTop:10}}
                      placeholder='Away Team Name'
                      placeholderTextColor = "#A8A7A7"
                      onChangeText={(state) => {this.setState({awayTeamName:state})}}
                      value={this.state.awayTeamName}
                  />
                  <Text  style={{fontSize:16, fontWeight:350,  marginTop:20,  marginBottom:10 }}>
                    Date
                </Text>
                <DatePicker
                    //selected={this.state.date }
                    onChange={handleChange}
                   // selected={this.state.date === '' ? new Date() : this.state.date}
                   selected={this.state.date}
                    //showTimeSelect
                    //timeFormat="h:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MM/d/yy"
                    customInput={<ExampleCustomInput />}
                />
             
                <Text  style={{fontSize:16, fontWeight:350, marginBottom:10, marginTop:20 }}>
                      Gender
                </Text>
                <Dropdown
                    onChange={this.handleChangeGender}
                    fluid
                    options={gender}
                    placeholder='Gender'
                    selection
                />
               
                
        
                    {console.log(this.state.homeTeamName +'' + this.state.awayTeamName +' ' + this.state.sport + ' ' +
                     this.state.group + ' ' + this.state.gender + ' ')}
                <TouchableOpacity onPress = {()=> this.sendGroupInfo() } 
                disabled={( this.state.homeTeamName != '' &&  this.state.awayTeamName != '' && this.state.sport != '' &&
                     this.state.group != '' && this.state.gender != '' && this.state.groupName != ''  ) 
                    ? false:true} 
                style={{backgroundColor: this.state.homeTeamName != '' &&  this.state.awayTeamName != '' && this.state.sport != '' &&
                this.state.group != '' && this.state.gender != '' && this.state.groupName != ''  ? '#3F733F': '#D4E3D4',marginTop:20, height:40, justifyContent:'center',borderRadius:3}}>
                    <Text style={{fontSize:18,alignSelf:'center',fontWeight:590,color:'#F6F8F6'}}>CREATE</Text>
                </TouchableOpacity>
           
            </View>
                        
                 
        )
    }

}




const mapStateToProps = state => {
    return {         
        teamInfo: state.teamInfo, 
        userInfo:state.userInfo,
        tutoComplete: state.tutoComplete, 
        userLogin: state.userLogin,   
    }  
}

const MyTheme = {    
    button: { backgroundColor: "green", borderColor: "red" },
    //signInButtonIcon: { display: "none" },
  };
/*
export default (withAuthenticator(connect(mapStateToProps, actions)(CreateGame),false, [], null, MyTheme, { 
    usernameAttributes: 'email',
    hiddenDefaults: ['phone_number'],
    signUpFields: [
        { label: "Name", key: "name", required: false, type: "string" }
      ]
},))
*/
export default (connect(mapStateToProps, actions)(CreateGame))


