import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import {Like, LikeReply } from '../UserLikeData/Like';

export const LoadMessages = ({input, MessageData, UserlikeData,userLike, username, loadMessage}) => {
    const Message= `
    query messagesByChannelID ($gameID:String!) {
      messagesByChannelID(gameID: $gameID) {
          nextToken
          items {
            unlikeUsername
            likeUsername
            author
            createdAt
            gameID
            body
            id
            updatedAt
            username
            likeButton
            unlikeButton
            noReply
            replyId
            
          }
        }
      }`
      API.graphql(graphqlOperation(Message,  {gameID: input})).then(response => { 
        console.log(response.data.messagesByChannelID.items)
        Like({gameID:input, username, UserlikeData,index:0,userLike})
        const items = response.data.messagesByChannelID.items.reverse();
            
        if (items) {
            MessageData(items, loadMessage);
          };
                            
    }).catch(e => {console.log(e);MessageData('error')});
}

export const LoadReplyMessages = ({input, MessageData, index, messages, UserlikeData,userLike, username, loadMessage}) => {
  const Message= `
  query messagesByChannelID ($gameID:String!) {
    messagesByChannelID(gameID: $gameID) {
        nextToken
        items {
          unlikeUsername
          likeUsername
          author
          createdAt
          gameID
          body
          id
          updatedAt
          username
          likeButton
          unlikeButton
          noReply
          replyId
        }
      }
    }`
    API.graphql(graphqlOperation(Message,  {gameID: input})).then(response => { 
      const items = response.data.messagesByChannelID.items;
      //LikeReply({gameID:input, username, UserlikeData,index,userLike})
      console.log(response.data.messagesByChannelID.items)
      if (items) {
          const newMessage  = [...messages].reverse()
          newMessage[index].reply = response.data.messagesByChannelID.items
          newMessage[index].close = false
          newMessage[index].reply.reverse()
          const reverseMessage = [...newMessage].reverse()
         // newMessage[index].textInput = null
          MessageData(reverseMessage, loadMessage);
         // LikeReply({gameID:input, username, UserlikeData,index,userLike,newMessage, MessageData})
        };
                          
  }).catch(e => {console.log(e);MessageData('error')});
}
