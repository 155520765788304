import * as React from 'react';
import { View, Text, ImageBackground,TouchableOpacity, Dimensions, FlatList, Linking, TextInput, Image} from 'react-native';
import CommentsPage from './CommentsPage';
import { BrowserRouter as Router, Route, Link, HashRouter, withRouter , Switch} from "react-router-dom";
import './App.css';
import Amplify, { API, graphqlOperation, Auth, Hub  } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignOut,AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { FaHome, FaUsers, FaPlus, FaSearch, FaEllipsisV} from 'react-icons/fa';
import { MdPlayArrow, MdClose, MdSearch} from 'react-icons/md';
import * as actions from './actions';
import { connect } from 'react-redux';
import { IoMdText, IoIosExit, IoMdPaper,IoMdPerson} from 'react-icons/io';
import { BiHomeAlt } from 'react-icons/bi';
import {HiOutlineDotsCircleHorizontal} from 'react-icons/hi';
import CreateGame from './CreateGame/CreateGame';
import { Dropdown, Input, Icon } from 'semantic-ui-react';
import {VscSignIn} from 'react-icons/vsc';
import HomePage from './HomePage'
import 'semantic-ui-css/semantic.min.css'
import ProfilePage from './ProfilePage/ProfilePage';
import UserProfilePage from './ProfilePage/UserProfilePage';
import {UserInfo} from './AjaxCall/UserInfo';
import './App.css';
import CheckMember from './CheckMember';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import SearchPage from './SearchPage';
import olympic from './Picture/olympic.png';
import ContactUs from './ContactUs';
import Privacy from './privacy';



class App extends React.Component {
    constructor(props) {
        super(props); 
        this.state = { 
            PageSizeWidth:Dimensions.get('window').width,
            viewingPage:'home',
            userLogin:false,
            user:{},
            check:false,
            TrendingMainHeadingSizeWidth: Dimensions.get('window').width<=770 ? Dimensions.get('window').widthn : 800,
            
        } 
        this.Pagehandler = this.Pagehandler.bind(this) ;
        this.updateUserInfo = this.updateUserInfo.bind(this)
    }

     updateUserInfo = (data) => {
        // this.props.updateUserInfo(data);console.log(data)
        //this.setState({check:true})
        }
   



    Pagehandler= (val) => {
        
        if (this.state.viewingPage != val) {
            this.setState({viewingPage: val})
        }
      }
      /*
      componentDidUpdate() {
          try {
        UserInfo({username: Auth.user.username}, this.updateUserInfo);
          }
          catch(err) {
              console.log(err)
          }
      }
      */

    componentDidMount() {   
        console.log('type A')    
        this.handleResize()

        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
              case "signIn":
                this.setState({ user: data });
                break;
              case "signOut":
                this.setState({ user: null });
                break;
              case "customOAuthState":
                this.setState({ customState: data });
            }
          });
      
          Auth.currentAuthenticatedUser()
            .then(user => this.setState({ user }))
            .catch(() => console.log("Not signed in"));
      
       Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {console.log(user);
            UserInfo({username: Auth.user.username}, this.props, this.updateUserInfo ); 
            //this.setState({check:true})
        })
        .catch(err => {console.log(err);
            //this.setState({check:true})
            ;
        
        })
        
    }


    handleAuthStateChange = (nextAuthState, authData,) => {
        //window.location.reload();
        console.log(nextAuthState)
        console.log(authData)
        if (nextAuthState === 'signedin') {
            console.log(this.props.userInfo)
            if (this.props.userInfo.email=='') {
               UserInfo({username: Auth.user.username}, this.props);
            }
          console.log("user successfully signed in!");
          //console.log("user data: ", authData);
        }
        if (authData) {
         // console.log("authData: ", authData);
        }
      };

    handleResize = () => {
        if (Dimensions.get('window').width<=770){
         this.setState({TrendingMainHeadingSizeWidth:Dimensions.get('window').width, })
        }
        else {
        this.setState({TrendingMainHeadingSizeWidth:800, }) 
        }
    }
    handleChangeMore = (e, { key, value, text }) => {
        console.log(e.target)
        console.log(e.target.innerText)
        if (e.target.innerText==='Sign Out') {
           Auth.signOut();
            this.props.resetUserInfo("");
            this.props.resetUserInfo(); 
            this.props.updateUserLogin(false);
            console.log(this.props)
           // this.props.updateSaveTeamInfo([])
        }
        else if (e.target.innerText==='Privacy'){
          
        }
        else if (e.target.innerText==='Help and Support'){
            <Link to="/Help and Support"/>
        }
        else{

        }
        console.log(this.props)
        
        
    }
    /*
     federated={{
                googleClientId:
                  'xxxx',
                facebookAppId: 'xxxxx'
              }}>
    https://cognito-idp.us-east-1.amazonaws.com
     { 
                 <div slot="sign-in">
                    <AmplifySignIn handleAuthStateChange={this.handleAuthStateChange}  >
                        <div slot="federated-buttons">
                        <button onClick={() => Auth.federatedSignIn({provider: 'Google'})}>Open Google</button>
                        <hr />
                        </div>
                    </AmplifySignIn>
                    </div>
                 }
        */
   
    
    render () { 
        

        const trigger = (
        <View style={{flex:1,flexDirection:'row', alignItems:'center',backgroundColor:'white',}} >
            <HiOutlineDotsCircleHorizontal size={20} color={'#454545'}/>
            <Text style = {{color:'#454545', fontSize:20,fontWeight:550, marginLeft:20}}>More</Text>
        </View>
          )

          const triggerMobile = (
            <View style={{flex:1, alignItems:'center',backgroundColor:'white',}} >
                <HiOutlineDotsCircleHorizontal size={20} color={'#454545'}/>
                <Text style = {{color:'#454545', fontSize:16,fontWeight:550, }}>More</Text>
            </View>
              )

         
        
        const optionsA = [
            { key: 'sign-out',  text: 'Sign Out' },
           // { key: 'Privacy',  text: 'Privacy' },
          //  { key: 'Help and Support',  text: 'Help and Support' },
          ]
          const optionsB = [
          //  { key: 'Privacy',  text: 'Privacy' },
          //  { key: 'Help and Support',  text: 'Help and Support' },
          ]
      
          
        const routes = [
            {
              path: "/",
              exact: true,
              main: (props) => <HomePage {...props} handler = {this.Pagehandler}/>
            },
            {
                path: "/search",
                exact: true,
                main: (props) => <SearchPage {...props} handler = {this.Pagehandler}/>
              },
            {
              path: "/create",
              main: (props) =>   (       
              <View style={{ }}> 
                    <View style={{height:100,justifyContent:'center'}}>
                        <Text style={{fontSize:26, fontWeight:450,textAlign:'center'  }}>
                                CREATE GAME CONVERSATION
                        </Text>
                        {this.props.userLogin ? null :
                        <Text style={{fontSize:18, fontWeight:450, alignSelf:'center', marginTop:5, color: '#371616' }}>
                                SIGN IN OR CREATE FREE ACCOUNT
                        </Text>
                        }
                    </View>
                    <View style={{borderBottomColor: '#DADADA', borderBottomWidth: 1, marginBottom:this.props.userLogin ? 0 : -150}}/>
                    
                    <AmplifyAuthenticator handleAuthStateChange={this.handleAuthStateChange}>
                        <AmplifySignIn
                        
                            headerText=""
                            slot="sign-in"
                        ></AmplifySignIn>
                     <AmplifySignUp
                            slot="sign-up"
                            formFields={[
                            { type: "username" },
                            { type: "password" },
                            { type: "email" },
                            { type: "name", label: "Name", },
                            ]}
                        />   
                        
                            <View>
                                <CreateGame {...props} handler = {this.Pagehandler} userInfo={this.props.userInfo}/>
                            </View>
                    </AmplifyAuthenticator>  
                      
                         
                </View>     

               
              )
            },
            {
              path: "/profile",
              main: (props) =>   (
            
             <AmplifyAuthenticator  handleAuthStateChange={this.handleAuthStateChange}   >
               

              
                  <AmplifySignUp
                      slot="sign-up"
                      formFields={[
                      { type: "username" },
                      { type: "password" },
                      { type: "email" },
                      { type: "name", label: "Name", },
                      ]}
                  />
                    <ProfilePage {...props} handler = {this.Pagehandler} userInfo={this.props.userInfo} />
                 
              </AmplifyAuthenticator>  
              )
            },
            {
                path: "/help",
                main: () => <ContactUs />
              },
              {
                path: "/support",
                main: () => <Privacy />
              },
              {
                path:'/game/:id',
                main: (props) =>  (
                    console.log(this.props.userInfo.username),
                    this.props.userInfo.username==""?
                    <CheckMember {...props} handler = {this.Pagehandler} userInfo={this.props.userInfo}/>
                    :
                    <AmplifyAuthenticator handleAuthStateChange={this.handleAuthStateChange}>
                        <AmplifySignUp
                           slot="sign-up"
                            formFields={[
                            { type: "username" },
                            { type: "password" },
                            { type: "email" },
                            { type: "name", label: "Name", },
                            ]}
                        />
                       
                       <CheckMember {...props} handler = {this.Pagehandler} userInfo={this.props.userInfo}/>
                    </AmplifyAuthenticator>  
                    
                   
                )
              },

            {
                path:'/search/:id',
                main: (props) =>  <UserProfilePage {...props} handler = {this.Pagehandler} userInfo={this.props.userInfo} />
            }
          ];
          console.log(this.props)
        return (  
           // this.state.check ? 
         
            <View  style={{flex:1, backgroundColor:'#99ccff',marginBottom: 0, minHeight:this.state.height, }} > 
           
           <ImageBackground  
                source={{uri: olympic}}
                
                imageStyle={{ position:'fixed', backgroundColor:'#99ccff'}}
                resizeMode='repeat'
                style={{  width: undefined, height: undefined,backgroundColor:'#99ccff' }}     >      
                      
            <View style={{marginBottom:70,  width:  this.state.TrendingMainHeadingSizeWidth, alignSelf:'center',backgroundColor:'white', marginBottom:0}}> 
                
            <Router>
                <View style={{ flexDirection: isMobile ? "column" : "row" }}>
                {
                    isMobile ? 
                    <View style={{ flex: 2.5, borderLeftColor: '#DADADA',borderLeftWidth:0.1, marginBottom:50}}>
                        <Switch>
                            {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => <route.main {...props} />} 
                               //children={(props) => <route.main  {...props} />} 
                            />
                            ))}
                        </Switch>
                    </View>
                    :
                    null
                }
                    {
                    isMobile ? 
                    <View style={{backgroundColor:  'white' ,  position: 'fixed', width: '100%', margin: 0,bottom: 0, height:50 }}>
                            <View style={{flexDirection:'row', flex:1, paddingTop:5}}>
                                <Link style={{ textDecoration: 'none', flex:1, }} to="/" onClick={()=> {this.setState({viewingPage:'home'}); }}>
                                    <View style={{ alignItems:'center',marginBottom:30}} >
                                        <BiHomeAlt  size={20} color={this.state.viewingPage==='home'?'#C20606':'#454545'}/>
                                        <Text style = {{color:this.state.viewingPage==='home'?'#C20606':'#454545', fontSize:16,fontWeight:550, }}>Home</Text>
                                    </View>
                                </Link>
                                {
                                this.props.userLogin ?
                                    <Link style={{ textDecoration: 'none', flex:1,}} to="/profile" onClick={()=> {this.setState({viewingPage:'profile'}); }}>
                                        <View style={{alignItems:'center', marginBottom:30}} >
                                            <IoMdPerson size={20} color={this.state.viewingPage==='profile'?'#C20606':'#454545'}/>
                                            <Text style = {{color:this.state.viewingPage==='profile'?'#C20606':'#454545', fontSize:16,fontWeight:550,}}>Profile</Text>
                                        </View>
                                    </Link> 
                                :  
                                    <Link style={{ textDecoration: 'none' , flex:1,}} to="/profile" onClick={()=> {this.setState({viewingPage:'profile'}); }}>
                                        <View style={{ alignItems:'center',backgroundColor:'white', marginBottom:30}} >
                                            <VscSignIn size={20} color={this.state.viewingPage==='profile'?'#C20606':'#454545'}/>
                                            <Text style = {{color:this.state.viewingPage==='profile'?'#C20606':'#454545', fontSize:16,fontWeight:550, }}>Sign In</Text>
                                        </View>
                                    </Link> 
                                }
                                {
                                <View style={{flex:1}}>
                                    <Dropdown 
                                        onChange={this.handleChangeMore} 
                                        upward 
                                        trigger={triggerMobile} 
                                        options={this.props.userLogin ? optionsA : optionsB} 
                                        icon=''
                                        value=''/>

                                </View>
                                }
                                {/*
                                <Link style={{ textDecoration: 'none',flex:1 }} to="/search" onClick={()=> {this.setState({viewingPage:'search'}); }}>
                                    <View style={{ alignItems:'center', marginBottom:30, }} >
                                       <FaSearch size={20} color={'#454545'}/>
                                        <Text style = {{ fontSize:15,fontWeight:550, color: '#454545'}}>Search</Text>
                                    </View>
                                </Link> 
                                */}
                                <Link style={{ textDecoration: 'none',flex:1 }} to="/create" onClick={()=> {this.setState({viewingPage:'create'}); }}>
                                    <View style={{ alignItems:'center', marginBottom:30, }} >
                                       <FaPlus size={20} color={'#454545'}/>
                                        <Text style = {{ fontSize:15,fontWeight:550, color: '#454545'}}>Create Game</Text>
                                    </View>
                                </Link> 


                            </View>
                    </View>
                    :
                  
                    <View style={{flex:1,background: "white", padding:10, paddingTop:50}}>
                        
                        <View style={{position: 'fixed', }}>
                            <Link style={{ textDecoration: 'none' }} to="/" onClick={()=> {this.setState({viewingPage:'home'}); }}>
                            <View style={{flex:1,flexDirection:'row', alignItems:'center',backgroundColor:'white', marginBottom:30}} >
                                    <BiHomeAlt  size={20} color={this.state.viewingPage==='home'?'#C20606':'#454545'}/>
                                    <Text style = {{color:this.state.viewingPage==='home'?'#C20606':'#454545', fontSize:20,fontWeight:550, marginLeft:20}}>Home</Text>
                                    </View>
                            </Link>
                            {
                                this.props.userLogin ?
                                <Link style={{ textDecoration: 'none' }} to="/profile" onClick={()=> {this.setState({viewingPage:'profile'}); }}>
                                    <View style={{flex:1,flexDirection:'row', alignItems:'center',backgroundColor:'white', marginBottom:30}} >
                                        <IoMdPerson size={20} color={this.state.viewingPage==='profile'?'#C20606':'#454545'}/>
                                        <Text style = {{color:this.state.viewingPage==='profile'?'#C20606':'#454545', fontSize:20,fontWeight:550, marginLeft:20}}>Profile</Text>
                                    </View>
                                </Link> 
                              :  
                                <Link style={{ textDecoration: 'none' }} to="/profile" onClick={()=> {this.setState({viewingPage:'profile'}); }}>
                                    <View style={{flex:1,flexDirection:'row', alignItems:'center',backgroundColor:'white', marginBottom:30}} >
                                        <VscSignIn size={20} color={this.state.viewingPage==='profile'?'#C20606':'#454545'}/>
                                        <Text style = {{color:this.state.viewingPage==='profile'?'#C20606':'#454545', fontSize:20,fontWeight:550, marginLeft:20}}>Sign In</Text>
                                    </View>
                                </Link> 
                            }
                            {/*
                            <Link style={{ textDecoration: 'none' }} to="/search" onClick={()=> {this.setState({viewingPage:'search'}); }}>
                                <View style={{flex:1,flexDirection:'row', alignItems:'center',backgroundColor:'white', marginBottom:30}} >
                                    <MdSearch size={20} color={this.state.viewingPage==='search'?'#C20606':'#454545'}/> 
                                    <Text style = {{color:this.state.viewingPage==='search'?'#C20606':'#454545', fontSize:20,fontWeight:550, marginLeft:20}}>Search</Text>
                                    </View>
                            </Link>
                            */}
                            
                        
                            <Dropdown 
                            onChange={this.handleChangeMore} 
                            upward 
                            trigger={trigger} 
                            options={this.props.userLogin ? optionsA : optionsB} 
                            icon=''
                            value=''/>
                            
                          
                           
                            <Link style={{ textDecoration: 'none' }} to="/create" onClick={()=> {this.setState({viewingPage:'create'}); }}>
                                <View style={{flex:1,flexDirection:'row', alignItems:'center',backgroundColor:'white', marginBottom:30, marginTop:10, borderRadius:20, justifyContent:'center',}} >
                                    <FaPlus size={20} color={this.state.viewingPage==='create'?'#C20606':'#454545'}/>
                                    <Text style = {{color:this.state.viewingPage==='create'?'#C20606':'#454545', fontSize:20,fontWeight:550,marginLeft:20 }}>New Conversation</Text>
                                </View>
                            </Link> 
                            
                        </View>

                    </View>
                   
                    }
                    {
                    isMobile ? 
                    null
                    :
                    <View style={{ flex: 2.5, borderLeftColor: '#DADADA',borderLeftWidth:0.1, }}>
                        <Switch>
                            {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                render={(props) => <route.main {...props} />} 
                               //children={(props) => <route.main  {...props} />} 
                            />
                            ))}
                        </Switch>
                    </View>
                    }
                </View>
            </Router>
          
            </View>
            </ImageBackground >
                    
                
              
            </View>
           // :
           // false
           
    /*
    onChange={this.handleChangeMore} 
            <View style={{flex:1, backgroundColor:'#EAEAEA',marginBottom: 0, minHeight:this.state.height,paddingTop:20}}>     
                <View style={{marginBottom:70,  width:  this.state.TrendingMainHeadingSizeWidth, alignSelf:'center',backgroundColor:'white', marginBottom:20}}> 
                    
                    <CommentsPage />
                
                </View>
            </View>   
            */
        );

    }
}

const mapStateToProps = state => {
    return { 
        viewingPage: state.viewingPage,
        currentTeamSel: state.currentTeamSel,
        teamInfo: state.teamInfo,
        userInfo: state.userInfo,
        tutoComplete: state.tutoComplete,
        userLogin: state.userLogin,

    }  
}

//export default connect(mapStateToProps, actions)(withRouter(props => <App{...props}/>));

export default connect(mapStateToProps, actions)(App); 

//export default App;



//export default App;
