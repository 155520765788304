
export default (state = [],  action) => {   
    switch (action.type) {           
        case 'save_team_info':   
           return action.payload;
        default:           
            return state ;
    }

};
