import * as React from 'react';
import { Platform, TextInput, Text, View, Image, FlatList, ScrollView, BackHandler, TouchableOpacity, ImageBackground, Linking, YellowBox, Dimensions} from 'react-native';
import { connect } from 'react-redux';
import * as actions from './actions';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import TextareaAutosize from 'react-textarea-autosize'; 
import {SaveUserFeedBack} from './AjaxCall/SaveUserFeedBack';


class ContactUs extends React.Component  {
    constructor(props) {
        super(props);        
        this.state = {            
            Imageratio:1,
            TrendingMainHeadingSizeWidth:Dimensions.get('window').width ,
            email:'',
            msg:'',
            statemsg:false
        }  
         this.updateSaveUserFeedback = this.updateSaveUserFeedback.bind(this)
    }

    componentDidMount(){
        this.setState({email:this.props.userInfo.email})     
        this.handleResize()  
            window.scrollTo(0, 0)
          this.props.handler('none')
           // this.props.updateUserLogin(true);       
        
    }

    updateSaveUserFeedback = (data) => {
        if (data==='success'){this.setState({statemsg:true}) }
        else{this.setState({mserror:true})}
    }
    
    handleResize = () => {
        if (Dimensions.get('window').width<=770){
            this.setState({TrendingMainHeadingSizeWidth:Dimensions.get('window').width, Imageratio:Dimensions.get('window').width/700})
        }
        else {
            this.setState({TrendingMainHeadingSizeWidth:700, Imageratio:1})
        }
    }


    saveUserMsgOnline = () => {
       // var editUserInfo = this.props.userInfo  
       SaveUserFeedBack({email:this.state.email,msg:this.state.msg}, this.updateSaveUserFeedback)

    }

    render() {
    
        return(
            <View style={{ backgroundColor:'white',flex:1,paddingBottom:10,  marginTop:40, marginBottom:70,width: isMobile? '90%' : this.state.TrendingMainHeadingSizeWidth,alignSelf:'center'}}> 
                 {
                    this.state.statemsg ?
                    <View>
                        <Text style={{textAlign:'center', fontSize:18}}>Thanks for contacting us!</Text>
                        <Text style={{textAlign:'center', fontSize:18}}>We will be in touch with you shortly through email</Text>
                    </View>
                    :
                    <View>
                        <Text style={{fontWeight:490, fontSize:17, color: '#8D8C8C' }}>Email</Text>                            
                        <TextareaAutosize 
                                style={{ backgroundColor:'#E2E1E1',alignSelf:'center',  fontSize:17, borderWidth:0, width:'100%', height:'100%', fontFamily:'sans-serif',marginTop:7,}}
                                onChange={e => {this.setState({email: e.target.value})}}                  
                                value={this.state.email}
                                ref={this.textarea} >
                            </TextareaAutosize>
                        

                        <Text style={{  fontWeight:490, fontSize:17, marginTop:30,color: '#8D8C8C' }}>Message</Text>                   
                    
                        <TextareaAutosize  
                        maxRows={14}
                        rows={14}
                                style={{backgroundColor:'#E2E1E1',alignSelf:'center',  fontSize:17, borderWidth:0, width:'100%', height:'100%', fontFamily:'sans-serif',marginTop:7,}}
                                onChange={e => {this.setState({msg: e.target.value})}}                  
                                value={this.state.msg}
                                height={200}
                                ref={this.textarea} >
                            </TextareaAutosize>
                    
                            <TouchableOpacity disabled={ this.state.msg==='' || this.state.email==='' ? true: false}
                                onPress = {() => {  this.saveUserMsgOnline()} }
                                    style={{width:90,height:40,borderRadius:10, alignSelf:'center', marginTop: 20, backgroundColor: this.state.msg==='' || this.state.email==='' ?  '#D4E3D4' : '#3F733F', justifyContent:'center'}}>
                                <Text style={{fontSize:18,alignSelf:'center', color:'white'}}>Save</Text>
                            </TouchableOpacity>
                    </View>
                  
                }
                          
                    
              
            </View>
         
        )
    }
}

const mapStateToProps = state => {
    return { 
     
        userInfo: state.userInfo, 
       
    }  
}

export default connect(mapStateToProps, actions)(ContactUs);
