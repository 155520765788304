import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import '@aws-amplify/pubsub';

export const SubLikeTable = ({username, UserlikeData,}) => {
    const SubLikeTable = `
    subscription updateLike ($username: String!)  {
        updateLike(username: $username) {
          gameID
          like
          unlike
          username
        }
      }`
    const subscription = API.graphql(graphqlOperation(SubLikeTable,  {username})).subscribe({
        next: ({ provider, value }) => {
            
            console.log(value.data.updateLike)
            UserlikeData(value.data.updateLike)
           
               
        }
    
    });
    return () => {
        subscription.unsubscribe();
    }
    
}

export const SubMessages = ({gameID, MessageData, SubMessageData, messages,  username}) => {
    const SubMessage = `
    subscription updatedComment($gameID: String!) {
        updatedComment(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(SubMessage,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            
            console.log(value)
            const messagesList = [ ...messages];  
            if (messagesList.findIndex(e => e.id == value.data.updatedComment.id ) == -1  ) {        
                messagesList.push(value.data.updatedComment)
                if (username==value.data.updatedComment.username){
                    MessageData(messagesList, true) 
                }
                else {
                    MessageData(messagesList) 
                }
            }
               
        }
    
    });
    return () => {
        subscription.unsubscribe();
    }
    
}

export const SubMessagesReply = ({gameID, MessageData, SubMessageData, messages, updateTextInput, }) => {
    console.log('SubMessagesReply')
    const SubMessage = `
    subscription updatedComment($gameID: String!) {
        updatedComment(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(SubMessage,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            console.log(value.data.updatedComment)
            const messagesList = [ ...messages];
            const messageIndex = messages.findIndex(e => e.id == value.data.updatedComment.gameID )  
            if(messageIndex!=-1)    { 
                if (messagesList[messageIndex].hasOwnProperty('reply')){
                   
                    if (messagesList[messageIndex].reply.findIndex(e => e.id == value.data.updatedComment.id ) == -1 ) {
                        messagesList[messageIndex].reply.push(value.data.updatedComment)
                       
                    }
                   
                    
                }
                else {
                    messagesList[messageIndex].close=true
                }
                MessageData(messagesList)
            }
           
        }
    
    });
    return subscription
}

export const SubRepliesNo = ({gameID, MessageData, SubMessageData, messages }) => {
    
    const SubRepliesNo = `
    subscription  updateReply ($gameID: String!) {
        updateReply(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(SubRepliesNo,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            console.log(value)
          
            if (messages.filter(e => e.id === value.data.updateReply.id).length > 0) {
                const index = messages.findIndex( e => e.id === value.data.updateReply.id);
                const messagesList = [...messages];
                const oldreplyno = messages[index].noReply
                messagesList[index].noReply = value.data.updateReply.noReply;
                
                    MessageData(messagesList,true);
               
                
                console.log(messagesList)
            }
             
        }
    
    });
    return () => {
        subscription.unsubscribe();
}}

export const SubDecreaseRepliesNo = ({gameID, MessageData, SubMessageData, messages }) => {
    
    const SubRepliesNo = `
    subscription  deReplyNo ($gameID: String!) {
        deReplyNo(gameID: $gameID) {
            gameID
            id
            updatedAt
            likeUsername
            unlikeUsername
            noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(SubRepliesNo,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            
        
           if (messages.filter(e => e.id === value.data.deReplyNo.id).length > 0) {
                const index = messages.findIndex( e => e.id === value.data.deReplyNo.id);
                const messagesList = [...messages];
                messagesList[index].noReply= value.data.deReplyNo.noReply;
                MessageData(messagesList,true);
                
            }
            
             
        }
    
    });
    return () => {
        subscription.unsubscribe();
}}


export const SubAddLike = ({gameID, MessageData, SubMessageData, messages }) => {
    console.log('SubAddLike')
    const AddLike = `
    subscription  addLike($gameID: String!) {
        addLike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
           
            if (messages.filter(e => e.id === value.data.addLike.id).length > 0) {
                const index = messages.findIndex( e => e.id === value.data.addLike.id);
                const messagesList = [...messages];
                messagesList[index].likeButton = value.data.addLike.likeButton;
                MessageData(messagesList);
                console.log(messagesList)
              }
             
           
        }
    
    });
    return () => {
        subscription.unsubscribe();;
    }
       // SubMessageData(subscription )
}

export const SubAddUnlike = ({gameID, MessageData, SubMessageData, messages }) => {
    
    const AddLike = `
    subscription  addUnlike($gameID: String!) {
        addUnlike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            if (messages.filter(e => e.id === value.data.addUnlike.id).length > 0) {
                /* vendors contains the element we're looking for */
                const index = messages.findIndex( e => e.id === value.data.addUnlike.id);
                const messagesList = [...messages];
                messagesList[index].unlikeButton = value.data.addUnlike.unlikeButton;
                MessageData(messagesList);
                console.log(messagesList)
              }
           // const messagesList = [ ...messages];
           // messagesList.unshift(value.data.updatedComment)
           // MessageData(messagesList)
           
        }
    
    });
    return () => {
        subscription.unsubscribe();;
    }
       // SubMessageData(subscription )
}

export const SubMinusLike = ({gameID, MessageData, SubMessageData, messages }) => {
  
    const AddLike = `
    subscription  removeLike($gameID: String!) {
        removeLike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            
            if (messages.filter(e => e.id === value.data.removeLike.id).length > 0) {
                const index = messages.findIndex( e => e.id === value.data.removeLike.id);
                const messagesList = [...messages];
                messagesList[index].likeButton = value.data.removeLike.likeButton;
                MessageData(messagesList);
                console.log(messagesList)
              }
           
        }
    
    });
    return () => {
        subscription.unsubscribe();;
    }
       // SubMessageData(subscription )
}

export const SubMinusUnlike = ({gameID, MessageData, SubMessageData, messages }) => {
    
    const AddLike = `
    subscription  removeUnlike($gameID: String!) {
        removeUnlike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            if (messages.filter(e => e.id === value.data.removeUnlike.id).length > 0) {
                /* vendors contains the element we're looking for */
                const index = messages.findIndex( e => e.id === value.data.removeUnlike.id);
                const messagesList = [...messages];
                messagesList[index].unlikeButton = value.data.removeUnlike.unlikeButton;
                MessageData(messagesList);
                console.log(messagesList)
              }
           // const messagesList = [ ...messages];
           // messagesList.unshift(value.data.updatedComment)
           // MessageData(messagesList)
           
        }
    
    });
    return () => {
        subscription.unsubscribe();;
    }
       // SubMessageData(subscription )
}

export const SubAddLikeMinusUnlike = ({gameID, MessageData, SubMessageData, messages }) => {
  
    const AddLike = `
    subscription  removeUnlikeAddLike($gameID: String!) {
        removeUnlikeAddLike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            console.log(value)
            if (messages.filter(e => e.id === value.data.removeUnlikeAddLike.id).length > 0) {
                /* vendors contains the element we're looking for */
                const index = messages.findIndex( e => e.id === value.data.removeUnlikeAddLike.id);
                const messagesList = [...messages];
                messagesList[index].likeButton = value.data.removeUnlikeAddLike.likeButton;
                messagesList[index].unlikeButton = value.data.removeUnlikeAddLike.unlikeButton;
                MessageData(messagesList);
                console.log(messagesList)
              }
           // const messagesList = [ ...messages];
           // messagesList.unshift(value.data.updatedComment)
           // MessageData(messagesList)
           
        }
    
    });
    return () => {
        subscription.unsubscribe();;
    }
       // SubMessageData(subscription )
}

export const SubAddUnlikeMinusLike = ({gameID, MessageData, SubMessageData, messages }) => {
    
    const AddLike = `
    subscription  removeLikeAddUnlike($gameID: String!) {
        removeLikeAddUnlike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        noReply
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            console.log(value)
            if (messages.filter(e => e.id === value.data.removeLikeAddUnlike.id).length > 0) {
                /* vendors contains the element we're looking for */
                const index = messages.findIndex( e => e.id === value.data.removeLikeAddUnlike.id);
                const messagesList = [...messages];
                messagesList[index].likeButton = value.data.removeLikeAddUnlike.likeButton;
                messagesList[index].unlikeButton = value.data.removeLikeAddUnlike.unlikeButton;
                MessageData(messagesList);
                console.log(messagesList)
              }
           // const messagesList = [ ...messages];
           // messagesList.unshift(value.data.updatedComment)
           // MessageData(messagesList)
           
        }
    
    });
    return () => {
        subscription.unsubscribe();;
    }
       // SubMessageData(subscription )
}

export const SubDeleteMessage = ({gameID, MessageData, SubMessageData, messages,loadMessage }) => {
    
    const delMesage = `
    subscription   deleteComment($gameID: String!) {
        deleteComment(gameID: $gameID) {
        gameID
        id
      
        }
    }`
    const subscription = API.graphql(graphqlOperation(delMesage ,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            console.log(value)
            const messagesList = [...messages];
            const index = messagesList.findIndex(e => e.id == value.data.deleteComment.id )
            if (index != -1) {
                messagesList[index].body = null
                MessageData(messagesList,loadMessage); 
            }

          const messageReplyIndex  =   messages.findIndex(e => e.id == value.data.deleteComment.gameID )
          if (messageReplyIndex != -1) {
            const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == value.data.deleteComment.id )
            messagesList[messageReplyIndex].reply[ReplyIndex].body=null
            MessageData(messagesList,loadMessage); 
            console.log(messagesList)

          }
        }
    
    });
    return () => {
        subscription.unsubscribe();;
    }
       // SubMessageData(subscription )
}

export const SubDeleteReplyMessage = ({gameID, MessageData, SubMessageData, messages,loadMessage }) => {
    console.log('SubDeleteReplyMessage ')
    console.log(gameID)
    const delMesage = `
    subscription   deleteComment($gameID: String!) {
        deleteComment(gameID: $gameID) {
        gameID
        id
      
        }
    }`
    const subscription = API.graphql(graphqlOperation(delMesage ,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            const messagesList = [...messages];           
            const messageReplyIndex  =   messages.findIndex(e => e.id == value.data.deleteComment.gameID )
            if (messageReplyIndex != -1) {
                const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == value.data.deleteComment.id )
                if (ReplyIndex!=-1) {
                    messagesList[messageReplyIndex].reply[ReplyIndex].body=null
                    MessageData(messagesList,true); 
                }
                

            }
        }
    
    });
    return subscription;
    
       // SubMessageData(subscription )
}



export const SubReplyAddLike = ({gameID, MessageData, SubMessageData, messages }) => {
    const AddLike = `
    subscription  addLike($gameID: String!) {
        addLike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            console.log(value)
            const messagesList = [...messages];           
            const messageReplyIndex  =   messages.findIndex(e => e.id == value.data.addLike.gameID )
            if (messageReplyIndex != -1) {
                console.log('a')
                const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == value.data.addLike.id )
                if (ReplyIndex!=-1) {
                    messagesList[messageReplyIndex].reply[ReplyIndex].likeButton = value.data.addLike.likeButton;
                    console.log(messagesList)
                    MessageData(messagesList); 
                }
            }
            
           
        }
    
    });
    return subscription
    
       // SubMessageData(subscription )
}

export const SubReplyAddUnlike = ({gameID, MessageData, SubMessageData, messages }) => {
    
    const AddLike = `
    subscription  addUnlike($gameID: String!) {
        addUnlike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
           
            const messagesList = [...messages];           
            const messageReplyIndex  =   messages.findIndex(e => e.id == value.data.addUnlike.gameID )
            if (messageReplyIndex != -1) {
                const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == value.data.addUnlike.id )
                if (ReplyIndex!=-1) {
                    messagesList[messageReplyIndex].reply[ReplyIndex].unlikeButton = value.data.addUnlike.unlikeButton;
                    MessageData(messagesList); 
                }
            }
           
        }
    
    });
    return subscription
   
       // SubMessageData(subscription )
}

export const SubReplyMinusLike = ({gameID, MessageData, SubMessageData, messages }) => {
  
    const AddLike = `
    subscription  removeLike($gameID: String!) {
        removeLike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
           
            const messagesList = [...messages];           
            const messageReplyIndex  =   messages.findIndex(e => e.id == value.data.removeLike.gameID )
            if (messageReplyIndex != -1) {
                const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == value.data.removeLike.id )
                if (ReplyIndex!=-1) {
                    messagesList[messageReplyIndex].reply[ReplyIndex].likeButton = value.data.removeLike.likeButton;
                    MessageData(messagesList); 
                }
            }
           
        }
    
    });
    return subscription
    
       // SubMessageData(subscription )
}

export const SubReplyMinusUnlike = ({gameID, MessageData, SubMessageData, messages }) => {
    
    const AddLike = `
    subscription  removeUnlike($gameID: String!) {
        removeUnlike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            const messagesList = [...messages];           
            const messageReplyIndex  =   messages.findIndex(e => e.id == value.data.removeUnlike.gameID )
            if (messageReplyIndex != -1) {
                const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == value.data.removeUnlike.id )
                if (ReplyIndex!=-1) {
                    messagesList[messageReplyIndex].reply[ReplyIndex].unlikeButton = value.data.removeUnlike.unlikeButton;
                    MessageData(messagesList); 
                }
            }
           
           
        }
    
    });
    return subscription
    
       // SubMessageData(subscription )
}

export const SubReplyAddLikeMinusUnlike = ({gameID, MessageData, SubMessageData, messages }) => {
  
    const AddLike = `
    subscription  removeUnlikeAddLike($gameID: String!) {
        removeUnlikeAddLike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            const messagesList = [...messages];           
            const messageReplyIndex  =   messages.findIndex(e => e.id == value.data.removeUnlikeAddLike.gameID )
            if (messageReplyIndex != -1) {
                const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == value.data.removeUnlikeAddLike.id )
                if (ReplyIndex!=-1) {
                    messagesList[messageReplyIndex].reply[ReplyIndex].likeButton = value.data.removeUnlikeAddLike.likeButton;
                    messagesList[messageReplyIndex].reply[ReplyIndex].unlikeButton = value.data.removeUnlikeAddLike.unlikeButton;
                    MessageData(messagesList); 
                }
            }
           
        }
    
    });
    return subscription
  
       // SubMessageData(subscription )
}

export const SubReplyAddUnlikeMinusLike = ({gameID, MessageData, SubMessageData, messages }) => {
    
    const AddLike = `
    subscription  removeLikeAddUnlike($gameID: String!) {
        removeLikeAddUnlike(gameID: $gameID) {
        author
        body
        createdAt
        gameID
        id
        likeButton
        unlikeButton
        updatedAt
        username
        likeUsername
        unlikeUsername
        }
    }`
    const subscription = API.graphql(graphqlOperation(AddLike,  {gameID})).subscribe({
        next: ({ provider, value }) => {
            const messagesList = [...messages];           
            const messageReplyIndex  =   messages.findIndex(e => e.id == value.data.removeLikeAddUnlike.gameID )
            if (messageReplyIndex != -1) {
                const ReplyIndex  = messagesList[messageReplyIndex].reply.findIndex(e => e.id == value.data.removeLikeAddUnlike.id )
                if (ReplyIndex!=-1) {
                    messagesList[messageReplyIndex].reply[ReplyIndex].likeButton = value.data.removeLikeAddUnlike.likeButton;
                    messagesList[messageReplyIndex].reply[ReplyIndex].unlikeButton = value.data.removeLikeAddUnlike.unlikeButton;
                    MessageData(messagesList); 
                }
            }
           
        }
    
    });
    return subscription
    
       // SubMessageData(subscription )
}