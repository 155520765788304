import * as React from 'react';
import { Platform, Text, View, TextInput, Image, FlatList, ScrollView, BackHandler, TouchableOpacity, ImageBackground, Linking, YellowBox, Dimensions} from 'react-native';
import { connect } from 'react-redux';
import * as actions from './actions';
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { FaCog,FaSearchengin } from 'react-icons/fa';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import { MdPlayArrow, MdClose, MdSearch} from 'react-icons/md';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
//import UserProfilePage from './UserProfilePage';
import {LoadGameSearchHomeTeam, LoadGameSearchAwayTeam, SearchGame,} from './AjaxCall/LoadGame';
import { MdLooks5,MdEdit,MdDelete, MdKeyboardArrowRight, MdKeyboardArrowDown, MdArrowDropUp, MdArrowDropDown} from 'react-icons/md';
import { Dropdown, Input, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css'
import {ConvoCard, ProfileCard} from './Components/CustomButton';
import ProfilePage from './ProfilePage/ProfilePage';


const moment = require('moment-timezone');
const AMPLITUDE_KEY = "265ecd4214dc923fea7ba20a854373ff";


class SearchPage extends React.Component {
    constructor(props) {
        super(props); 
        this.state = { 
        search:'',
        borderWidth: 0,
        isFocused: true,
        borderRadius:20,
        searchdata:[], 
        searchUsename:[],
        nosearchResultFound:false,
        loading:false,
        username:'',
        searchTextOutput: '',
        dateTextInput:'Date',
        milesTextInput:'Near me',
        gameTypeTextInput: 'Game Type',
        ageGroupTextInput: 'Age Group',
        genderTextinput: 'Gender',
        lat:'',
        lng:''.length,
        height:Dimensions.get('window').height ,
        } 
        this.updateSearchGame =    this.updateSearchGame.bind(this)
    }
searchCounter =  0


//------------------------------------------------------------------------------------------------------------------------------------
  
  
updateSearchGame = (data) => {
   if (data==='error') {   
    this.setState({loading:false,  searchTextOutput:'Having Isues retrieving game' })
   }
   else {
    this.setState({loading:false, searchdata:data, searchTextOutput:'Nothing Found Try Another Search \nOr \nExpand Search Criteria' })
   }
}

    componentDidMount(){
        window.scrollTo(0, 0)
        this.props.handler('search')    
        this.handleResize()
        console.log(this.props.searchinput)
        //this.SearchInput(this.props.searchinput)
        
    }

    handleResize = () => {
        if (Dimensions.get('window').width<=770){
            this.setState({TrendingMainHeadingSizeWidth:Dimensions.get('window').width, Imageratio:Dimensions.get('window').width/700})
        }
        else {
            this.setState({TrendingMainHeadingSizeWidth:700, Imageratio:1})
        }
    }

    handleFocus = () => this.setState({isFocused: true})
    handleBlur = () => this.setState({isFocused: false})   

    SearchInput = () => {      
        if (this.state.search.trim() === '' && this.state.milesTextInput==='Start Date' && this.state.milesTextInput==='Game Type' && this.state.milesTextInput==='Age Group' 
        && this.state.milesTextInput==='Gender') {
            this.setState({loading:false, searchTextOutput:'Nothing Found Try Another Search \nOr \nExpand Search Criteria'})
        }
       
        else {
            this.setState({loading:true,searchdata:[],searchUsename:[]})
            const gameType = this.state.gameTypeTextInput==='Game Type' || this.state.gameTypeTextInput==='Show All' ? null : this.state.gameTypeTextInput
            const ageGroup = this.state.ageGroupTextInput==='Age Group' || this.state.ageGroupTextInput==='Show All' ? null : this.state.ageGroupTextInput
            const gender = this.state.genderTextinput=== 'Gender' || this.state.genderTextinput==='Show All'? null : this.state.genderTextinput
            const stopDate = this.state.dateTextInput === 'Start Date' || this.state.dateTextInputt==='Show All'?  null : moment.utc( moment().endOf('day')).format()
            const input = this.state.search === '' ? null : this.state.search.trim()
            switch (this.state.dateTextInput) {
                case 'Show All' :
                    var startDate = null;
                break
                case 'Today' :
                   var startDate =  moment.utc( moment().startOf('day')).format() ;
                break
                case '2 days' : 
                    var startDate  = moment.utc( moment().subtract(1, 'days').startOf('day')).format() ;
                break
                case 'A Week' :  
                    var startDate  = moment.utc( moment().subtract(7, 'days').startOf('day')).format() ;
                                         
                break
                case 'A Month' :    
                    var startDate  =  moment.utc( moment().subtract(30, 'days').startOf('day')).format() ;
                     
                break
                case '3 Months' :   
                    var startDate  = moment.utc( moment().subtract(90, 'days').startOf('day')).format() ;
                       
                break
                case '6 Months' :
                    var startDate  =  moment.utc( moment().subtract(180, 'days').startOf('day')).format() ;
                          
                break
            }
            if (this.state.milesTextInput==='Near me' || this.state.milesTextInput==='Show All' ) {
                SearchGame({input, gameType, ageGroup, gender, startDate, stopDate},this.updateSearchGame)
            }
            else {
                //DistanceSearchGame({input, gameType, ageGroup, gender, startDate, stopDate, lat:this.state.lat, lng:this.state.lng, distance: this.state.milesTextInput},this.updateSearchGame)
            }
          
            
        }
    }

GetMylocation = (item,updateTextInput) => {
    this.setState({loading:true})
    const showLocation  = (position)=> {
        console.log(position)
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        this.state[updateTextInput] = item
        this.setState({lat:position.coords.latitude,lng:position.coords.longitude,})
        this.SearchInput()
        
    }
    const ErrorHandler = (err)=> {
        console.log(err)
        this.setState({loading:false})
        if(err.code == 1) {
            alert("Access is denied!. Go to Setting to Reset Location and Privacy or use another web browser");
        } else if( err.code == 2) {
            alert("Error: Position is unavailable!");
        }
    }
   // if (updateTextInput==='milesTextInput'){

    if (updateTextInput==='milesTextInput' ) {      
        if (item==='Show All') {
            this.state[updateTextInput] = item
            this.SearchInput()
            this.forceUpdate()
        } 
        else {
            navigator.geolocation.getCurrentPosition(showLocation, ErrorHandler); 
        }
    }
    else if (this.state.milesTextInput==='Near me' || this.state.milesTextInput==='Show All') {
        this.state[updateTextInput] = item
        this.SearchInput()
        this.forceUpdate()
    }
    else {    
        navigator.geolocation.getCurrentPosition(showLocation, ErrorHandler);          
    }
    
}

handleDate = (e, { key, value, text }) => {
    console.log(value)
}

handleGender = (e, { key, value, text }) => {
    console.log(value)
}





searchpage = () => {
const trigger =  (
    <View style={{backgroundColor:'#DFDEDE', flex:1,flexDirection:'row', alignItems:'center', borderRadius:5, paddingTop:7, paddingBottom:7, justifyContent:'center'}}>
        <Text style={{marginLeft:5, marginRight:10}}>Date</Text>
       <MdArrowDropDown size={20} color={'#454545'}/>
    </View>
)
/*
const options = [
    { key: 'Show All', text: 'Show All', value:'show all'},
    { key: 'Today',  text: 'Today' },
    { key: '2 days',  text: '2 days' },
    { key: 'A Week',  text: 'A Week'},
    { key: 'A Month',  text: 'A Month' },
    { key: '3 Months',  text: '3 Months' },
    { key: '6 Months', text: '6 Months' },
  ]
*/
const optionsDate = [
    
    { key: 'Last Month',  text: 'Last Month',value:'Last Month'} ,
    { key: 'Last Week',  text: 'Last Week',value:'Last Week'},
    { key: 'Yesterday',  text: 'Yesterday',value:'Yesterday'} ,
    { key: 'Today',  text: 'Today',value:'Today'},
    { key: 'Tomorrow',  text: 'Tomorrow',value:'Tomorrow'} ,
    { key: 'Next Week',  text: 'Next Week',value:'Next Week'} ,
    { key: 'Next Month',  text: 'Next Month',value:'Next Month'} 
]

const optionsGender = [
    
    { key: 'Male',  text: 'Male',value:'Male'} ,
    { key: 'Female',  text: 'Female',value:'Female'},
    { key: 'Co-ed',  text: 'Co-ed',value:'Co-ed'} 
]



    var inputTemplate = [
                    
                    {dropdownInput:['Show All','Today','2 days','A Week','A Month','3 Months','6 Months'],updateTextInput:'dateTextInput'},
                    {dropdownInput:['Show All','Male','Female','Co-ed'],updateTextInput:'genderTextinput'},
                        ]
    const DropdownView = (inputTemplate) => {
        return (
        <ScrollView style={{  borderWidth:1, borderColor:'#D3D5D6',borderRadius:5,}}>   
            {     
                inputTemplate.dropdownInput.map((item,index) =>    (                           
                        <TouchableOpacity  onPress={()=>  this.GetMylocation(item, inputTemplate.updateTextInput) }>
                            <Text style={{height:30, backgroundColor : '#F1F1F1',fontSize:16, paddingLeft:10 }}>{item}</Text>
                           
                        </TouchableOpacity  >
                    )
                )
            }
        </ScrollView> 
        )
    }
   
    return (
            
             

                <View style={{marginTop:5, flex:1, }}>
                   
                <View style={{margin:10,  flex:1, flexDirection: 'row',marginBottom:20, color: '#030303',  backgroundColor:'white', borderWidth:1,borderColor:'#B5B4B4' }}>
                    <TextInput                               
                        style={{ fontWeight:400, flex:1, textAlign:'center', fontSize:17, height:45 }}                      
                        placeholder="Search Team Name or User Name"
                        placeholderTextColor = "#A8A7A7"
                        onChangeText={(search) => (this.setState({search}) )}                   
                        value={this.state.search}   
                        onSubmitEditing={(e)=>this.SearchInput()}                
                    />  
                    {this.state.search !='' ?
                    <TouchableOpacity onPress={()=> this.setState({search:''})} style={{alignSelf:'center',}}>
                                <MdClose size={25} color='#B5B4B4' /> 
                    </TouchableOpacity> 
                    :
                    null
                    }              
                    <TouchableOpacity onPress={()=> this.SearchInput()} style={{backgroundColor:'#DFDEDE',paddingLeft:15,paddingRight:15,justifyContent:'center', alheight:35 }}>                                    
                            <MdSearch size={25} style={{alignSelf:'center',}} color='black'/>                                      
                    </TouchableOpacity>                              
                </View>
                    {/*
                    <div style={{width:120, marginLeft:20, marginBottom:5, flexDirection:'row'}}>
                    <Dropdown
                        onChange={this.handleDate}
                        fluid
                        options={optionsDate} 
                        placeholder='Game Date'
                        selection
                        style={{backgroundColor:'#DFDEDE',  }}
                    />
                    </div>  
                    */}
               

                

                <View style={{borderBottomColor: '#DADADA', borderBottomWidth: 1,}}/>

                
                    {
                        this.state.loading ?
                        <Loader
                            style={{alignSelf:'center'}}
                            type="ThreeDots"
                            color="#DE4D4D"
                            height={200}
                            width={100}
                        // timeout={3000} //3 secs

                            />
                        :
                        this.state.searchdata.length===0  ? 
                        <View style={{flex:1,  paddingTop:80,paddingBottom:200, }}>
                            <FaSearchengin size={50} color='#DE4D4D' style={{alignSelf:'center'}}/> 
                            <Text style={{fontSize:17,alignSelf:'center',marginTop:5, textAlign:'center'}}>{this.state.searchTextOutput}</Text>
                           
                            
                        </View>
                    :
                        
                    <View style={{backgroundColor:'white', paddingTop:20}}>                      
                                {
                                    this.state.searchdata.map(( item, key, Array) =>  (
                                        item.appuuid===null 
                                        ?
                                        <ProfileCard item = {item} link= {this.props.match.url+'/'+item.username}/>
                                        :
                                        <ConvoCard item = {item}  handler = {this.props.handler} profile={false}/>
                                         
                                    ))
                                    }
                    </View>

                } 
                </View>
            
       
    )
}

  //<ProfilePage routeProps={props}   handler={this.props.handler}/>

    render () {
        console.log(this.props.match.path)
        return (
             <View >          
               
               
                <Route
                    exact
                    path={this.props.match.path}
                    render={() => this.searchpage()}
                />
            </View> 
        )
    }
}

const mapStateToProps = state => {
    return { 
          
    }  
}

export default connect(mapStateToProps, actions)(SearchPage);
