import * as React from 'react';
import { View, Image, Text, ImageBackground,TouchableOpacity, Dimensions, FlatList, Linking, TextInput} from 'react-native';
import {FaDotCircle, FaFlag, FaUserSlash, FaCommentAlt,FaRegFutbol,FaSearchengin} from 'react-icons/fa';
import {AiFillLike, AiFillDislike,} from 'react-icons/ai';
import { BrowserRouter as Router, Route, Link, HashRouter, withRouter , Switch} from "react-router-dom";
import {BsInfo, BsThreeDotsVertical,} from 'react-icons/bs';
import {BiDotsHorizontal, BiDotsHorizontalRounded} from 'react-icons/bi';
import {GoComment} from 'react-icons/go';
import {LoadGameSearchHomeTeam, LoadGameSearchAwayTeam, SearchGame,TrendingGame} from './AjaxCall/LoadGame';
import { MdPlayArrow, MdClose, MdSearch} from 'react-icons/md';
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import {ConvoCard, ProfileCard, HomePageConvoCard} from './Components/CustomButton';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import olympic from './Picture/olympic.png';
import Loader from 'react-loader-spinner';
const moment = require('moment-timezone');

class HomePage extends React.Component {
    constructor(props) {
        super(props); 
        this.state = { 
            viewingPage:'home',
            trendData:[],

            search:'',
        borderWidth: 0,
        isFocused: true,
        borderRadius:20,
        searchdata:[], 
        searchUsename:[],
        nosearchResultFound:false,
        loading:false,
        username:'',
        searchTextOutput: '',
        dateTextInput:'Date',
        milesTextInput:'Near me',
        gameTypeTextInput: 'Game Type',
        ageGroupTextInput: 'Age Group',
        genderTextinput: 'Gender',
        lat:'',
        lng:''.length,
        height:Dimensions.get('window').height ,
        } 
    }

    updateTrendingGame = (data) => {
         const tempData = [... data]
         tempData.reverse()       
        this.setState({trendData:tempData})
    }
    updateSearchGame = (data) => {
        if (data==='error') {   
         this.setState({loading:false,  searchTextOutput:'Having Isues retrieving game' })
        }
        else {
         this.setState({loading:false, searchdata:data, searchTextOutput:'Nothing Found Try Another Search' })
        }
     }

    componentDidMount() { 
        TrendingGame(this.updateTrendingGame) 
    }
    SearchInput = () => {      
        if (this.state.search.trim() === '' && this.state.milesTextInput==='Start Date' && this.state.milesTextInput==='Game Type' && this.state.milesTextInput==='Age Group' 
        && this.state.milesTextInput==='Gender') {
            this.setState({loading:false, searchTextOutput:'Nothing Found Try Another Search'})
        }
       
        else {
            this.setState({loading:true,searchdata:[],searchUsename:[]})
            const gameType = this.state.gameTypeTextInput==='Game Type' || this.state.gameTypeTextInput==='Show All' ? null : this.state.gameTypeTextInput
            const ageGroup = this.state.ageGroupTextInput==='Age Group' || this.state.ageGroupTextInput==='Show All' ? null : this.state.ageGroupTextInput
            const gender = this.state.genderTextinput=== 'Gender' || this.state.genderTextinput==='Show All'? null : this.state.genderTextinput
            const stopDate = this.state.dateTextInput === 'Start Date' || this.state.dateTextInputt==='Show All'?  null : moment.utc( moment().endOf('day')).format()
            const input = this.state.search === '' ? null : this.state.search.trim()
            switch (this.state.dateTextInput) {
                case 'Show All' :
                    var startDate = null;
                break
                case 'Today' :
                   var startDate =  moment.utc( moment().startOf('day')).format() ;
                break
                case '2 days' : 
                    var startDate  = moment.utc( moment().subtract(1, 'days').startOf('day')).format() ;
                break
                case 'A Week' :  
                    var startDate  = moment.utc( moment().subtract(7, 'days').startOf('day')).format() ;
                                         
                break
                case 'A Month' :    
                    var startDate  =  moment.utc( moment().subtract(30, 'days').startOf('day')).format() ;
                     
                break
                case '3 Months' :   
                    var startDate  = moment.utc( moment().subtract(90, 'days').startOf('day')).format() ;
                       
                break
                case '6 Months' :
                    var startDate  =  moment.utc( moment().subtract(180, 'days').startOf('day')).format() ;
                          
                break
            }
            if (this.state.milesTextInput==='Near me' || this.state.milesTextInput==='Show All' ) {
                SearchGame({input, gameType, ageGroup, gender, startDate, stopDate},this.updateSearchGame)
            }
            else {
                //DistanceSearchGame({input, gameType, ageGroup, gender, startDate, stopDate, lat:this.state.lat, lng:this.state.lng, distance: this.state.milesTextInput},this.updateSearchGame)
            }
          
            
        }
    }

    render () {
    
  
        return (
        
            <View style={{}}>
                <ImageBackground  
                source={{uri: isMobile? olympic:'none'}}
                
                imageStyle={{  }}
                resizeMode='repeat'
                style={{  width: undefined, height: undefined, }}     >  
                    <Text style = {{ fontSize:30,fontWeight:550, color: isMobile?'black': 'black', marginBottom:10, textAlign: "center",marginTop:10}}>Join the Game Conversation</Text>
                    <View style={{margin:10,  flex:1, flexDirection: 'row',marginBottom:10, color: '#030303',  backgroundColor:'white', borderWidth:1,borderColor:'#B5B4B4' }}>
                        <TextInput                               
                            style={{ fontWeight:400, flex:1, textAlign:'center', fontSize:17, height:45 }}                      
                            placeholder="Search Team Name or User Name"
                            placeholderTextColor = "#A8A7A7"
                            onChangeText={(search) => (this.setState({search}) )}                   
                            value={this.state.search}   
                            onSubmitEditing={(e)=>this.SearchInput()}                
                        />  
                        {this.state.search !='' ?
                        <TouchableOpacity onPress={()=> this.setState({search:''})} style={{alignSelf:'center',}}>
                                    <MdClose size={25} color='#B5B4B4' /> 
                        </TouchableOpacity> 
                        :
                        null
                        }              
                        <TouchableOpacity onPress={()=> this.SearchInput()} style={{backgroundColor:'#DFDEDE',paddingLeft:15,paddingRight:15,justifyContent:'center',  }}>                                    
                                <MdSearch size={25} style={{alignSelf:'center',}} color='black'/>                                      
                        </TouchableOpacity>                              
                    </View>
                

                <Text style = {{ fontSize:20,fontWeight:550, color: isMobile?'black': 'black', marginBottom:21, textAlign: "center",}}>OR</Text>
                <Link style={{ textDecoration: 'none', alignSelf:'center',marginBottom:20,}} to="/create" onClick={()=> {this.setState({viewingPage:'create'}); }}>
                    
                        <Text style = {{color:'white', fontSize:15,fontWeight:550, backgroundColor:'#A30606', padding:10, borderRadius:10}}>Start New Conversation</Text>
                    
                </Link> 
                </ImageBackground>
                <View style={{ }}>  
                {this.state.searchdata.length!= 0 ? <View style={{borderBottomColor: '#DADADA', borderBottomWidth: 1,}}/> : null}                   
                               
                                    {
                                        this.state.loading ?
                                        <Loader
                                            style={{alignSelf:'center'}}
                                            type="ThreeDots"
                                            color="#DE4D4D"
                                            height={200}
                                            width={100}
                                        // timeout={3000} //3 secs
                
                                            />
                                        
                                            :
                                            this.state.searchdata.length===0  ? 
                                                <Text style={{fontSize:17,alignSelf:'center',marginTop:5, textAlign:'center'}}>{this.state.searchTextOutput}</Text>
                                               
                                                
                                           
                                    :
                                         
                                    this.state.searchdata.map(( item, key, Array) =>  (
                                        item.appuuid===null 
                                        ?
                                        <ProfileCard item = {item} link= {'/search/'+item.username}/>
                                        :
                                        <HomePageConvoCard item = {item}  handler = {this.props.handler} profile={false}/>
                                         
                                    ))
                                    }
                    </View>
                <Text style = {{ fontSize:17,fontWeight:550, color: '#454545', marginBottom:4, marginLeft:10, marginTop:20}}>Trending Games</Text>
                <View style={{borderBottomColor: '#DADADA', borderBottomWidth: 1,}}/>
                {this.state.trendData.map((item, index)=>  <HomePageConvoCard item = {item} handler = {this.props.handler} profile={false}/>
             
        )}
   
            </View>
        )
    }
}
//Love to see me from your point of view <Text>Barcelone Vs Real Madrid</Text>
export default HomePage;