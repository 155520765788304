const usersDefaultState = {homeTeamStats: [
                            {avaliable:true,id:'item-0',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-1',number:'', firstname: '',lastname:''},                        
                            {avaliable:true,id:'item-2',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-3',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-4',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-5',number:'', firstname: '',lastname:''},                        
                            {avaliable:true,id:'item-6',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-7',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-8',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-9',number:'', firstname: '',lastname:''},
                        ],
                        awayTeamStats:[
                            {avaliable:true,id:'item-0',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-1',number:'', firstname: '',lastname:''},                        
                            {avaliable:true,id:'item-2',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-3',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-4',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-5',number:'', firstname: '',lastname:''},                        
                            {avaliable:true,id:'item-6',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-7',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-8',number:'', firstname: '',lastname:''},
                            {avaliable:true,id:'item-9',number:'', firstname: '',lastname:''},
                        ],
                        homeTeamName:'',
                        awayTeamName:'',
                        playMatrix:[],
                        statsPlayMatrix:[],
                        gameTime:[''],
                        gamePlay:false,
                        trend:0,
                        gamePlayHalf:[0,900],
                        hideData:[false, false, false, false],
                        gender:'',
                        city:'',
                        sport:'Basketball',
                        date:new Date(),
                        level:'', 
                        gamepixuuid:''  ,
                        country:'United States',
                        state:'',
                        gameType:'',
                        ageGroup:'',
                        organization:'',
                        conference:'',
                        division:'',
                        zipcode:'',
                        quarter:'',
                        record:'',
                        address:'',
                        lng:'',
                        lat:''

                        }
export default (state = usersDefaultState,  
                        action) => {   
    switch (action.type) { 
        case "reset_teamInfo":
            return {homeTeamStats:
                                    [{avaliable:true, id:'item-0',number:null,time: [0, 0, 0, 0,], firstname: null,lastname:null, freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-1',number:null,time: [0, 0, 0, 0,], firstname: null,lastname:null,freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},                        
                                    {avaliable:true,id:'item-2',number:null,time: [0, 0, 0, 0,], firstname: null,lastname:null,freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-3',number:null,time: [0, 0, 0, 0,], firstname: null,lastname:null,freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-4',number:null,time: [0, 0, 0, 0,], firstname: null,lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-5',number:null,time: [0, 0, 0, 0,], firstname: null,lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-6',number:null,time: [0, 0, 0, 0,], firstname: null,lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},                        
                                    {avaliable:true,id:'item-7',number:null,time: [0, 0, 0, 0,], firstname:null,lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-8',number:null,time: [0, 0, 0, 0,], firstname: null,lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-9',number:null,time: [0, 0, 0, 0,], firstname: null,lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
            
                                    ],
                                    awayTeamStats:
                                    [{avaliable:true,id:'item-0',number:null,time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-1',number:null,time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},    
                                    {avaliable:true,id:'item-2',number:null,time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-3',number:null,time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-4',number:'',time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-5',number:'',time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-6',number:'',time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},    
                                    {avaliable:true,id:'item-7',number:'',time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-8',number:'',time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                    {avaliable:true,id:'item-9',number:'',time:[0, 0, 0, 0,], firstname: '',lastname:'',freethrow:[0, 0, 0, 0,], twopoints:[0, 0, 0, 0,],threepoints:[0, 0, 0, 0,],assist:[0, 0, 0, 0,],defrebound:[0, 0, 0, 0,],steal:[0, 0, 0, 0,],block:[0, 0, 0, 0,],turnover:[0, 0, 0, 0,], freethrowmiss:[0, 0, 0, 0,], foul:[0, 0, 0, 0,],offrebound:[0, 0, 0, 0,],twopointsmiss:[0, 0, 0, 0,],threepointsmiss:[0, 0, 0, 0,]},
                                
                                
                                    ],
                                
                        homeTeamName:'',
                        awayTeamName:'',
                        playMatrix:[],
                        statsPlayMatrix:[],
                        gameTime:[900, 900, 900, 900],
                        gamePlay:false,
                        gamePlayHalf:[0,900],
                        hideData:[false, false, false, false],
                        gender:'',
                        trend:0,
                        city:'',
                        sport:'Basketball',
                        date:new Date(),
                        level:'',
                        gamepixuuid:'' ,
                        country:'United States',
                        state:'',
                        gameType:'',
                        ageGroup:'',
                        organization:'',
                        conference:'',
                        division:'',
                        zipcode:'',
                        quarter:'',
                        record:'',
                        address:'',
                        lng:'',
                        lat:''
                        
                        };       
        case 'team_info':  
    
           return action.payload;
        default:           
            return state ;
    }

};
