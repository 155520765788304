export const updateCurrentSelectedTeam = (Team) => {
    return {
        type: 'Cur_sel_Team',
        payload: Team
    };
};
export const UpdateHomePage = (booleen) => {
    return {
        type: 'updateHome_Info',
        payload: booleen
    };
};

export const UpdateNewPageIndex = (booleen) => {
    return {
        type: 'newInfoIndex',
        payload: booleen
    };
};

export const UpdatePlayerIndex = (booleen) => {
    return {
        type: 'playerIndex',
        payload: booleen
    };
};

export const updateGameFixtures = (fixtures) => {
    return {
        type: 'gameFixtures',
        payload: fixtures
    };
};

export const updateAddedFixtures = (fixtures) => {
    return {
        type: 'added_gameFixtures',
        payload: fixtures
    };
};

export const updateUserEmail = (email) => {
    return {
        type: 'user_email',
        payload: email
    };
};

export const updateGameStanding = (standing) => {
    return {
        type: 'gameStanding',
        payload: standing
    };
};

export const updatePlayerDetailInfo = (playerInfo) => {
    return {
        type: 'player_detail',
        payload: playerInfo
    };
};

export const updateGameRosters = (rosters) => {
    return {
        type: 'gameRosters',
        payload: rosters
    };
};

export const updateTeamNewsData = (news) => {
    return {
        type: 'teamNews',
        payload: news
    };
};
export const updateNextGameElement = (element) => {
    return {
        type: 'next_game_element',
        payload: element
    };
};

export const updatePropsElement = (element) => {
    return {
        type: 'props_element',
        payload: element
    };
};

export const updateUserLogin = (element) => {
    return {
        type: 'user_login',
        payload: element
    };
};

export const updateViewingPage= (element) => {
    return {
        type: 'viewing_page',
        payload: element
    };
};

export const updateCodeInProgress= (element) => {
    return {
        type: 'progress',
        payload: element
    };
};

export const updateTeamInfo= (element) => {
    return {
        type: 'team_info',
        payload: element
    };
};

export const resetTeamInfo = () => {
    return {
        type: 'reset_teamInfo',
        payload: null
    };
};

export const resetUserInfo = () => {
    return {
        type: 'reset_userInfo',
        payload: null
    };
};

export const updateSaveTeamInfo= (element) => {
    return {
        type: 'save_team_info',
        payload: element
    };
};

export const updateUserInfo = (info) => {
    return {
        type: 'user_info',
        payload: info
    };
};

export const updateGameQuarter= (info) => {
    return {
        type: 'game_quarter',
        payload: info
    };
};
export const updateGuideNote= (info) => {
    return {
        type: 'guide_note',
        payload: info
    };
};

export const updateTutoComplete= (info) => {
    return {
        type: 'tuto_complete',
        payload: info
    };
};

export const updateTextInput = (info) => {
    return {
        type: 'TextArray',
        payload: info
    };
};


