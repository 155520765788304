import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';

export const SaveUserInfo = ({UserInfoData},updateSaveInfoFeedback) => {
    const userInfo = `
    mutation loadUserInfo (
        $username:String! 
        $nameofuser:String
        $email:String
        $phoneNumber:String
        $bio:String
        $location:String
        $birthday:String
        $profilepixuuid:String
        )  {
            loadUserInfo (
                username:$username
                nameofuser:$nameofuser
                email:$email
                phoneNumber:$phoneNumber
                bio:$bio
                location:$location
                birthday:$birthday
                profilepixuuid:$profilepixuuid
            )
        {username}
    }`; 
    
    
    API.graphql(graphqlOperation(userInfo, UserInfoData
         )).then(response => { console.log(response); 
            updateSaveInfoFeedback('success')
    }).catch(e => {console.log(e); });
   
}

