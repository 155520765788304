import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
export const AddLikeGroup = ({appuuid}) => {
  
    const updateLikeGroup = `
      mutation AddLikeGroup ($appuuid:String!, ) {
        AddLikeGroup (appuuid: $appuuid ) {
            likeNo
            dislikeNo
            commentNo
          }
        }`
        API.graphql(graphqlOperation(updateLikeGroup,  {appuuid })).then(response => { 
          console.log("add done")
          
                   
      }).catch(e => {console.log(e);
        //MessageData('error')
      });
  }

  export const AddUnlikeGroup = ({appuuid}) => {
  
    const updateLikeGroup = `
      mutation AddUnlikeGroup ($appuuid:String!, ) {
        AddUnlikeGroup (appuuid: $appuuid ) {
            likeNo
            dislikeNo
            commentNo
          }
        }`
        API.graphql(graphqlOperation(updateLikeGroup,  {appuuid })).then(response => { 
          console.log("add done")
          
                   
      }).catch(e => {console.log(e);
        //MessageData('error')
      });
  }

  export const AddCommentNo = ({appuuid}) => {
  
    const updateLikeGroup = `
      mutation AddCommentNo ($appuuid:String!, ) {
        AddCommentNo (appuuid: $appuuid ) {
            likeNo
            dislikeNo
            commentNo
          }
        }`
        API.graphql(graphqlOperation(updateLikeGroup,  {appuuid })).then(response => { 
          console.log("add done")
          
                   
      }).catch(e => {console.log(e);
        //MessageData('error')
      });
  }

  export const RemoveLikeGroup = ({appuuid}) => {
  
    const updateLikeGroup = `
      mutation RemoveLikeGroup ($appuuid:String!, ) {
        RemoveLikeGroup (appuuid: $appuuid ) {
            likeNo
            dislikeNo
            commentNo
          }
        }`
        API.graphql(graphqlOperation(updateLikeGroup,  {appuuid })).then(response => { 
          console.log("add done")
          
                   
      }).catch(e => {console.log(e);
        //MessageData('error')
      });
  }

  export const RemoveUnlikeGroup = ({appuuid}) => {
  
    const updateLikeGroup = `
      mutation RemoveUnlikeGroup ($appuuid:String!, ) {
        RemoveUnlikeGroup (appuuid: $appuuid ) {
            likeNo
            dislikeNo
            commentNo
          }
        }`
        API.graphql(graphqlOperation(updateLikeGroup,  {appuuid })).then(response => { 
          console.log("add done")
          
                   
      }).catch(e => {console.log(e);
        //MessageData('error')
      });
  }

  export const RemoveCommentNo = ({appuuid}) => {
  
    const updateLikeGroup = `
      mutation RemoveCommentNo ($appuuid:String!, ) {
        RemoveCommentNo (appuuid: $appuuid ) {
            likeNo
            dislikeNo
            commentNo
          }
        }`
        API.graphql(graphqlOperation(updateLikeGroup,  {appuuid })).then(response => { 
          console.log("add done")
          
                   
      }).catch(e => {console.log(e);
        //MessageData('error')
      });
  }

  export const RemoveLikeAddUnlikeGroup = ({appuuid}) => {
  
    const updateLikeGroup = `
      mutation RemoveLikeAddUnlikeGroup ($appuuid:String!, ) {
        RemoveLikeAddUnlikeGroup (appuuid: $appuuid ) {
            likeNo
            dislikeNo
            commentNo
          }
        }`
        API.graphql(graphqlOperation(updateLikeGroup,  {appuuid })).then(response => { 
          console.log("add done")
          
                   
      }).catch(e => {console.log(e);
        //MessageData('error')
      });
  }

  export const RemoveUnlikeAddLikeGroup = ({appuuid}) => {
  
    const updateLikeGroup = `
      mutation RemoveUnlikeAddLikeGroup ($appuuid:String!, ) {
        RemoveUnlikeAddLikeGroup (appuuid: $appuuid ) {
            likeNo
            dislikeNo
            commentNo
          }
        }`
        API.graphql(graphqlOperation(updateLikeGroup,  {appuuid })).then(response => { 
          console.log("add done")
          
                   
      }).catch(e => {console.log(e);
        //MessageData('error')
      });
  }

  

  
  