import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, Image, Platform,View, ScrollView,ImageBackground } from 'react-native';
//import Dropdown from 'rc-dropdown';
import { MdLooks5,MdEdit,MdDelete, MdKeyboardArrowRight, MdKeyboardArrowDown, MdArrowDropUp, MdArrowDropDown} from 'react-icons/md';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import LinesEllipsis from 'react-lines-ellipsis';
import {DeleteGame} from '../AjaxCall/DeleteGame';
import Loader from 'react-loader-spinner';
import {FaDotCircle, FaFlag, FaUserSlash, FaCommentAlt,FaRegFutbol, FaBars, FaBasketballBall, FaBaseballBall, FaFootballBall} from 'react-icons/fa';
import {AiFillLike, AiFillDislike,} from 'react-icons/ai';
import {BsInfo, BsThreeDotsVertical,} from 'react-icons/bs';
import {BiDotsHorizontal, BiDotsHorizontalRounded} from 'react-icons/bi';
import {GoComment, GoPerson} from 'react-icons/go';
import { Dropdown, Input, Icon } from 'semantic-ui-react';
import {AddMember, RemoveMember } from '../AjaxCall/SaveGame';
import {LoadUserGame} from '../AjaxCall/LoadUserGame';
import {OtherUserInfo} from '../AjaxCall/UserInfo';


export const HomePageConvoCard = ({item, handler,updateSaveGame,data,loading,loadingIndex,profile,edit, link}) => {
   // console.log(item)
    const [userInfoJoinGame, setuserInfoJoinGame] = useState('')
  

    useEffect(() => { OtherUserInfo({username:item.username},updateUserInfo) }, []);
   

    const  stringToHslColor = (str, s, l) => {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {hash = str.charCodeAt(i) + ((hash << 5) - hash);}
            var h = hash % 360;
             return 'hsl('+h+', '+s+'%, '+l+'%)';
        }
    
   

        const updateUserInfo = (data) => {
            setuserInfoJoinGame(data)
        }
        return (
    
            <View>
                <View style={{ flex:1}}>              
                    <Link to={`${'/game/'+ item.appuuid}`}   style={{ textDecoration: 'none',flex:1,}} onClick={()=> {handler('none')}}>              
                        <View> 
                        <View style={{flexDirection:'row'}}>
                            <Text style={{fontWeight:600,  fontSize:13.5,letterSpacing:0.3, marginLeft:55, marginTop:10,color:'#575757', marginRight:2}}>{ userInfoJoinGame.nameofuser}</Text> 
                            <Text style={{fontWeight:600,  fontSize:13.5,letterSpacing:0.3,  marginTop:10,color:'#575757', marginRight:2}}>@{userInfoJoinGame.username}</Text> 
                        </View>                    
                            <View style={{padding:10, flexDirection:'row'}}>                              
                                <ImageBackground  style={{width:35,height:35, backgroundColor: stringToHslColor(item.username,50,55),  borderRadius:10,}}
                                imageStyle={{ borderRadius: 10 }}
                                source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  item.username+'/'+ userInfoJoinGame.profilepixuuid}} > 
                                    {userInfoJoinGame.profilepixuuid == null ? <GoPerson  color={stringToHslColor(item.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1,}}/>: null}
                                </ImageBackground >                        
                                <View style={{paddingLeft:10,flex:1}}>  
                                                        
                                    <View style={{flexDirection:'row', alignItems:'center', }}>
                                        <Text style={{fontWeight:650,  fontSize:15,letterSpacing:0.3, }}>{ item.groupName}</Text>
                                    </View >
                                    <View style={{alignItems:'center', flexDirection:'row',marginTop:2, }} >
                                        <Text style={{}}>
                                        <Text style={{ fontSize:15, color: '#6B0606', }} numberOfLines={2}>{item.homeTeamName}</Text>
                    
                                        <Text style={{ fontSize:15, color: '#6B0606', }}>{item.awayTeamName}</Text>
                                        </Text>
                                        <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5}}>{'\u2B24'}</Text>
                                        <Text style={{ color:'#575757',fontSize:13,}}>{new Date(item.date).toLocaleDateString()}</Text>
                                        <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5}}>{'\u2B24'}</Text>
                                        <Text style={{ color:'#575757',fontSize:13, }}>{item.gender}</Text>
                                        <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5}}>{'\u2B24'}</Text>
                                        <Text style={{ color:'#575757',fontSize:13,marginRight:5, }}>{item.sport}</Text>
                                        { item.sport == 'Soccer' ?
                                        <FaRegFutbol  size={12} color= 'black'   /> 
                                        : item.sport == 'Basketball' ?
                                        <FaBasketballBall  size={12} color= 'black'   /> 
                                        : item.sport == 'Baseball' ?
                                        <FaBaseballBall  size={12} color= 'black'   /> 
                                        : item.sport == 'Football' ?
                                        <FaFootballBall  size={12} color= 'black'   /> 
                                        : null
                                        }               
                                    </View>
                                    <Text style={{ color:'#575757',fontSize:13,marginTop:20}}>{item.group} Conversation</Text>                          
                                    <View style={{marginTop:5, flexDirection:'row',justifyContent:'space-between', flex:1}}>
                                        <View style={{flexDirection:'row', flex:1,alignItems:'center'}}>
                                            <FaCommentAlt  size={12} color= '#6B6B6B'   /> 
                                            <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{item.commentNo}</Text>
                                        </View>
                                        <View style={{flexDirection:'row',flex:1,alignItems:'center'}}>
                                            <AiFillLike  size={16} color= '#6B6B6B'   /> 
                                            <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{item.likeNo}</Text>
                                        </View>
                                        <View style={{flexDirection:'row',flex:1,alignItems:'center'}}>
                                            <AiFillDislike  size={16} color= '#6B6B6B'   /> 
                                            <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{item.dislikeNo}</Text>
                                        </View>
                                    </View>                            
                                </View>                      
                            </View>
                        </View>
                    </Link>          
                    <View  style={{borderBottomColor: '#D6D6D6',borderBottomWidth: 1,}}/>
                </View>     
            </View>
        )
    }


export const ConvoCard = ({item, handler,updateSaveGame,data,loading,loadingIndex,profile,edit, link}) => {
    console.log(item)
    const [deleteuuid, setDeleteuuid] = useState(null)
    const [userInfoJoinGame, setuserInfoJoinGame] = useState('')
    const handleChangeMore = (e, { key, value, text }) => {
        setDeleteuuid(item.appuuid)
    }
    const trigger = (
        <BiDotsHorizontalRounded size={19} color= '#6B6B6B' style={{marginTop:10, marginLeft:5}} /> 
    )  
    const options = [
        { key: 'delete',  text: 'delete' },
    
    ]

    useEffect(() => { 
        //console.log(item.username); 
        OtherUserInfo({username:item.username},updateUserInfo) }, []);
    
    const updateUserInfo = (data) => {
        //console.log(item.username)
        //console.log(data)
        setuserInfoJoinGame(data)
    }
    const  stringToHslColor = (str, s, l) => {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var h = hash % 360;
       return 'hsl('+h+', '+s+'%, '+l+'%)';
    }
   
    const increase_brightness = function(color,percent){

        var ctx = document.createElement('canvas').getContext('2d');
    
        ctx.fillStyle = color;
        ctx.fillRect(0,0,1,1);
    
        var color = ctx.getImageData(0,0,1,1);
        var r = color.data[0] + Math.floor( percent / 100 * 255 );
        var g = color.data[1] + Math.floor( percent / 100 * 255 );
        var b = color.data[2] + Math.floor( percent / 100 * 255 );
    
        return 'rgb('+r+','+g+','+b+')';
    }

    

    return (
    
        <View>
            
        {
            
            loading ?
            <View>
                <View style={{ flex:1, justifyContent:'center', flexDirection:'row', marginTop:25, }}>
                        <Text  style={{alignSelf:'center', color:'#757575', fontWeight:500, fontSize:15.5,}}>Deleting</Text>
                        <Loader
                            style={{alignSelf:'center', marginTop:10, paddingLeft:3 }}
                            type="ThreeDots"
                            color="#DE4D4D"
                            height={25}
                            width={25}
                            // timeout={3000} //3 secs
                        />
                        
                </View>
                <View style={{ height:0.1, backgroundColor:'#D6D6D6', width:'100%', marginTop:25}} />
            </View>
            :
            deleteuuid != null ?
            <View style={{flex:1,  marginTop:25, marginBottom:0, justifyContent:'space-evenly' }}>
                <Text style={{textAlign:"center", fontSize:18, fontWeight:500, }}>Are you Sure?</Text>
                <Text style={{textAlign:"center", color:'#757575', fontWeight:500, fontSize:15.5,}}>Delete is permanent and cannot be reversed</Text>
                <View style={{flexDirection:'row-reverse', paddingRight:10}}>
                    <Text   
                    onPress={()=>{DeleteGame({appuuid: deleteuuid, username: item.username,}, updateSaveGame);
                   // LoadUserGame({ username: profile.username}, updateSaveGame) 
                    updateSaveGame(data,loadingIndex);
                    setDeleteuuid(null)}}
                            style={{textAlign:"center",  color: '#6B0606', fontWeight:500, fontSize:14, marginLeft:15}}>DELETE
                    </Text>
                    <Text   onPress={()=>{setDeleteuuid(null)}}
                             style={{textAlign:"center",  color: '#176404', fontWeight:500, fontSize:14}}>CANCEL
                    </Text>
                </View>
                <View style={{ height:0.1, backgroundColor:'#D6D6D6', width:'100%', marginTop:25}} />
            </View>
            :
        <View style = {{flexDirection:'row', flex:1,}}>
            { profile && profile.username != item.username ?

            <Link to={`${'/game/'+ item.appuuid}`}   style={{ textDecoration: 'none',flex:1, }} onClick={()=> {handler('none')}}>
                <View>
                    <View style={{flexDirection:'row', marginBottom:2}}>
                            <Text style={{fontWeight:600,  fontSize:13.5,letterSpacing:0.3, marginLeft:55, marginTop:10,color:'#575757', marginRight:2}}>Joined {userInfoJoinGame.nameofuser}</Text> 
                            <Text style={{fontWeight:600,  fontSize:13.5,letterSpacing:0.3,  marginTop:10,color:'#575757', marginRight:2}}>@{userInfoJoinGame.username} game</Text> 
                    </View> 
                    <View style={{ marginRight:10,  padding:10,flexDirection:'row'}}>           
                        <ImageBackground style={{width:35,height:35, backgroundColor:stringToHslColor ( item.username,50,55), borderRadius:10,}}
                            imageStyle={{ borderRadius: 10 }}
                            source={{uri:  'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  item.username +'/'+ userInfoJoinGame.profilepixuuid}} > 
                           {userInfoJoinGame.profilepixuuid == null ? <GoPerson  color={stringToHslColor ( item.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1}}/> : null}
                        </ImageBackground>
                        <View style={{paddingLeft:10, flex:1}}>
                        <View style={{flexDirection:'row', alignItems:'center', }}>
                            <Text style={{fontWeight:650,  fontSize:15,letterSpacing:0.3, }}>{ item.groupName}</Text>
                        </View >
                          
                            <View style={{alignItems:'center', flexDirection:'row',marginTop:2, }} >
                                <Text style={{}}>
                                <Text style={{ fontSize:15, color: '#6B0606', }} numberOfLines={2}>{item.homeTeamName}</Text>
            
                                <Text style={{ fontSize:15, color: '#6B0606', }}>{item.awayTeamName}</Text>
                                </Text>
                                <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5}}>{'\u2B24'}</Text>
                                <Text style={{ color:'#575757',fontSize:13,}}>{new Date(item.date).toLocaleDateString()}</Text>
                                <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5}}>{'\u2B24'}</Text>
                                <Text style={{ color:'#575757',fontSize:13, }}>{item.gender}</Text>
                                <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5}}>{'\u2B24'}</Text>
                                <Text style={{ color:'#575757',fontSize:13,marginRight:5, }}>{item.sport}</Text>
                                { item.sport == 'Soccer' ?
                                <FaRegFutbol  size={12} color= 'black'   /> 
                                : item.sport == 'Basketball' ?
                                <FaBasketballBall  size={12} color= 'black'   /> 
                                : item.sport == 'Baseball' ?
                                <FaBaseballBall  size={12} color= 'black'   /> 
                                : item.sport == 'Football' ?
                                <FaFootballBall  size={12} color= 'black'   /> 
                                : null
                                }               
                            </View>
                            <Text style={{ color:'#575757',fontSize:13,marginTop:20}}>{item.group} Conversation</Text>
                            
                            <View style={{marginTop:5, flexDirection:'row',justifyContent:'space-between', }}>
                                <View style={{flexDirection:'row', flex:1,alignItems:'center'}}>
                                    <FaCommentAlt  size={12} color= '#6B6B6B'   /> 
                                    <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{item.commentNo}</Text>
                                </View>
                                <View style={{flexDirection:'row',flex:1,alignItems:'center'}}>
                                    <AiFillLike  size={16} color= '#6B6B6B'   /> 
                                    <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{item.likeNo}</Text>
                                </View>
                                <View style={{flexDirection:'row',flex:1,alignItems:'center'}}>
                                    <AiFillDislike  size={16} color= '#6B6B6B'   /> 
                                    <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{item.dislikeNo}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View  style={{borderBottomColor: '#D6D6D6',borderBottomWidth: 1, marginTop:0,}}/>
                    
                </View>
            </Link>

                :
            <View style={{ flex:1}}>   
            <View style={{flexDirection:'row', flex:1}}>
            <Link to={`${'/game/'+ item.appuuid}`}   style={{ textDecoration: 'none',flex:1,}} onClick={()=> {handler('none')}}>
               
                <View>
               
                  
                    <View style={{padding:10, flexDirection:'row'}}> 
                             
                        <ImageBackground  style={{width:35,height:35, backgroundColor:stringToHslColor ( item.username,50,55),  borderRadius:10,}}
                          imageStyle={{ borderRadius: 10 }}
                          source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  item.username+'/'+ (profile.nameofuser == null ? userInfoJoinGame.profilepixuuid: profile.profilepixuuid)}} > 
                            {profile.profilepixuuid == null && profile ? <GoPerson  color={stringToHslColor ( item.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1,}}/>: null}
                        </ImageBackground >
                        
                        <View style={{paddingLeft:10,flex:1}}>

                        <View style={{flexDirection:'row', alignItems:'center', }}>
                                        <Text style={{fontWeight:650,  fontSize:15,letterSpacing:0.3, }}>{ item.groupName}</Text>
                                    </View >
                            
                            <View style={{alignItems:'center', flexDirection:'row',marginTop:2, }} >
                                <Text style={{}}>
                                <Text style={{ fontSize:15, color: '#6B0606', }} numberOfLines={2}>{item.homeTeamName}</Text>
            
                                <Text style={{ fontSize:15, color: '#6B0606', }}>{item.awayTeamName}</Text>
                                </Text>
                                <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5}}>{'\u2B24'}</Text>
                                <Text style={{ color:'#575757',fontSize:13,}}>{new Date(item.date).toLocaleDateString()}</Text>
                                <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5}}>{'\u2B24'}</Text>
                                <Text style={{ color:'#575757',fontSize:13, }}>{item.gender}</Text>
                                <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5}}>{'\u2B24'}</Text>
                                <Text style={{ color:'#575757',fontSize:13,marginRight:5, }}>{item.sport}</Text>
                                { item.sport == 'Soccer' ?
                                <FaRegFutbol  size={12} color= 'black'   /> 
                                : item.sport == 'Basketball' ?
                                <FaBasketballBall  size={12} color= 'black'   /> 
                                : item.sport == 'Baseball' ?
                                <FaBaseballBall  size={12} color= 'black'   /> 
                                : item.sport == 'Football' ?
                                <FaFootballBall  size={12} color= 'black'   /> 
                                : null
                                }               
                            </View>
                            <Text style={{ color:'#575757',fontSize:13,marginTop:20}}>{item.group} Conversation</Text>
                          
                            <View style={{marginTop:5, flexDirection:'row',justifyContent:'space-between', flex:1}}>
                                <View style={{flexDirection:'row', flex:1,alignItems:'center'}}>
                                    <FaCommentAlt  size={12} color= '#6B6B6B'   /> 
                                    <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{item.commentNo}</Text>
                                </View>
                                <View style={{flexDirection:'row',flex:1,alignItems:'center'}}>
                                    <AiFillLike  size={16} color= '#6B6B6B'   /> 
                                    <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{item.likeNo}</Text>
                                </View>
                                <View style={{flexDirection:'row',flex:1,alignItems:'center'}}>
                                    <AiFillDislike  size={16} color= '#6B6B6B'   /> 
                                    <Text style={{ color:'#575757',fontSize:13,marginLeft:5}}>{item.dislikeNo}</Text>
                                </View>
                            </View>
                            
                        </View>
                      
                    </View>
               </View>
            </Link> 
            { profile.username == item.username & edit ?
                <Dropdown 
                onChange={handleChangeMore} 
                upward 
                trigger={trigger} 
                options={options} 
                icon=''
                value=''/> :
                null
            }
             </View>
             <View  style={{borderBottomColor: '#D6D6D6',borderBottomWidth: 1,}}/>
            </View>               
            }
            
        </View>
        }
        </View>
    )
}


export const Profile = ({item, check, note, appuuid,membersupdate}) => {
    const  stringToHslColor = (str, s, l) => {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var h = hash % 360;
       return 'hsl('+h+', '+s+'%, '+l+'%)';
    }
    //console.log(item)
    return (
    
        <View style={{flexDirection:'row', marginTop:5,marginBottom:5}}>
            <View style={{flex:1,flexDirection:'row',}}>
                <ImageBackground  style={{width:35,height:35, backgroundColor:stringToHslColor ( item.username,50,55), borderRadius:10,}}
                    imageStyle={{ borderRadius: 10 }}
                    source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  item.username +'/'+ item.profilepixuuid}} > 
                    {item.profilepixuuid == null ? <GoPerson  color={stringToHslColor ( item.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1,}}/>: null}
                </ImageBackground>
                <View style={{paddingLeft:10,width:'100%', }}>
                 
                        <Text style={{flex:1,  fontSize:15,letterSpacing:0.3, }}>{item.nameofuser}</Text>
                        <Text style={{flex:1,  fontSize:15,letterSpacing:0.3, }}>{item.bio}</Text>
                    
                </View>
            </View>
            { check && note=='Confirm' ?
            <TouchableOpacity onPress = {() => AddMember(appuuid, item.username, membersupdate ) }style={{backgroundColor:'green', borderRadius:5,padding:5,justifyContent:'center' }}>
                <Text style={{color:'white', }}>Confirm</Text>
            </TouchableOpacity>
            :
            check && note=='Remove' ?
            <TouchableOpacity onPress = {() => RemoveMember(appuuid, item.username, membersupdate) }  style={{backgroundColor:'#8A0E0E', borderRadius:5,padding:5,justifyContent:'center' }}>
                <Text style={{color:'white', }}>Remove</Text>
            </TouchableOpacity>
            :
            null
            }

        </View>
    )

}

export const ProfileCard = ({item, link,}) => {
    const  stringToHslColor = (str, s, l) => {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var h = hash % 360;
       return 'hsl('+h+', '+s+'%, '+l+'%)';
    }
    return (
        <Link to={`${link}`} style={{ textDecoration: 'none', backgroundColor:'white', }}> 
            <TouchableOpacity style={{flexDirection:'row'}}>                
                <ImageBackground
                    style={{ alignSelf:'center', marginBottom:20,width:35, height:35,borderRadius:35,backgroundColor:stringToHslColor ( item.username,50,55), marginLeft:30, justifyContent:'center', borderRadius:10}}   
                    imageStyle={{ borderRadius: 10 }}                                  
                    source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  item.username +'/'+ item.profilepixuuid}} >  
                    {item.profilepixuuid == null ? <GoPerson  color={stringToHslColor ( item.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1,}}/>: null}                                       
                </ImageBackground>             
                <View style={{padding:5, marginTop:7, marginBottom:15,height: '100%', width:'60%', paddingLeft:25 }}>
                    <Text numberOfLines={1} style = {{ fontfamily: 'Helvetica' , color: 'black',fontSize: 16,fontWeight:500, flex:4}}>{item.nameofuser}</Text>
                    <View style={{flexDirection:'row', alignItems:'center',}}>
                        <Text style = {{ color: 'black',fontSize: 14,fontWeight:500, marginBottom:5}}>{item.username}</Text>  
                        <Text style={{ color:'#575757',fontSize:2,marginRight:5, marginLeft:5, marginBottom:4}}>{'\u2B24'}</Text>    
                        <Text style = {{ color: 'black',fontSize: 14,fontWeight:500, marginBottom:5}}>{item.bio}</Text>     
                    </View>              
                </View>
            </TouchableOpacity>
       </Link> 
    )
}








