
import * as React from 'react';

import { Platform, TextInput, Text, View, Image, FlatList, ScrollView, BackHandler, TouchableOpacity, ImageBackground, Linking, YellowBox, Dimensions} from 'react-native';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import EditProfile from '../EditProfile';
import { MdLooks5,MdEdit,MdDelete, MdKeyboardArrowRight, MdKeyboardArrowDown, MdArrowDropUp, MdArrowDropDown} from 'react-icons/md';
import {AmplifyAuthenticator, AmplifySignOut, AmplifySignUp} from "@aws-amplify/ui-react";
import {UserInfo, OtherUserInfo} from '../AjaxCall/UserInfo';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import {ConvoCard, HomePageConvoCard} from '../Components/CustomButton'
import {LoadUserGame} from '../AjaxCall/LoadUserGame';
import {GoComment, GoPerson} from 'react-icons/go';
//import {DeleteGame} from '../AjaxCall/DeleteGame';
const UserProfilePage  = (props,) => {
  
    console.log(props)
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState('');
    const [game, setGame] = React.useState([]);
    const [loadingIndex, setLoadingIndex] = React.useState(false);

    const updateSaveGame = (data,loadingIndex, ) => {
        if (data==='error') {}
        else {console.log('succeffuly load game');
        setGame(data); setLoadingIndex(loadingIndex); }
    }

    const updateUserInfo = (data,loadingIndex, ) => {
        if (data==='error') {}
        else {console.log('succeffuly load game pppppp'); setUser(data);console.log(data) }
    }


   

    
    React.useEffect(() => {props.handler('none')}, [game]);
    React.useEffect(() => {LoadUserGame({ username: window.location.href.split('/').pop(-1)}, updateSaveGame) }  , []);
    React.useEffect(() => {OtherUserInfo({ username: window.location.href.split('/').pop(-1)}, updateUserInfo) }  , []);
       
        
    const  stringToHslColor = (str, s, l) => {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var h = hash % 360;
       return 'hsl('+h+', '+s+'%, '+l+'%)';
    }

   
    const reportGame = () => { 
                  
        console.log(game)
        return (
           
                            <View style={{backgroundColor:'white',borderRadius:10,}}>
                                <View style={{marginTop:10,}}></View>
                                { user != '' ?
                                <View style={{flexDirection:'row',paddingBottom:10, }}>
                          
                                    <ImageBackground 
                                        style={{ alignSelf:'center', marginBottom:20,width:70, height:70,borderRadius:35,backgroundColor:stringToHslColor ( user.username,50,55), marginLeft:30, justifyContent:'center'}}
                                        imageStyle={{ borderRadius: 35 }}
                                        source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  user.username + '/'+ user.profilepixuuid}} >     
                                        {user.profilepixuuid == null ? <GoPerson  color={stringToHslColor ( user.username,50,93)} size={35} style={{justifyContent:'center', alignSelf:'center',flex:1,}}/>: null}                                   
                                    </ImageBackground >  
                                    
                                                                   
                                    <View style ={{marginLeft:10, flex:1,}}>
                                        
                                        <View style={{flexDirection:'row'}}>   
                                            <Text style={{marginTop:10,  fontWeight:500, fontSize:17,flex:1 }}>{user.nameofuser===''? 'Name' : user.nameofuser}</Text>
                                        </View>
                                        <Text style={{marginTop:10,  fontWeight:500, fontSize:14, marginBottom:0}}>
                                        {user.bio===''? 'Bio': user.bio}
                                        </Text>
                                    </View>
                                   
                              
                                </View>
                                 :
                                 null
                                  }
                                <View style={{borderBottomColor: '#DADADA', borderBottomWidth: 1,}}/>
                                
                                {game.map((item, index)=>  
                                item==null?
                                null:
                                <ConvoCard
                                item = {item} handler = {props.handler}
                                updateSaveGame = {updateSaveGame} 
                                loading={loadingIndex>=0 && loadingIndex===index ?true:false}
                                data={game}
                                loadingIndex= {index}
                                profile={user}
                                edit={false}
                                />)}
                            </View> 
                   
                  
                   
               
         
        )
    
}


    
        return  (
            <View style={{backgroundColor:'white', }}> 
                
                <Route  path={`${props.match.path}/EditProfile`}
            
                    render={(props) => <EditProfile routeProps={props} />}
                    /> 
                    {/*
                    <Route  path={`game/:id`}
                    render={(props) => <GamePreview routeProps={props} handler={this.props.handler}/>}
                    />  
                */ }
                <Route
                    exact
                    path={props.match.path}
                    render={() => <View >
                    {reportGame() }
                    </View>}
                /> 
                    
            </View> 
               
         
        )
    
}



export default (UserProfilePage)

