import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';


export const Like = ({gameID, username, UserlikeData, index, userLike}) => {
    console.log(gameID + ' ' + username )
    const Like = `
    query LikeInfo ($gameID:String!, $username:String!) {
        LikeInfo (gameID: $gameID, username:$username) {
          nextToken
          items {
            username
            unlike
            like
            gameID
          }
        }
      }`
      API.graphql(graphqlOperation(Like,  {gameID, username })).then(response => { 
        console.log(response)
        const items = response.data.LikeInfo.items[0];
        if (items) {
          //const newUserLike = [...userLike]
          //newUserLike[index] = response.data.LikeInfo.items[0]
            //console.log(response.data.LikeInfo.items[0])
           // console.log(newUserLike)
            UserlikeData(response.data.LikeInfo.items[0]);
          };
                            
    }).catch(e => {console.log(e);UserlikeData('error')});
}

export const LikeReply = ({gameID, username, UserlikeData, index, userLike, newMessage, MessageData}) => {
  console.log(gameID + ' ' + username )
  const Like = `
  query LikeInfo ($gameID:String!, $username:String!) {
      LikeInfo (gameID: $gameID, username:$username) {
        nextToken
        items {
          username
          unlike
          like
          gameID
        }
      }
    }`
    API.graphql(graphqlOperation(Like,  {gameID, username })).then(response => { 
      console.log(response)
      const items = response.data.LikeInfo.items[0];
     
        const newUserLike = [...userLike]
        newUserLike= response.data.LikeInfo.items[0]
          //console.log(response.data.LikeInfo.items[0])
          UserlikeData(newUserLike);
          MessageData(newMessage);
       
                          
  }).catch(e => {console.log(e);UserlikeData('error')});
}