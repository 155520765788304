//import React from 'react';
//import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppRegistry, Text } from 'react-native';
import Amplify from 'aws-amplify';
import ReduxSetup from './ReduxSetup';
import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';




Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-1:5296551d-784c-4c10-9226-efba52dcf08b',
        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        userPoolId: 'us-east-1_Dh16e9Boj',
        //userPoolId: 'us-east-1_2HNa3ZrIE',
        
        // identityPoolId: 'us-east-1:kv1348da-7474-4a01-029d-0b50475284895',
        // OPTIONAL - Amazon Cognito Web Client ID
                //userPoolWebClientId: '3l7rl5n7v45kd6q698sutqicpu',
        userPoolWebClientId: '6ssa8etusq2e8q3pt17ves123q',
        /*
        oauth: {
    domain: 'background.auth.us-east-1.amazoncognito.com',
    clientID:  '6ssa8etusq2e8q3pt17ves123q',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'http://localhost:3000/profile',
    redirectSignOut: 'http://localhost:3000/profile',
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
   }
   */     
   // userPoolWebClientId: 'starter_userpoolapp_MOBILEHUB_844666056 web'
    },
    //API: {
        'aws_appsync_graphqlEndpoint': 'https://4qdbm67dbjadtl6vmy3xmd7jhu.appsync-api.us-east-1.amazonaws.com/graphql',
        'aws_appsync_region': 'us-east-1',
        'aws_appsync_authenticationType': 'AWS_IAM',
    //},
    Storage: {
        bucket: 'certificate-for-fanzquiz', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-1', //OPTIONAL -  Amazon service region
    },
    Analytics: {
        // OPTIONAL - disable Analytics if true
        disabled: false,
        // OPTIONAL - Allow recording session events. Default is true.
        autoSessionRecord: true,

        AWSPinpoint: {
            // OPTIONAL -  Amazon Pinpoint App Client ID
            appId: '54d068e4245a4329b7346a32b1db1840',
            // OPTIONAL -  Amazon service region
            region: 'us-east-1',
        }
    }
});

const startpoint = () => {
    return (        
        <ReduxSetup/>
                 
    )
}

// register the app
AppRegistry.registerComponent('App', () => startpoint);

AppRegistry.runApplication('App', {
  initialProps: {},
  rootTag: document.getElementById('root')
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
