import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './config-store';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { BrowserRouter } from 'react-router-dom'



export default class ReduxSetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storeCreated: false,
            store: null,
        };
    }
    componentDidMount() {
        configureStore()
            .then(({ persistor, store }) =>
                this.setState({ persistor, store, storeCreated: true }));
    }
    render() {
        if (!this.state.storeCreated) return null;
        return (
            <Provider store={this.state.store}>
                <PersistGate persistor={this.state.persistor}>
                    <BrowserRouter>
                        <App/> 
                    </BrowserRouter>           
                </PersistGate>
                
            </Provider>
        );
    }
}