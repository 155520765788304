export default (state = [], action) => {
   
    switch (action.type) {
        
        case 'gameRosters':  
            console.log('rosterData')
            console.log(action.payload)
            console.log('rosterData')
           return action.payload;
        default:
            return state;
    }
};