import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';

export const SaveUserFeedBack = ({email, msg},updateUserMsgFeedback) => {
    const loadUserFeedBack  = `
    mutation loadUserFeedBack (
        $email:String! 
        $msg:String!
       
        )  {
            loadUserFeedBack (
                msg:$msg             
                email:$email                
            )
        {email}
    }`; 
    
    
    API.graphql(graphqlOperation(loadUserFeedBack, {email,msg}
         )).then(response => {  
            // console.log(response.data.loadUserFeedBack)
           // updateUserMsgFeedback(response.data.loadUserFeedBack .items[0].emial)
           updateUserMsgFeedback('success')
    }).catch(e => {console.log(e); });
   
}