//import * as React from 'react';
import { Alert, StyleSheet, Text, FlatList, TextInput, View, Dimensions, Button, TouchableOpacity, PanResponder, ImageBackground, ScrollView,  TouchableWithoutFeedback, TouchableHighlight, Pressable, Modal  } from 'react-native';
import { connect } from 'react-redux';
import * as actions from './actions';
import React, { useEffect, useState } from 'react';
import {LoadMessages,LoadReplyMessages} from './AjaxCall/Message/LoadMessage';
import {UpdateLike} from './AjaxCall/UserLikeData/UpdateLike';
import {SendMessage} from './AjaxCall/Message/SendMessage';
import {DeleteMessage, } from './AjaxCall/Message/DeleteMessage';
import {SubMessages, SubAddLike, SubAddUnlike, SubMinusLike, SubMinusUnlike, SubAddLikeMinusUnlike, SubAddUnlikeMinusLike, SubDeleteMessage, SubMessagesReply, SubDeleteReplyMessage, 
        SubReplyAddLike, SubReplyAddUnlike, SubReplyMinusLike, SubReplyMinusUnlike, SubReplyAddLikeMinusUnlike, SubReplyAddUnlikeMinusLike, SubRepliesNo, SubLikeTable,SubDecreaseRepliesNo} from './AjaxCall/Message/SubscripMessage';    
import {FaDotCircle, FaFlag, FaUserSlash} from 'react-icons/fa';
import {AiFillLike, AiFillDislike,} from 'react-icons/ai';
import {BsInfo, BsThreeDotsVertical,} from 'react-icons/bs';
import {MdSend} from 'react-icons/md';
import { Dropdown, Input, Icon, Grid,  } from 'semantic-ui-react';
import {Profile} from './Components/CustomButton';
import soundfile from './message-pop-sound.wav';
import TextInputPage from './TextInputPage';
import {GoComment, GoPerson} from 'react-icons/go';

//import Dropdown from 'react-dropdown';
//import 'react-dropdown/style.css';
//import 'rc-dropdown/assets/index.css';


const moment = require('moment-timezone');
//var Dropdown = require('rc-dropdown');

const listRef = React.createRef(); 



function CommentsPage(props) {

    const [modalVisible, setModalVisible] = useState(false);

    console.log(props)
    props.props.handler('none')
    const {userInfo} = props.props
    const onFocus = () => {
        LoadMessages({input:props.props.match.params.id, MessageData, UserlikeData,userLike, username: props.props.userInfo.username,loadMessage:true})
      };
      
      // User has switched away from the tab (AKA tab is hidden)
      const onBlur = () => {
      };

      useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        // Specify how to clean up after this effect:
        return () => {
          window.removeEventListener('focus', onFocus);
          window.removeEventListener('blur', onBlur);
        };
      });

      
    const [pagegameID, setpagegameID] = useState(props.props.match.params.id)
    const [messages, setMessages] = useState([]);  
    const [userLike, setuserLike]  = useState();
    const [textInput, setTextInput] = useState([]);
    const [subscription, setSubscription] = useState();
    const [subLike, setSubLike] = useState();
    const [button, setButton] = useState(false)
    const [ReplyMessage, setReplyMessage] = useState([])
    const [textGameID, setTextGameID] = useState()
    const [textBorderColor, settextBorderColor] = useState('white')

    const [subEvent, setReplySub] = useState([])
    const [subDeleteEvent, setReplyDeleteSub] = useState([])

    const [subReplyAddLikeEvent, setReplyAddLike] = useState([])
    const [subReplyAddUnlikeEvent, setReplyAddUnlike] = useState([])
    const [subReplyMinusLikeEvent, setReplyMinusLike] = useState([])
    const [subReplyMinusUnlikeEvent, setReplyMinusUnlike] = useState([])
    const [subReplyAddLikeMinusUnlikeEvent, setReplyAddLikeMinusUnlike] = useState([])
    const [subReplyAddUnlikeMinusLikeEvent, setReplyAddUnlikeMinusLike] = useState([])

    const [pageView, setpageView] = useState('comments')
    const [members, setmembers] = useState([])
    const [requesters, setrequesters] = useState([])
    const [owner, setowner] = useState([])

    const MessageDataReveerse = (data, loadMessage) => {
        if (data=='error'){ }
        else {
            
            //setMessages(data); setButton(false);
            if (!loadMessage) {
                toggle();
            }
           
            console.log(data[0])
            
        }
    }
    
    const MessageData = (data, loadMessage) => {
        if (data=='error'){ }
        else {
            //const newMessage = [...]
            setMessages(data); setButton(false);
            if (!loadMessage) {
                toggle();
            }
           
            console.log(data)
            
        }
    }

    const UserlikeData = (data) => {
        if (data=='error'){ }
        else {
            setuserLike(data); 
        }
    }   
    const SubMessageData = (sub) => {setSubscription(sub)}
    const SubLikeData = (sub) => {setSubLike(sub)}
    const SetButton  = (data) => {setButton(data)}

    useEffect(() => { LoadMessages({input:props.props.match.params.id, MessageData, UserlikeData,userLike, username: props.props.userInfo.username,loadMessage:true}) }, []);
    //useEffect(() => { Like({gameID:props.props.match.params.id, username: "User1007", UserlikeData,})}, []);
    useEffect(() => {
        const subscription = SubLikeTable({username:userInfo.username, UserlikeData,});
        return subscription
     }, [userLike, ]);

    useEffect(() => {
       const subscription = SubMessages({gameID:props.props.match.params.id, MessageData, SubMessageData, messages, username: props.props.userInfo.username});
       //console.log('SubMessages')
       //console.log(subscription)
       //subEvent.unshift(undefined)
       
       return subscription
    }, [messages]);
    useEffect(() => {
        const subscription = SubDeleteMessage({gameID:props.props.match.params.id, MessageData, SubMessageData, messages,username: props.props.userInfo.username, loadMessage:true});
        return subscription
     }, [messages]);

    useEffect(() => {
        const subscription = SubAddLike({gameID:props.props.match.params.id, MessageData, SubLikeData, messages,username: props.props.userInfo.username });
        console.log('SubAddLike')
        return subscription
     }, [messages]);

     useEffect(() => {
        const subscription = SubAddUnlike({gameID:props.props.match.params.id, MessageData, SubLikeData, messages,username: props.props.userInfo.username });
        return subscription
     }, [messages]);

     useEffect(() => {
        const subscription = SubMinusLike({gameID:props.props.match.params.id, MessageData, SubLikeData, messages,username: props.props.userInfo.username });
        return subscription
     }, [messages]);

     useEffect(() => {
        const subscription = SubMinusUnlike({gameID:props.props.match.params.id, MessageData, SubLikeData, messages,username: props.props.userInfo.username });
        return subscription
     }, [messages]);

     useEffect(() => {
        const subscription = SubAddLikeMinusUnlike({gameID:props.props.match.params.id, MessageData, SubLikeData, messages,username: props.props.userInfo.username });
        return subscription
     }, [messages]);

     useEffect(() => {
        const subscription = SubAddUnlikeMinusLike({gameID:props.props.match.params.id, MessageData, SubLikeData, messages, username: props.props.userInfo.username });
        return subscription
     }, [messages]);
     
     useEffect(() => {
        const subscription = SubRepliesNo({gameID:props.props.match.params.id, MessageData, SubLikeData, messages,username: props.props.userInfo.username });
        return subscription
     }, [messages]);

     useEffect(() => {
        const subscription = SubDecreaseRepliesNo({gameID:props.props.match.params.id, MessageData, SubLikeData, messages,username: props.props.userInfo.username });
        return subscription
     }, [messages]);

     /*
     const subFun = (index, textID, gameID) => {
        let subscription = SubMessagesReply({gameID:textID, MessageData, SubMessageData, messages, updateTextInput});
        let newsubEvent = [...subEvent]
        newsubEvent[index] = subscription 
        setReplySub (newsubEvent)

        subscription = SubDeleteReplyMessage({gameID:gameID, MessageData, SubMessageData, messages,});
        newsubEvent = [...subDeleteEvent]
        newsubEvent[index] = subscription 
        setReplyDeleteSub(newsubEvent)

        subscription = SubReplyAddLike({gameID:gameID, MessageData, SubMessageData, messages,});
        newsubEvent = [...subReplyAddLikeEvent]
        newsubEvent[index] = subscription 
        setReplyAddLike(newsubEvent)
       

        subscription = SubReplyAddUnlike({gameID:gameID, MessageData, SubMessageData, messages,});
        newsubEvent = [...subReplyAddUnlikeEvent]
        newsubEvent[index] = subscription 
        setReplyAddUnlike(newsubEvent)
        
        subscription = SubReplyMinusLike({gameID:gameID, MessageData, SubMessageData, messages,});
        newsubEvent = [...subReplyMinusLikeEvent]
        newsubEvent[index] = subscription 
        setReplyMinusLike(newsubEvent)

        subscription = SubReplyMinusUnlike({gameID:gameID, MessageData, SubMessageData, messages,});
        newsubEvent = [...subReplyMinusUnlikeEvent]
        newsubEvent[index] = subscription 
        setReplyMinusUnlike(newsubEvent)

        subscription = SubReplyAddLikeMinusUnlike({gameID:gameID, MessageData, SubMessageData, messages,});
        newsubEvent = [...subReplyAddLikeMinusUnlikeEvent]
        newsubEvent[index] = subscription 
        setReplyAddLikeMinusUnlike(newsubEvent)
        
        subscription = SubReplyAddUnlikeMinusLike ({gameID:gameID, MessageData, SubMessageData, messages,});
        newsubEvent = [...subReplyAddUnlikeMinusLikeEvent]
        newsubEvent[index] = subscription 
        setReplyAddUnlikeMinusLike(newsubEvent)

     }
     */

    const subFun = (index, textID, gameID) => {
        let subscription = SubMessagesReply({gameID:textID, MessageData, SubMessageData, messages,username: props.props.userInfo.username});
        let newsubEvent = [...subEvent]
         newsubEvent.unshift({id:textID,subscription})
        setReplySub (newsubEvent)

        subscription = SubDeleteReplyMessage({gameID:gameID, MessageData, SubMessageData, messages,username: props.props.userInfo.username,loadMessage:true});
        newsubEvent = [...subDeleteEvent]
        newsubEvent.unshift({id:textID,subscription})
        setReplyDeleteSub(newsubEvent)

        subscription = SubReplyAddLike({gameID:gameID, MessageData, SubMessageData, messages,username: props.props.userInfo.username});
        newsubEvent = [...subReplyAddLikeEvent]
        newsubEvent.unshift({id:textID,subscription})
        setReplyAddLike(newsubEvent)
   
        subscription = SubReplyAddUnlike({gameID:gameID, MessageData, SubMessageData, messages,username: props.props.userInfo.username});
        newsubEvent = [...subReplyAddUnlikeEvent]
        newsubEvent.unshift({id:textID,subscription}) 
        setReplyAddUnlike(newsubEvent)
        
        subscription = SubReplyMinusLike({gameID:gameID, MessageData, SubMessageData, messages,username: props.props.userInfo.username});
        newsubEvent = [...subReplyMinusLikeEvent]
        newsubEvent.unshift({id:textID,subscription})
        setReplyMinusLike(newsubEvent)

        subscription = SubReplyMinusUnlike({gameID:gameID, MessageData, SubMessageData, messages,username: props.props.userInfo.username});
        newsubEvent = [...subReplyMinusUnlikeEvent]
        newsubEvent.unshift({id:textID,subscription})
        setReplyMinusUnlike(newsubEvent)

        subscription = SubReplyAddLikeMinusUnlike({gameID:gameID, MessageData, SubMessageData, messages,username: props.props.userInfo.username});
        newsubEvent = [...subReplyAddLikeMinusUnlikeEvent]
        newsubEvent.unshift({id:textID,subscription})
        setReplyAddLikeMinusUnlike(newsubEvent)
        
        subscription = SubReplyAddUnlikeMinusLike ({gameID:gameID, MessageData, SubMessageData, messages,username: props.props.userInfo.username});
        newsubEvent = [...subReplyAddUnlikeMinusLikeEvent]
        newsubEvent.unshift({id:textID,subscription})
        setReplyAddUnlikeMinusLike(newsubEvent)

     }
     /*
     const removeSub = (index) => {
         console.log(index)
         console.log(subEvent)
        subEvent[index].unsubscribe()
        subDeleteEvent[index].unsubscribe()
        subReplyAddLikeEvent[index].unsubscribe()
        subReplyAddUnlikeEvent[index].unsubscribe()
        subReplyMinusLikeEvent[index].unsubscribe()
        subReplyMinusUnlikeEvent[index].unsubscribe()
        subReplyAddLikeMinusUnlikeEvent[index].unsubscribe()
        subReplyAddUnlikeMinusLikeEvent[index].unsubscribe()

     }
     */
     const removeSub = (id) => {
         console.log(subEvent)
        if (subEvent.findIndex(e => e.id == id ) != -1){
            const index = subEvent.findIndex(e => e.id == id )
            subEvent[index].subscription.unsubscribe()
            let newsubEvent = [...subEvent]
            newsubEvent.splice(index, 1);
            setReplySub (newsubEvent)

            subDeleteEvent[index].subscription.unsubscribe()
            newsubEvent = [...subDeleteEvent]
            setReplyDeleteSub(newsubEvent)

            subReplyAddLikeEvent[index].subscription.unsubscribe()
            newsubEvent = [...subReplyAddLikeEvent]
            setReplyAddLike(newsubEvent)

            subReplyAddUnlikeEvent[index].subscription.unsubscribe()
            newsubEvent = [...subReplyAddUnlikeEvent]
            setReplyAddUnlike(newsubEvent)

            subReplyMinusLikeEvent[index].subscription.unsubscribe()
            newsubEvent = [...subReplyMinusLikeEvent]
            setReplyMinusLike(newsubEvent)


            subReplyMinusUnlikeEvent[index].subscription.unsubscribe()
            newsubEvent = [...subReplyMinusUnlikeEvent]
            setReplyMinusUnlike(newsubEvent)

            subReplyAddLikeMinusUnlikeEvent[index].subscription.unsubscribe()
            newsubEvent = [...subReplyAddLikeMinusUnlikeEvent]
            setReplyAddLikeMinusUnlike(newsubEvent)

            subReplyAddUnlikeMinusLikeEvent[index].subscription.unsubscribe()
            newsubEvent = [...subReplyAddUnlikeMinusLikeEvent]
            setReplyAddUnlikeMinusLike(newsubEvent)
        }

    }

    


   const handleChange = () => {};
                
    // Placeholder function for handling the form submission
    const handleSubmit = () => {};
    
    const updateLikeUnlike = (userLike, button, message, index, reply, ) => {
        if (userInfo.username == '') {
            setModalVisible(true)
        }
        else {
        
            if (button == 'like') {
                if (userLike == undefined) {
                    userLike = {gameID:pagegameID, username:userInfo.username , like:[message.id], unlike:[] }
                    const dir = 'AddLike'
                    UpdateLike({userLike, UserlikeData, SetButton,dir, message, index, pagegameID})
                    
                }
                else {
                    if (userLike.unlike != null) {
                        if (userLike.unlike.includes(message.id)) {
                        
                            userLike.unlike.splice(userLike.unlike.indexOf(message.id),1)
                            userLike.like.push(message.id)
                        
                            const dir = 'RemoveUnlikeAddLike'
                            UpdateLike({userLike, UserlikeData, SetButton,dir, message, index, pagegameID})

                        // RemoveUnlikeAddLike({gameID:message.gameID, id:message.id, likeUsername : message.likeUsername, unlikeUsername : message.unlikeUsername, MessageData, index, messages, SetButton, reply}) 
                        }   
                        else {
                            if (userLike.like != null) {
                                if (userLike.like.includes(message.id)) {
                                    
                                    userLike.like.splice(userLike.like.indexOf(message.id),1)                              
                                    const dir = 'RemoveLike'
                                    UpdateLike({userLike, UserlikeData, SetButton,dir ,message, index, pagegameID})
                                    
                                    //RemoveLikeMessage({gameID:message.gameID, id:message.id, likeUsername : message.likeUsername, MessageData, index, messages, SetButton, reply }) 
                                }
                                else {
                                    userLike.like.push(message.id)
                                    const dir = 'AddLike'
                                    UpdateLike({userLike, UserlikeData, SetButton,dir, message, index, pagegameID})
                                    //message.likeButton = parseInt(message.likeButton) + 1
                                    
                                    //UpdateLikeMessage({gameID:message.gameID, id:message.id, likeUsername : message.likeUsername, MessageData, index, messages, SetButton, reply })  
                                }
                            } 
                            else {
                                    userLike.like = [message.id]
                                    const dir = 'AddLike'
                                    UpdateLike({userLike, UserlikeData, SetButton,dir, message, index, pagegameID})
                                    //UpdateLikeMessage({gameID:message.gameID, id:message.id, likeUsername :[userInfo.username], MessageData, index, messages, SetButton, reply })   
                            }
                        }
                    }


                    else {
                        if (userLike.like != null) {
                            if (userLike.like.includes(message.id)) {
                                userLike.like.splice(userLike.like.indexOf(message.id),1)
                                const dir = 'RemoveLike'
                                UpdateLike({userLike, UserlikeData, SetButton,dir ,message, index,pagegameID})
                                //RemoveLikeMessage({gameID:message.gameID, id:message.id, likeUsername : message.likeUsername, MessageData, index, messages, SetButton, reply }) 
                            }
                            else {
                                userLike.like.push(message.id)
                                const dir = 'AddLike'
                                UpdateLike({userLike, UserlikeData, SetButton,dir, message, index,pagegameID})
                                //UpdateLikeMessage({gameID:message.gameID, id:message.id, likeUsername : message.likeUsername, MessageData, index, messages, SetButton, reply })  
                            }
                        } 
                        else {
                                userLike.like = [message.id]
                                const dir = 'AddLike'
                                UpdateLike({userLike, UserlikeData, SetButton,dir, message, index, pagegameID})
                                //UpdateLikeMessage({gameID:message.gameID, id:message.id, likeUsername :userInfo.username, MessageData, index, messages, SetButton, reply })   
                        }
                    }
                } 
            
            }
            else {
                if (userLike == undefined) {
                    userLike = {gameID:pagegameID, username:userInfo.username , unlike:[message.id], like:[] }
                    const dir = 'AddUnlike'
                    UpdateLike({userLike, UserlikeData, SetButton,dir, message, index, pagegameID})
                }
                else {
                    if (userLike.like != null) {
                    
                        if (userLike.like.includes(message.id)) {
                            
                            userLike.like.splice(userLike.like.indexOf(message.id),1)
                            userLike.unlike.push(message.id)
                            const dir = 'RemoveLikeAddUnlike'
                        
                            UpdateLike({userLike, UserlikeData, SetButton,dir, message, index, pagegameID})
                            //RemoveLikeAddUnlike({gameID:message.gameID, id:message.id, MessageData, index, messages,likeUsername : message.likeUsername, unlikeUsername : message.unlikeUsername, SetButton, reply }) 
                        }   
                        else {
                            if (userLike.unlike != null) {
                            
                                if (userLike.unlike.includes(message.id)) {
                                    userLike.unlike.splice(userLike.unlike.indexOf(message.id),1)
                                    const dir = 'RemoveUnlike'
                                    UpdateLike({userLike, UserlikeData, SetButton,dir, message, index,pagegameID})
                                    //RemoveUnlikeMessage({gameID:message.gameID, id:message.id, unlikeUsername : message.unlikeUsername, MessageData, index, messages, SetButton, reply }) 
                                }
                                else {
                                    userLike.unlike.push(message.id)
                                    const dir = 'AddUnlike'
                                    UpdateLike({userLike, UserlikeData, SetButton,dir, message, index,pagegameID})
                                    //UpdateDislikeMessage({gameID:message.gameID, id:message.id, unlikeUsername : message.unlikeUsername, MessageData, index, messages, SetButton, reply })   
                                }
                            }
                            else {  
                                userLike.unlike = [message.id]   
                                const dir = 'AddUnlike'
                                UpdateLike({userLike, UserlikeData, SetButton,dir, message, index,pagegameID})                        
                            // UpdateDislikeMessage({gameID:message.gameID, id:message.id, unlikeUsername :userInfo.username, MessageData, index, messages, SetButton, reply }) 
                            }
                        }
                    }
                    else {
                    
                        if (userLike.unlike != undefined) {
                                if (userLike.unlike.includes(message.id)) {
                                    userLike.unlike.splice(userLike.unlike.indexOf(message.id),1)
                                    const dir = 'RemoveUnlike'
                                    UpdateLike({userLike, UserlikeData, SetButton,dir, message, index,pagegameID})
                                    //RemoveUnlikeMessage({gameID:message.gameID, id:message.id, unlikeUsername : message.unlikeUsername, MessageData, index, messages, SetButton, reply }) 
                                }
                                else {
                                    userLike.unlike.push(message.id)
                                    const dir = 'AddUnlike'
                                    UpdateLike({userLike, UserlikeData, SetButton,dir, message, index,pagegameID})
                                    //UpdateDislikeMessage({gameID:message.gameID, id:message.id, unlikeUsername : message.unlikeUsername, MessageData, index, messages, SetButton, reply })   
                                }
                        }
                        else {  
                            userLike.unlike = [message.id]  
                            const dir = 'AddUnlike'
                            UpdateLike({userLike, UserlikeData, SetButton,dir, message, index,pagegameID})                            
                        // UpdateDislikeMessage({gameID:message.gameID, id:message.id, unlikeUsername :[userInfo.username], MessageData, index, messages, SetButton, reply }) 
                        }
                    
                    }
                }

            }
            setuserLike(userLike)
        }
        
        //setButton(false)
    }
    /*
    const menu = (message, index, reply) => (           
        <TouchableOpacity style={{ backgroundColor:'#DFDEDE', padding:10, }} onPress={() => {DeleteMessage({gameID:message.gameID, id:message.id,MessageData, index, messages, reply})}}>
           <Text style={{fontSize:16, }}>Delete</Text>             
        </TouchableOpacity>
    )
    */

 
const option =  [
    { key: 'delete',  text: 'Delete', value:56},

  ]

    const trigger =  (
        
              <BsThreeDotsVertical style={{ marginRight:-5}} size={15} color='#6B6B6B'/> 
            
    )

    const handleChangeMore = (e,{message, index, value,prevgameID}) => {
        if (e.type == 'click' && e.target.innerText== 'Delete') {
            DeleteMessage({gameID:value.message.gameID, id:value.message.id,MessageData, index:value.index, messages, reply:value.reply,pregameID:index==-1 ? '' : value.prevgameID,loadMessage:true})
        }
       
        
        //console.log(e)
       // console.log(e.target.innerText)
       // if (e.target.innerText==='Sign Out') {
          // Auth.signOut();
           // this.props.props.updateUserEmail("");
            //this.props.props.resetUserInfo(); 
           // this.props.props.updateUserLogin(false);
           // console.log(this.props)
           // this.props.props.updateSaveTeamInfo([])
      //  }
        //console.log(this.props)
        
        
    }

    ///onPress={() => {DeleteMessage({gameID:message.gameID, id:message.id,MessageData, index, messages, reply})}}

const replyMessageView = (message, index, textindex, oldMessageID) => {
    console.log(oldMessageID)
    const option =  [
        { key: 'delete',  text: 'Delete', value:{message,index,reply:true, prevgameID:oldMessageID},},
    
      ]

    return (
    
    <View style={{ flexDirection:'row', flex:1, marginBottom:0,margin:10,}}>
            <ImageBackground  style={{width:35,height:35, backgroundColor:stringToHslColor(message.username,50,55), borderRadius:10, }}
                imageStyle={{ borderRadius: 10 }}
                 source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  message.username +'/'+ 
                 (props.membersAndowner.findIndex((element) => element.username == message.username) == -1 ? '' :
                props.membersAndowner[props.membersAndowner.findIndex((element) => element.username == message.username)].profilepixuuid)}} 
               > 
               { props.membersAndowner.findIndex((element) => element.username == message.username) == -1 ? 
                <GoPerson  color={stringToHslColor(message.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1}}/> :
                props.membersAndowner[props.membersAndowner.findIndex((element) => element.username == message.username)].profilepixuuid == null ? 
               <GoPerson  color={stringToHslColor(message.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1}}/> :
                null}
            </ImageBackground>   
                             
            <View style={{flex:1,marginLeft:10,}}>                
                <View style={{backgroundColor: message.username=== userInfo.username ?   '#ECFFE4' : '#F1F1F1',borderRadius:10, marginBottom:5}}>
                    <View style={{flexDirection:'row',paddingBottom:10, paddingTop:10}}>
                        <Text style={{marginLeft:10,flex:1, lineHeight:20}}>
                        {message.body} 
                        </Text>
                        <Dropdown 
                            onChange={handleChangeMore}
                            upward 
                            trigger= {message.username === userInfo.username ? trigger : null} 
                            options={option}
                            icon=''
                            value=''/>

                       
                      
                    </View>  
                    
                        <View style={{flexDirection:'row',}}>   
                            <View style={{flex:1, flexDirection:'row',}}>                                  
                                <Text style={{marginLeft:10, marginRight:5, marginBottom: 10, color:'#6B6B6B', fontSize:12}}>{message.author}</Text>
                                <Text style={{fontSize:2, marginTop:7, color:'#6B6B6B',marginRight:5,}}>{'\u2B24'}</Text>
                                <Text style={{ marginBottom: 10, color:'#6B6B6B', fontSize:12}}>
                                {moment(message.createdAt).fromNow()}
                                </Text>
                            </View>
                            {/*message.username=== userInfo.username ?  null : <FaFlag style={{ marginRight:10,}} size={12} color='#6B6B6B'/> */}
                        </View> 
                                                            
                </View>
              
                    <View style={{flex: 1,flexDirection:'row',}}>
                       
                        <TouchableOpacity disabled={ message.username=== userInfo.username ? true : button} activeOpacity={0.5} style={{flexDirection:'row',}} 
                            onPress={() => {setButton(true); updateLikeUnlike(userLike,'like',message, textindex, true,)}} >
                            <AiFillLike style={{marginRight:5 }} size={16} color= { userLike== undefined ?  '#6B6B6B' :  (userLike.like.includes(message.id) ? 'green' : '#6B6B6B')}/> 
                            <Text style={{ marginRight:15, color:'#6B6B6B', fontSize:13}}>{message.likeButton}</Text>
                        </TouchableOpacity >
                        <TouchableOpacity disabled={message.username=== userInfo.username ?  true : button} activeOpacity={0.5} style={{flexDirection:'row',}} 
                            onPress={() =>  {setButton(true); updateLikeUnlike(userLike,'unlike',message, textindex, true,)}} >
                            <AiFillDislike style={{marginRight:5 }} size={16} color= { userLike == undefined ? '#6B6B6B'  : (userLike.unlike.includes(message.id) ? 'red' : '#6B6B6B' )}/> 
                            <Text style={{ marginRight:10, color:'#6B6B6B', fontSize:13}}>{message.unlikeButton}</Text>
                        </TouchableOpacity >
                    </View>
              
            </View>
            
                                  
        </View>
    )
}

//setTextGameID(message.id) 

    const deleteView = (message) => (
        <View style={{ flexDirection:'row', flex:1, marginBottom:0, margin:15,}}>
            {/*
             <Image style={{width:35,height:35, backgroundColor:'brown', borderRadius:10, }}
                    source={{
                    uri:"https://i.picsum.photos/id/167/2896/1944.jpg?hmac=YZo5hYh18tGFz3wI4Eic6fdwNHA2pN1ZpNjVNElC8wk"}}/>       
            */} 
            <View style={{width:35,height:35,backgroundColor:'#F1F1F1', alignItems:'center',borderRadius:10,}}> 
                <FaUserSlash style={{flex:1}}  size={25} color='#9E9E9E'/>
             </View> 
            <View style={{flex:1,marginLeft:10,}}>     
                     
                <View style={{backgroundColor: message.username=== userInfo.username ?   '#ECFFE4' : '#F1F1F1',borderRadius:10, marginBottom:5}}>
                    
                        <Text style={{margin:10, marginRight:5,flex:1, lineHeight:20 ,color:'#5D5D5D'}}>
                            Comment has been redacted
                        </Text>
                        
                                                        
                        <Text style={{marginLeft:10, marginRight:5, marginBottom: 10, color:'#6B6B6B', fontSize:12}}>Deleted by user</Text>
                            
                        
                        
                                                            
                </View>
               
            </View>
            
                                  
        </View>
    )


    const messagemeView = (message, textindex) => {
        const option =  [
            { key: 'delete',  text: 'Delete', value:{message,index:textindex,reply:false},},
        
          ]
   


        return (
            
        <View>
           
            <View style={{ flexDirection:'row', flex:1, marginBottom:0, margin:10,}}>
            
            <ImageBackground  style={{width:35,height:35, backgroundColor:stringToHslColor(message.username,50,55), borderRadius:10, }}
                imageStyle={{ borderRadius: 10 }}
                 source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  message.username +'/'+ 
                 (props.membersAndowner.findIndex((element) => element.username == message.username) == -1 ? '' :
                props.membersAndowner[props.membersAndowner.findIndex((element) => element.username == message.username)].profilepixuuid)}} 
               > 
               { props.membersAndowner.findIndex((element) => element.username == message.username) == -1 ? 
                <GoPerson  color={stringToHslColor(message.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1}}/> :
                props.membersAndowner[props.membersAndowner.findIndex((element) => element.username == message.username)].profilepixuuid == null ? 
               <GoPerson  color={stringToHslColor(message.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1}}/> :
                null}
            </ImageBackground>                    
                <View style={{flex:1,marginLeft:10,}}>                
                    <View style={{backgroundColor: message.username=== userInfo.username ?   '#ECFFE4' : '#F1F1F1',borderRadius:10, marginBottom:5}}>
                        <View style={{flexDirection:'row',paddingBottom:10, paddingTop:10}}>
                            <Text style={{marginLeft:10,flex:1, lineHeight:20}}>
                                {message.body} 
                            </Text>
                        
                            <Dropdown 
                                onChange={handleChangeMore}
                                upward 
                            trigger= {message.username === userInfo.username ? trigger : null} 
                            options={option}
                                icon=''
                                value=''/>
                        </View>  
                        
                            <View style={{flexDirection:'row',}}>   
                                <View style={{flex:1, flexDirection:'row',}}>                                  
                                    <Text style={{marginLeft:10, marginRight:5, marginBottom: 10, color:'#6B6B6B', fontSize:12}}>{message.author}</Text>
                                    <Text style={{fontSize:2, marginTop:7, color:'#6B6B6B',marginRight:5,}}>{'\u2B24'}</Text>
                                    <Text style={{ marginBottom: 10, color:'#6B6B6B', fontSize:12}}>
                                    {moment(message.createdAt).fromNow()}
                                    </Text>
                                </View>
                                {/*message.username=== userInfo.username ?  null : <FaFlag style={{ marginRight:10,}} size={12} color='#6B6B6B'/> */}
                            </View> 
                                                                
                    </View>
                    <View style={{flexDirection:'row',}}>
                        <View style={{flex: 1,flexDirection:'row',}}>
                        
                            <TouchableOpacity disabled={ message.username=== userInfo.username ? true : button} activeOpacity={0.7} style={{flexDirection:'row',}} 
                                onPress={() => { setButton(true); updateLikeUnlike(userLike,'like',message, 0, false)}} >
                                <AiFillLike style={{marginRight:5 }} size={16} color= { userLike == undefined ? '#6B6B6B' : (userLike.like.includes(message.id) ? 'green' : '#6B6B6B' )}/> 
                                <Text style={{ marginRight:15, color:'#6B6B6B', fontSize:13}}>{message.likeButton}</Text>
                            </TouchableOpacity >
                            <TouchableOpacity disabled={message.username=== userInfo.username ?  true : button} activeOpacity={0.7} style={{flexDirection:'row',}} 
                                onPress={() => { setButton(true); updateLikeUnlike(userLike,'unlike',message, 0, false ) }} >
                                <AiFillDislike style={{marginRight:5 }} size={16} color= { userLike == undefined ?  '#6B6B6B' : (userLike.unlike.includes(message.id) ? 'red' : '#6B6B6B' )}/> 
                                <Text style={{ marginRight:10, color:'#6B6B6B', fontSize:13}}>{message.unlikeButton}</Text>
                            </TouchableOpacity >
                        </View>
                        <TouchableOpacity style={{marginLeft:5, marginRight:5}} onPress = {()=> message.hasOwnProperty("reply") ? (RemoveReplyToMessage(textindex, message.id), removeSub(message.id)) : (AddReplyToMessage(textindex, message.id), setTextGameID(message.id), subFun(textindex,message.id, message.id) )}>
                            <Text style={{color:message.close ? '#6B0606':'#6B6B6B'}}>{message.hasOwnProperty("reply") ? 'Hide Reply' : message.noReply==0 ?  'Reply' :'Replies (' + message.noReply + ')'}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            
                                  
            </View>
      
            { message.hasOwnProperty("reply") ? 
                <View style={{  paddingLeft:45}}>
                    {message.reply.map((message1, index) => ( message1.body==null ? deleteView(message1, index) : replyMessageView(message1, index, textindex, message.gameID) )) }
                    {InputTextMessage(message.id, textindex, message.gameID)}
                </View>
                :
                null }
        </View>
        )
    }

    const pressButton = (like, message, index, value)  => {
        setButton(true);
     
        setTimeout(()=>{
            updateLikeUnlike(like,message, index, value) ;
        }, 50)
      }

    const sendnothing = () => {

    }

    const  stringToHslColor = (str, s, l) => {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var h = hash % 360;
        return 'hsl('+h+', '+s+'%, '+l+'%)';
        }
    const InputTextMessage = (id, index, prevgameID ) => (        
        <View style={{flexDirection:'row',marginBottom:10,margin:15, marginLeft:15}}>           
                <ImageBackground  style={{width:35,height:35, backgroundColor:stringToHslColor(userInfo.username ,50,55), borderRadius:10,}}
                    imageStyle={{ borderRadius: 10 }}
                    source={{uri: 'https://s3.amazonaws.com/certificate-for-fanzquiz/public/username/'+  userInfo.username +'/'+ userInfo.profilepixuuid}} > 
                  { userInfo.profilepixuuid == null ? 
                    <GoPerson  color={stringToHslColor(userInfo.username,50,93)} size={20} style={{justifyContent:'center', alignSelf:'center',flex:1}}/> :
                    null}
                </ImageBackground>
                <TextInputPage props={props} id={id} index={index} prevgameID={prevgameID} messages ={messages} MessageData= {MessageData} />
                  
       </View> 
    )

    const RemoveReplyToMessage = (index, id) => {
        const newMessage  = [...messages]
        delete newMessage[newMessage.length-index-1].reply
        setMessages(newMessage)
    }

 

    const AddReplyToMessage = (index, id) => {
      
        LoadReplyMessages({input:id, MessageData, index, messages, username:userInfo.username, UserlikeData, userLike,loadMessage:true})
    }




    const useAudio = url => {
        const [audio] = useState(new Audio(url));
        const [playing, setPlaying] = useState(false);
      
        const toggle = () => setPlaying(!playing);
      
        useEffect(() => {
            playing ? audio.play() : audio.pause();
          },
          [playing]
        );
      
        useEffect(() => {
          audio.addEventListener('ended', () => setPlaying(false));
          return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
          };
        }, []);
      
        return [playing, toggle];
      };
      
      
        const [playing, toggle] = useAudio(soundfile);
     

    return (
      
        <View style={{marginBottom:10}}>
          
            <View style={{flexDirection:'row', flex:1, backgroundColor:'black',paddingTop:10, paddingBottom:10 }}>
                <TouchableOpacity style={{flex:1, }} onPress={()=> setpageView('comments')}>
                    <Text style={{fontWeight:pageView==='comments'? 500 : 350, alignSelf:'center', color:pageView==='comments'? 'white': '#BFBFBF', fontSize:16 }}>Comments</Text>
                    {pageView==='comments'? <View style={{height:5, backgroundColor:'#AD3E3A', marginTop:5, marginBottom:-5}}></View>:null}
                </TouchableOpacity>
                <TouchableOpacity style={{flex:1,}} onPress={()=> setpageView('members')}>
                    <Text style={{fontWeight:pageView==='members'? 500 : 350, alignSelf:'center',color:pageView==='members'? 'white': '#BFBFBF', fontSize:16 }}>Members</Text>
                    {pageView==='members'? <View style={{height:5, backgroundColor:'#AD3E3A', marginTop:5, marginBottom:-5}}></View>:null}
                </TouchableOpacity>
            </View>
            { pageView==='comments' ?
            <View>
                {InputTextMessage(props.props.match.params.id,-1)}
                <View style={{ height:0.1, backgroundColor:'#D6D6D6', marginBottom:10 }}></View>
                {[... messages].reverse().map((message, index) => ( message.body == null ? deleteView(message, index) : messagemeView(message, index)  ))  }         
            </View>
            :  
            <View style={{marginBottom:10,}}>
                <View style={{margin:10,marginLeft:15}}>
                    <Text style={{marginBottom:10, fontSize:17}}>Admin</Text>
                    {props.owner.map((item,index) => Profile({item,check:false}))  }
                </View>
                <View style={{ height:0.1, backgroundColor:'#D6D6D6', marginBottom:10, marginTop:10 }}></View>
                
                    { props.trendData.group == 'Private' ?
                    <View>
                        <View style={{margin:10,marginLeft:15}}>
                            <Text style={{marginBottom:10, fontSize:17}}>Requested</Text>
                            {props.requesters.map((item,index) => Profile({item, check:props.owner.some(owner => owner.username === userInfo.username),note:'Confirm',appuuid:props.props.match.params.id,membersupdate: props.membersupdate}))  }
                        </View>
                        <View style={{ height:0.1, backgroundColor:'#D6D6D6', marginBottom:10, marginTop:10 }}></View>

                        <View style={{margin:10,marginLeft:15}}>
                            <Text style={{marginBottom:10, fontSize:17}}>Members</Text>
                            {props.members.map((item,index) => Profile({item,check:props.owner.some(owner => owner.username === userInfo.username),note:'Remove',appuuid:props.props.match.params.id, membersupdate: props.membersupdate}))  }
                        </View>
                        <View style={{ height:0.1, backgroundColor:'#D6D6D6', marginBottom:10, marginTop:10 }}></View>
                     </View>
                    :
                    <Text style={{marginBottom:10, fontSize:17, margin:10,marginLeft:15}}>Public Group</Text>
                    }                   
              
              
            </View>
            }  
              
            
        </View>
    );
}
        
           
const mapStateToProps = state => {
    return { 
        //userInfo: state.userInfo,      
      //  teamInfo: state.teamInfo  
    }  
}

//export default connect(mapStateToProps, actions)(CommentsPage);
export default CommentsPage;

