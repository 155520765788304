export default (state = [], action) => {
   
    switch (action.type) {
        
        case 'gameStanding':  
       
           return action.payload;
        default:
            return state;
    }
};