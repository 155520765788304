import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';

export const SaveUserInfoGame = ({username, appuuidDate,dateAppuuid, saveTeamData, data }) => {  
    console.log(appuuidDate)
    const gameInfo = `
    mutation LoadUserInfoGame (
        $appuuidDate:String!
        $username:String!
        $dateAppuuid:String!
       )  {
        LoadUserInfoGame (
                username: $username
                appuuidDate:$appuuidDate
                dateAppuuid:$dateAppuuid
            )
        {appuuidDate username action dateAppuuid}
    }`; 

    API.graphql(graphqlOperation(gameInfo, {username,appuuidDate,dateAppuuid}
        )).then(response => {
            if(data){
                saveTeamData(data)
            }
            else {
                saveTeamData(response.data.LoadUserInfoGame.username);
            }
    }).catch(e => {console.log(e); 
        saveTeamData('error') 
    });
//}
}

export const RemoveUserInfoGame = ( username, appuuidDate) => {  
    const gameInfo = `
    mutation RemoveUserInfoGame (
        $appuuidDate:String!
        $username:String!
       )  {
        RemoveUserInfoGame (
                username: $username
                appuuidDate:$appuuidDate
            )
        {appuuidDate username action}
    }`; 

    API.graphql(graphqlOperation(gameInfo, {username,appuuidDate}
        )).then(response => {
            
    }).catch(e => {console.log(e); 
       
    });
//}
}

export const SaveGroupInfo = (input, saveTeamData ) => {   
    console.log(input)
    const gameInfo = `
    mutation loadGroupInfo (
        $username:String! 
        $nameofuser:String
        $trend:Int
        $appuuid:String!
        $sport: String
        $gender:String
        $date: String 
        $group: String
        $members:[String]
        $requesters:[String]
        $likeNo:Int
        $dislikeNo:Int
        $commentNo:Int
        $homeTeamName:String
        $awayTeamName:String
        $groupName:String
        
        )  {
            loadGroupInfo (
                username: $username
                trend:$trend
                nameofuser:$nameofuser
                appuuid: $appuuid
                gender:$gender
                sport: $sport
                date: $date 
                group:$group
                members:$members
                requesters:$requesters
                likeNo: $likeNo
                dislikeNo: $dislikeNo
                commentNo: $commentNo
                homeTeamName: $homeTeamName
                awayTeamName: $awayTeamName
                groupName:$groupName
            )
        {appuuid username date}
    }`; 

    API.graphql(graphqlOperation(gameInfo, input
        )).then(response => {
            SaveUserInfoGame({username:response.data.loadGroupInfo.username, dateAppuuid:response.data.loadGroupInfo.date+'-'+response.data.loadGroupInfo.appuuid, appuuidDate:response.data.loadGroupInfo.appuuid, saveTeamData})
            //saveTeamData(response.data.loadGroupInfo.appuuid);
          
    }).catch(e => {console.log(e); 
        saveTeamData('error') 
    });
//}
}

export const AddMember = (appuuid, name, saveTeamData, publicGroup ) => {
    console.log(appuuid) 
    console.log(name) 
    const gameInfo = ` 
    mutation AddMember($appuuid:String!, $name: String) {
        AddMember(appuuid: $appuuid, name: $name) {
          appuuid
          username
          members
          requesters
        }
    }`;
        API.graphql(graphqlOperation(gameInfo, {appuuid,name}
            )).then(response => {
                if (publicGroup) {
                    SaveUserInfoGame({username:name, dateAppuuid:new Date().toISOString()+'-'+response.data.AddMember.appuuid,appuuidDate: response.data.AddMember.appuuid,saveTeamData, data:response.data.AddMember})
                }
                else {
                    RemoveRequester(response.data.AddMember.appuuid,name,saveTeamData,true )  
                }
               
                   // saveTeamData(response.data.AddMember.members);
            
        }).catch(e => {console.log(e); 
           // saveTeamData('error') 
        });
}

export const RemoveMember = (appuuid, name, saveTeamData ) => {
    console.log(appuuid) 
    console.log(name) 
    const gameInfo = ` 
    mutation DeleteMember($appuuid:String!, $name: String) {
        DeleteMember(appuuid: $appuuid, name: $name) {
          appuuid
          username
          members
          requesters
        }
    }`;
        API.graphql(graphqlOperation(gameInfo, {appuuid,name}
            )).then(response => {
                    saveTeamData(response.data.DeleteMember);
                    RemoveUserInfoGame(name,appuuid)
            
        }).catch(e => {console.log(e); 
           // saveTeamData('error') 
        });
}

export const RemoveRequester = (appuuid, name, saveTeamData, addMember ) => {
   
    const gameInfo = ` 
    mutation DeleteRequester($appuuid:String!, $name: String) {
        DeleteRequester (appuuid: $appuuid, name: $name) {
          appuuid
          username
          members
          requesters
        }
    }`;
        API.graphql(graphqlOperation(gameInfo, {appuuid,name}
            )).then(response => {
                if (addMember) {
                    SaveUserInfoGame({username:name, dateAppuuid:new Date().toISOString()+'-'+response.data.DeleteRequester.appuuid,appuuidDate: response.data.DeleteRequester.appuuid,saveTeamData, data:response.data.DeleteRequester})
                }
                else {
                    console.log(response.data.DeleteRequester.requesters)
                    saveTeamData(response.data.DeleteRequester);
                }
              
                   // saveTeamData(response.data.DeleteRequester);
             
            
        }).catch(e => {console.log(e); 
           // saveTeamData('error') 
        });
}

export const AddRequester = (appuuid, name, saveTeamData ) => { 
    const gameInfo = ` 
    mutation AddRequester($appuuid:String!, $name: String) {
        AddRequester(appuuid: $appuuid, name: $name) {
          appuuid
          username
          members
          requesters
        }
    }`;
        API.graphql(graphqlOperation(gameInfo, {appuuid,name}
            )).then(response => {
        
                    saveTeamData(response.data.AddRequester);
               
            
        }).catch(e => {console.log(e); 
            saveTeamData('error') 
        });
}
      
    
    





